import axios from "axios";
import { getHeaderToken } from "../../../../../api";
import { APP_PROPERTIES } from '../../../../../properties/index';
import { fetchAdminApps, fetchUserApps } from "../../../../../api/UserApi";

export class AppsService {
    getApps() {
        return fetchUserApps()
    }

    getAdminApps() {
        return fetchAdminApps()
    }

    deleteApps(appIds) {
        return axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/deletion`, {
            "dashboardIds": appIds
        }, {
            headers: getHeaderToken()
        });
    }

    changeAlertStatus(status, appID) {
        return axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/alert-data-update?dashboardId=${appID}&enable=${status}`, {
        }, {
            headers: getHeaderToken()
        });
    }

    sendStatistics(appID){
        return axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/statistics`, {
            "dashboardId": appID
        }, {
            headers: getHeaderToken()
        }); 
    }

    changeActiveStatus(active, appID) {
        return axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/activation?dashboardId=${appID}&active=${active}`, {
        }, {
            headers: getHeaderToken()
        });
    }

    refreshApp(appID) {
        return axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/bigquery-dashboards/update`, {
            "dashboardId": appID
        }, {
            headers: getHeaderToken()
        });
    }

}

