/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Toast } from 'primereact/toast';
import { checkResultAndGetPayload } from '../../../api';
import {
    createDocumentCollection,
    fetchAvailableDocumentCollections
} from '../../../api/content/DocumentApi';
import { APP_PROPERTIES } from '../../../properties/index';
import { getHeaderToken } from '../../../api/index'
import { createUserArray, } from '../util';
import axios from 'axios'
import { MultiSelect } from "primereact/multiselect";
import _ from 'lodash';
import CreateCollection from "./CreateCollection";
import ConfirmationDialog from "../../common/ConfirmDialog/ConfirmationDialog";
import './AddRemoveDocuments.css';


class AddRemoveDocumentsDropdown extends Component {

    constructor(props) {
        super(props);

        const collIDs = [];
        if (!!props.inCollections) {
            props.inCollections.forEach(coll => {
                collIDs.push(coll.id);
            });
        }

        this.state = { inCollections: collIDs };

        this.collectionItemTemplate = this.collectionItemTemplate.bind(this);
    }

    componentDidMount = async () => {
        /*
        const elem = document.querySelector('.p-multiselect-panel');
        if (elem) {
            elem.classList.add("hideSelectAll");
            //elem.classList.add("inputWithSearchIcon");
        }
        */
        if (this.props.noCreation) {
            this.setState({ documentCollections: this.props.documentCollections });
        } else {
            const filteredDocumentCollections = await this.getAvailableDocumentCollections();

            this.setState({ documentCollections: filteredDocumentCollections });

            var self = this;
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/users`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        departmentMembers: response.data
                    }, () => {
                        let userArray = createUserArray(response.data, self.props.userData, 'user')
                        let orgArray = createUserArray(response.data, self.props.userData, 'org')
                        let allPossibleUsers = []
                        userArray && userArray.forEach(org => {
                            org.children.forEach(dep => {
                                dep.children.forEach(user => {
                                    allPossibleUsers = [...allPossibleUsers, user.id]
                                })
                            })
                        })
                        let allPossibleSuborgs = []
                        orgArray && orgArray.forEach(org => {
                            org.children.forEach(dep => {
                                allPossibleSuborgs = [...allPossibleSuborgs, dep.id]
                            })
                        })
                        let newOrgArray = []
                        orgArray?.forEach(org => {
                            org.children?.forEach(dep => {
                                newOrgArray = [...newOrgArray, { label: `${org.label} - ${dep.label}`, value: dep.id }]
                            })
                        })
                        let userList = []
                        userArray?.forEach(comp => {
                            comp.children?.forEach(dep => {
                                dep.children.forEach(user => {
                                    userList = [...userList, { label: `${user.label} [${comp.label} - ${dep.label}]`, value: user.id }]
                                })
                            })
                        })
                        self.setState({
                            userArray: userArray,
                            orgArray: orgArray,
                            allPossibleUsers: allPossibleUsers,
                            allPossibleSuborgs: allPossibleSuborgs,
                            allUsers: userList,
                            newOrgArray: newOrgArray
                        })
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    componentDidUpdate = async (prevProps) => {

        if (prevProps.inCollections !== this.props.inCollections && !this.props.noCreation) {
            const collIDs = [];
            this.props.inCollections?.forEach(coll => {
                collIDs.push(coll.id);
            });

            const filteredDocumentCollections = await this.getAvailableDocumentCollections();
            this.setState({
                inCollections: collIDs,
                documentCollections: filteredDocumentCollections,
            });
        }
        if (prevProps.documentCollections !== this.props.documentCollections && this.props.selectedDocument === this.props.docMetadata.ocDocId && this.props.noCreation) {
            console.log("update")
            const collIDs = [];
            this.props.inCollections?.forEach(coll => {
                collIDs.push(coll.id);
            });
            this.setState({
                inCollections: collIDs,
                documentCollections: this.props.documentCollections
            });
        }
    }

    getAvailableDocumentCollections = async () => {

        const response = await fetchAvailableDocumentCollections(0, 100);
        const result = checkResultAndGetPayload(response);

        let filteredDocumentCollections = [];
        if (result && result.content) {
            result.content.forEach(collection => {
                if (!collection.shared || collection.editableForSharedUsers) { // sharedComment
                    filteredDocumentCollections.push(collection);
                }
            })
        }

        return filteredDocumentCollections;
    }

    onCollectionChange = (value) => {

        const addToColl = _.difference(value, this.state.inCollections);
        if (addToColl.length > 0) {
            //console.log('add to: ', addToColl);
            const coll = this.state.documentCollections.filter(coll => addToColl.includes(coll.id));
            this.props.onAddToCollections(coll);
            this.setState({ inCollections: value })
        }
        else {
            const removeFromColl = _.difference(this.state.inCollections, value);
            //console.log('rem from: ', removeFromColl);
            if (removeFromColl.length > 0) {
                const coll = this.props.inCollections.filter(coll => removeFromColl.includes(coll.id));
                this.setState({
                    collectionsAfterRemove: value,
                    collectionsToRemoveFrom: coll,
                    displayDialogRemoveFromCollections: true
                });
            }
        }
    }

    addNewCollection = () => {

        this.setState({
            displayDialogAddCollection: true,
            newCollectionName: '',
            newCollectionDescription: '',
            sharedForDepartment: false,
            editableForSharedUsers: false,
            usersShared: []
        });
    }

    handleCollectionNameChange = (name) => {
        this.setState({ newCollectionName: name })
    }

    handleCollectionDescriptionChange = (desc) => {
        this.setState({ newCollectionDescription: desc })
    }

    handleSharedDepartmentChange = (share) => {
        this.setState({ sharedForDepartment: share })
    }

    handleMemberChange = (members) => {
        this.setState({ usersShared: members })
    }

    handleEditableChange = (editable) => {
        this.setState({ editableForSharedUsers: editable })
    }

    /**
     * Saves new collection with given name and description.
     */
    onSaveCollection = async (newCollectionName, newCollectionDescription, sharedForDepartment, usersRead, usersWrite, orgShared, editableForSharedUsers, addDocumentToNewCollection = false) => {

        const response = await createDocumentCollection(newCollectionName, newCollectionDescription, sharedForDepartment, usersRead, usersWrite, orgShared, editableForSharedUsers);
        checkResultAndGetPayload(response, this.growl);
        // TODO: get ID of newly created collection
        //console.log('result: ', result);
        if (response.statusCode <= 200) {

            const filteredDocumentCollections = await this.getAvailableDocumentCollections();

            this.setState({
                displayDialogAddCollection: false,
                documentCollections: filteredDocumentCollections,
                //selectedCollections: [filteredDocumentCollections[0]]
            }, () => {
                if (addDocumentToNewCollection && response.status === 'SUCCESS') {
                    const newCollection = filteredDocumentCollections[0];
                    const inCollectionsNew = [...this.state.inCollections];
                    inCollectionsNew.push(newCollection.id);
                    this.onCollectionChange(inCollectionsNew);
                }
            });

        } else {
            this.growl.show({ closable: true, severity: 'error', summary: 'Failed', detail: response.message })
        }
    }


    onHideRemoveFromCollection = () => {
        this.setState({ displayDialogRemoveFromCollections: false });
    }


    collectionItemTemplate(item) {

        return <span style={{ width: '100%' }}>
            <span className="" style={{ padding: 0, marginRight: 10 }}>
                <b>{item.name}</b>
            </span>
            <span className="" style={{ opacity: 0.7, padding: 0, marginRight: 3 }}>
                ({item.numberDocuments})
            </span>
            <span className="" style={{ opacity: 0.7, padding: 0, marginRight: 3 }}>
                {!item.shared ?
                    (item.sharedForDepartment || (item.usersSharedWith && item.usersSharedWith.length > 0)) ?
                        <span title={`You share this document collection with other users`}>
                            {'Shared by you'}
                        </span>
                        :
                        <span title={`This document collection can only be seen by you`}>
                            {'Private'}
                        </span>
                    :
                    <span title={`This document collection is shared with you by another user`}>
                        {'Shared with you'}
                    </span>
                }
            </span>
        </span>
    }

    render() {

        const collections = !!this.state.documentCollections ? this.state.documentCollections : [];

        let difference = []
        if (this.props.inCollections && this.props.inCollections.length > 0) {
            difference = this.props.inCollections.filter(({ id: id1 }) => !collections.some(({ id: id2 }) => id2 === id1));
        }

        difference.forEach(diff => {
            diff.disabled = true
            collections.push(diff)
        })

        let selectedCollections = []
        this.state.inCollections.forEach(col => {
            collections.forEach(collection => {
                if (col === collection.id) {
                    selectedCollections = [...selectedCollections, col]
                }
            })
        })
        //console.log(difference)

        // TODO: use join
        /*let diffNames = ''
        difference.length > 0 && difference.forEach(diff => { diffNames += diff.name + "," })
        diffNames = diffNames.slice(0, -1)*/

        return (
            <React.Fragment>

                <Toast ref={(el) => this.growl = el} />

                <MultiSelect
                    pt={{
                        checkboxIcon: {
                            onClick: (e) => {
                                e.stopPropagation();
                                if (e.target.className.baseVal !== "") {
                                    e.target.parentNode.click();
                                } else {
                                    e.target.parentNode.parentNode.click();
                                }
                            },
                        },
                        headerCheckbox: {
                            onClick: (e) => {
                                e.stopPropagation();
                                e.target.parentNode.click();
                            },
                        },
                    }}
                    maxSelectedLabels={this.props.maxSelectedLabels ? this.props.maxSelectedLabels : 3}
                    id="selectCollections"
                    className="dropdownNoBorder hideSelectAll valignMiddle"
                    value={selectedCollections}
                    options={collections}
                    optionValue='id'
                    optionLabel='name'
                    optionDisabled="disabled"
                    onChange={(e) => this.onCollectionChange(e.value)}
                    placeholder={this.props.placeholder ? this.props.placeholder : 'Add/Remove'}
                    //selectedItemTemplate={this.selectedItemTemplate}
                    //scrollHeight="400px"
                    //appendTo={document.body}
                    //ariaLabelledBy={}
                    style={{ marginRight: 0 }}
                    filter={true}
                    itemTemplate={this.collectionItemTemplate} />
                {!this.props.noCreation ?
                    <a id={`createNewCollection`}
                        className="primaryLink valignMiddle"
                        style={{ marginLeft: 0, marginRight: 15 }}
                        onClick={() => this.addNewCollection()} >
                        New
                    </a>
                    : null
                }
                <ConfirmationDialog
                    displayDialog={this.state.displayDialogRemoveFromCollections}
                    onShow={this.fetchAvailableCollections}
                    onHide={() => this.onHideRemoveFromCollection()}
                    onSubmit={() => {
                        this.props.onRemoveFromCollections(this.state.collectionsToRemoveFrom);
                        this.onHideRemoveFromCollection();
                    }}
                    headerText="Remove document from collection"
                    messageText={`This document will be removed from the collection! Do you really want to proceed?
                    If you share the collection the document will also be removed for your colleagues. Proceed?`}
                    submitButtonLabel={"Remove"}
                />

                <CreateCollection
                    displayDialog={this.state.displayDialogAddCollection}
                    onSubmit={this.onSaveCollection}
                    onHide={() => { this.setState({ displayDialogAddCollection: false }) }}
                    userArray={this.state.userArray}
                    orgArray={this.state.orgArray}
                    departmentMembers={this.state.departmentMembers}
                    allUsers={this.state.allUsers}
                    newOrgArray={this.state.newOrgArray}
                />

            </React.Fragment>
        );
    }
}

export default AddRemoveDocumentsDropdown;
