/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { RadioButton } from 'primereact/radiobutton';
import { ToggleButton } from 'primereact/togglebutton';
import { TabView, TabPanel } from 'primereact/tabview';
import NumberFormat from 'react-number-format';
import { OverlayPanel } from 'primereact/overlaypanel';
import { createDocViewUrl, hasUserRole } from '../util';
import CreateBlacklist from '../blacklist/CreateBlacklist';

import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'


class InferenceResultTable extends Component {
    cm = createRef();
    constructor(props) {
        super(props);
        this.modes = [{ name: 'Ontological', key: 'ontological' }, { name: 'Exact', key: 'exact' }];
        this.state = {
            selectedMode: this.modes[0],
            selectedModeTable: this.modes[0],
            displayDialog: false,
            source: [],
            target: [],
            targetRelations: [],
            fullRelationNumber: 0,
            subRelationNumber: 0,
            hopRelationNumber: 0,
            tripleCounter: 0,
            newBlacklistName: '',
            evidenceIDs: [],
            splitButtonItems: [
                {
                    label: 'exact',
                    command: (e) => {
                        this.blacklistConcept('exact')
                    }
                },
                {
                    label: 'ontological',
                    command: (e) => {
                        this.blacklistConcept('ontological')
                    }
                }
            ],
            splitButtonItemsTable: [
                {
                    label: 'exact',
                    command: (e) => {
                        this.blacklistConceptTable('exact')
                    }
                },
                {
                    label: 'ontological',
                    command: (e) => {
                        this.blacklistConceptTable('ontological')
                    }
                }
            ],
            menu: [
                {
                    label: 'View sources',
                    // icon: 'pi pi-info-outline',
                    command: (event) => this.onPathSelect(this.state.selectedPath)
                },
                {
                    label: 'Filter by',
                    // icon: 'pi pi-filter',
                    command: (event) => this.filter(this.state.selectedPath)
                }
            ],
            showFilterDialog: false,
            selectedTerms: [],
            entityOneChecked: false,
            //checkedEntities: [],
            displayDialogAddToBlacklist: false,
            displayDialogRemoveSelection: false,
            selectedBlackList: [],
            selectedEntities: [],
            activeIndexFull: 0,
            activeIndexSub: 0,
            activeIndexHops: 0
        }
    }

    export = (e, hitCount) => {
        this.props.callbackFromExport(this.props.fromTriple, this.props.fromSubPath, this.props.fromPath, this.props.fromHops, hitCount)
    }

    filter = () => {
        this.setState({
            showFilterDialog: true
        })
    }

    onPathSelect = async (e) => {

        this.newPath = false;

        let entities = []

        entities = [...entities, { start: e.start }]
        entities = [...entities, { start: e.end }]

        if (e.end2) {
            entities = [...entities, { start: e.end2 }]
        }
        if (e.end3) {
            entities = [...entities, { start: e.end3 }]
        }
        if (e.end4) {
            entities = [...entities, { start: e.end4 }]
        }
        if (e.end5) {
            entities = [...entities, { start: e.end5 }]
        }

        let relations = []

        relations = [...relations, { start: e.start, relation: e.relation, end: e.end }]

        if (e.end2) {
            relations = [...relations, { start: e.end, relation: e.relation2, end: e.end2 }]
        }

        if (e.end3) {
            relations = [...relations, { start: e.end2, relation: e.relation3, end: e.end3 }]
        }

        if (e.end4) {
            relations = [...relations, { start: e.end3, relation: e.relation4, end: e.end4 }]
        }

        if (e.end5) {
            relations = [...relations, { start: e.end4, relation: e.relation5, end: e.end5 }]
        }

        let selectEntityItems = []
        let selectEntityItemsSub = []
        let selectEntityItemsFull = []
        let selectEntityItemsHop = []

        if (this.props.fromTriple && entities) {
            entities.forEach(paths => {
                selectEntityItems = [...selectEntityItems, { label: paths.start, value: paths.start }]
            })
        }

        if (this.props.fromSubPath && entities) {
            entities.forEach(paths => {
                selectEntityItemsSub = [...selectEntityItemsSub, { label: paths.start, value: paths.start }]
            })
        }

        if (this.props.fromPath && entities) {
            entities.forEach(paths => {
                selectEntityItemsFull = [...selectEntityItemsFull, { label: paths.start, value: paths.start }]
            })
        }

        if (this.props.fromHops && entities) {
            entities.forEach(paths => {
                selectEntityItemsHop = [...selectEntityItemsHop, { label: paths.start, value: paths.start }]
            })
        }

        let selectionEntity
        if (selectEntityItems && selectEntityItems.length > 0) {
            selectionEntity = selectEntityItems[0].value
        }
        let selectionEntitySub
        if (selectEntityItemsSub && selectEntityItemsSub.length > 0) {
            selectionEntitySub = selectEntityItemsSub[0].value
        }
        let selectionEntityFull
        if (selectEntityItemsFull && selectEntityItemsFull.length > 0) {
            selectionEntityFull = selectEntityItemsFull[0].value
        }
        let selectionEntityHop
        if (selectEntityItemsHop && selectEntityItemsHop.length > 0) {
            selectionEntityHop = selectEntityItemsHop[0].value
        }

        if (this.props.fromTriple && selectEntityItems.length > 0 && this.props.prefNamesTriple && Object.keys(this.props.prefNamesTriple).length > 0) {


            let tripleOccurrence
            if (this.props.matchedPathsTriple && this.props.matchedPathsTriple.length > 0 && this.props.matchedPathsTriple[0].tripleData && this.props.matchedPathsTriple[0].tripleData.occurrence) {
                tripleOccurrence = this.props.matchedPathsTriple[e.counter - this.props.firstTriple - 1].tripleData[0].occurrence
            } else {
                tripleOccurrence = 1
            }
            let selectTripleItemsTriple = []

            if (this.props.fromTriple && relations) {
                relations.forEach(relation => {
                    selectTripleItemsTriple = [...selectTripleItemsTriple, { label: `${relation.start} - [${relation.relation}] - ${relation.end} (${tripleOccurrence} hits)`, value: `${relation.start} - [${relation.relation}] - ${relation.end}  (${tripleOccurrence} hits)` }]
                })
            }

            let selectionTripleTriple
            if (selectTripleItemsTriple && selectTripleItemsTriple.length > 0) {
                selectionTripleTriple = selectTripleItemsTriple[0].value
            }
            this.setState({
                displayDialog: true,
                path: entities,
                relations: relations,
                selectionEntity: selectionEntity,
                selectEntityItems: selectEntityItems,
                selectionEntitySub: selectionEntitySub,
                selectEntityItemsSub: selectEntityItemsSub,
                selectEntityItemsFull: selectEntityItemsFull,
                selectionEntityFull: selectionEntityFull,
                selectionTripleTriple: selectionTripleTriple,
                selectTripleItemsTriple: selectTripleItemsTriple,
                target: [],
                targetRelations: [],
                activeIndex: 0,
                tripleCounter: e.counter - this.props.firstTriple - 1,
                selectedBlackList: [],
                selectedEntities: [],
                activeIndexFull: 0,
                activeIndexSub: 0,
                activeIndexHops: 0,
                tripleOccurrence: tripleOccurrence
            }, () => {
                this.props.callbackFromPathSelect(false)
            })
        } else if (this.props.fromSubPath && selectEntityItemsSub.length > 0 && this.props.prefNamesSubPath && Object.keys(this.props.prefNamesSubPath).length > 0) {

            let subPathOccurrences = []
            if (this.props.matchedPathsSubPaths && this.props.matchedPathsSubPaths.length > 0 && this.props.matchedPathsSubPaths[0].tripleData) {
                this.props.matchedPathsSubPaths[e.counter - this.props.firstSub - 1].tripleData.forEach(triple => {
                    if (triple.occurrence) {
                        subPathOccurrences = [...subPathOccurrences, triple.occurrence]
                    } else {
                        subPathOccurrences = [...subPathOccurrences, 1]
                    }
                })
            }
            let selectTripleItems = []

            if (this.props.fromSubPath && relations) {
                relations.forEach((relation, i) => {
                    selectTripleItems = [...selectTripleItems, { label: `${relation.start} - [${relation.relation}] - ${relation.end} (${subPathOccurrences[i]} hits)`, value: `${relation.start} - [${relation.relation}] - ${relation.end}  (${subPathOccurrences[i]} hits)` }]
                })
            }
            let selectionTriple
            if (selectTripleItems && selectTripleItems.length > 0) {
                selectionTriple = selectTripleItems[0].value
            }
            this.setState({
                displayDialog: true,
                path: entities,
                relations: relations,
                selectionEntity: selectionEntity,
                selectEntityItems: selectEntityItems,
                selectionEntitySub: selectionEntitySub,
                selectEntityItemsSub: selectEntityItemsSub,
                selectEntityItemsFull: selectEntityItemsFull,
                selectionEntityFull: selectionEntityFull,
                selectionTriple: selectionTriple,
                selectTripleItems: selectTripleItems,
                target: [],
                targetRelations: [],
                activeIndex: 0,
                tripleCounter: e.counter - this.props.firstSub - 1,
                selectedBlackList: [],
                selectedEntities: [],
                activeIndexFull: 0,
                activeIndexSub: 0,
                activeIndexHops: 0,
                subPathOccurrences: subPathOccurrences
            }, () => {
                this.props.callbackFromPathSelect(false)
            })
        } else if (this.props.fromPath && selectEntityItemsFull.length > 0 && this.props.prefNames && Object.keys(this.props.prefNames).length > 0) {

            let pathOccurrences = []
            if (this.props.matchedPaths && this.props.matchedPaths.length > 0 && this.props.matchedPaths[0].tripleData) {
                this.props.matchedPaths[e.counter - this.props.firstFull - 1].tripleData.forEach(triple => {

                    if (triple.occurrence) {
                        pathOccurrences = [...pathOccurrences, triple.occurrence]
                    } else {
                        pathOccurrences = [...pathOccurrences, 1]
                    }
                })
            }

            let selectTripleItemsFull = []

            if (this.props.fromPath && relations) {
                relations.forEach((relation, i) => {
                    selectTripleItemsFull = [...selectTripleItemsFull, { label: `${relation.start} - [${relation.relation}] - ${relation.end} (${pathOccurrences[i]} hits)`, value: `${relation.start} - [${relation.relation}] - ${relation.end}  (${pathOccurrences[i]} hits)` }]
                })
            }

            let selectionTripleFull
            if (selectTripleItemsFull && selectTripleItemsFull.length > 0) {
                selectionTripleFull = selectTripleItemsFull[0].value
            }

            this.setState({
                displayDialog: true,
                path: entities,
                relations: relations,
                selectionEntity: selectionEntity,
                selectEntityItems: selectEntityItems,
                selectionEntitySub: selectionEntitySub,
                selectEntityItemsSub: selectEntityItemsSub,
                selectEntityItemsFull: selectEntityItemsFull,
                selectionEntityFull: selectionEntityFull,
                selectionTripleFull: selectionTripleFull,
                selectTripleItemsFull: selectTripleItemsFull,
                target: [],
                targetRelations: [],
                activeIndex: 0,
                tripleCounter: e.counter - this.props.firstFull - 1,
                selectedBlackList: [],
                selectedEntities: [],
                activeIndexFull: 0,
                activeIndexSub: 0,
                activeIndexHops: 0,
                pathOccurrences: pathOccurrences
            }, () => {
                this.props.callbackFromPathSelect(false)
            })
        } else if (this.props.fromHops && selectEntityItemsHop.length > 0 && this.props.prefNamesHops && Object.keys(this.props.prefNamesHops).length > 0) {
            let pathOccurrencesHop = []
            if (this.props.matchedPathsHops && this.props.matchedPathsHops.length > 0 && this.props.matchedPathsHops[0].tripleData) {
                this.props.matchedPathsHops[e.counter - this.props.firstHops - 1].tripleData.forEach(triple => {
                    if (triple.occurrence) {
                        pathOccurrencesHop = [...pathOccurrencesHop, triple.occurrence]
                    } else {
                        pathOccurrencesHop = [...pathOccurrencesHop, 1]
                    }
                })
            }

            let selectTripleItemsHop = []

            if (this.props.fromHops && relations) {
                relations.forEach((relation, i) => {
                    selectTripleItemsHop = [...selectTripleItemsHop, { label: `${relation.start} - [${relation.relation}] - ${relation.end} (${pathOccurrencesHop[i]} hits)`, value: `${relation.start} - [${relation.relation}] - ${relation.end}  (${pathOccurrencesHop[i]} hits)` }]
                })
            }

            let selectionTripleHop
            if (selectTripleItemsHop && selectTripleItemsHop.length > 0) {
                selectionTripleHop = selectTripleItemsHop[0].value
            }

            this.setState({
                displayDialog: true,
                path: entities,
                relations: relations,
                selectionEntity: selectionEntity,
                selectEntityItems: selectEntityItems,
                selectionEntitySub: selectionEntitySub,
                selectEntityItemsSub: selectEntityItemsSub,
                selectEntityItemsFull: selectEntityItemsFull,
                selectEntityItemsHop: selectEntityItemsHop,
                selectionEntityFull: selectionEntityFull,
                selectionEntityHop: selectionEntityHop,
                selectionTripleHop: selectionTripleHop,
                selectTripleItemsHop: selectTripleItemsHop,
                target: [],
                targetRelations: [],
                activeIndex: 0,
                tripleCounter: e.counter - this.props.firstHops - 1,
                //exampleSentencesFull: response.data.relationData.exampleSentences,
                selectedBlackList: [],
                selectedEntities: [],
                activeIndexFull: 0,
                activeIndexSub: 0,
                activeIndexHops: 0,
                pathOccurrencesHop: pathOccurrencesHop
            }, () => {
                this.props.callbackFromPathSelect(false)
            })
        }
    }
    sentenceTemplate = (rowData) => {
        let repAvailable = false

        let repID = rowData.source_repo ? rowData.source_repo[0] : rowData.repository ? rowData.repository[0] : -1
        this.props.userDetails.department?.selectedRepositories.forEach(rep => {
            if (rep.id.toString() === repID) {
                repAvailable = true
            }
        })

        let sentence = ""
        let sentenceLength = 0
        if ((rowData.source_context && rowData.source_context[0] !== null) || rowData.sentence) {
            sentence = rowData.source_context ? rowData.source_context[0] : rowData.sentence
            sentenceLength = sentence.split(' ').length
        }

        return <div>
            {(rowData.doc_id && rowData.source_repo && repAvailable && rowData.source_context) || (rowData.ocDocId && repAvailable && rowData.sentence) ?
                <div>
                    {rowData.source_db[0] !== "chembl" && sentenceLength > 6 ?
                        <a title={sentence} onClick={(e) => this.showDocument(rowData, e)}>{sentence.split(' ').slice(0, 6).join(' ') + "..."}</a>
                        : rowData.source_db[0] !== "chembl" && sentenceLength <= 6 ?
                            <a title={sentence} onClick={(e) => this.showDocument(rowData, e)}>{sentence}</a>
                            : rowData.source_db[0] === "chembl" && sentenceLength > 6 ?
                                <label title={sentence}>{sentence.split(' ').slice(0, 6).join(' ') + "..."}</label>
                                : rowData.source_db[0] === "chembl" && sentenceLength <= 6 ?
                                    <label title={sentence}>{sentence}</label>
                                    : null
                    }
                </div>
                :
                rowData.doc_id && rowData.source_repo && repAvailable && !rowData.source_context ?
                    <div>
                        <a onClick={(e) => this.showDocument(rowData, e)}>Document</a>
                    </div>
                    :
                    <div>
                        <span>Document repository not available</span>
                    </div>
            }
            {rowData.source_link && rowData.source_link[0] && !rowData.source_link[0].startsWith("s3://") ?
                <a target="_blank" href={rowData.source_link[0]}>{//rowData.source_link[0].substring(rowData.source_link[0].lastIndexOf("/") + 1)
                    'External link'}</a>
                : null
            }
        </div>
    }

    showDocument = async (rowData) => {
        let link = createDocViewUrl(rowData.repository ? rowData.repository : rowData.source_repo, rowData.ocDocId ? rowData.ocDocId : rowData.doc_id, { query: rowData.source_context ? `text:"${rowData.source_context[0]}"` : rowData.sentence ? `text:"${rowData.sentence}"` : "" });
        window.open(link, "_blank");
    }

    onSelectionTripleChange = (e) => {
        if (e.index !== null) {
            this.setState({
                selectionTriple: this.state.selectTripleItems[e.index],
                subRelationNumber: e.index,
                activeIndexSub: e.index
            })
        }
    }

    onSelectionTripleFullChange = (e) => {
        if (e.index !== null) {
            this.setState({
                selectionTripleFull: this.state.selectTripleItemsFull[e.index],
                fullRelationNumber: e.index,
                activeIndexFull: e.index
            })
        }
    }

    onSelectionTripleHopChange = (e) => {
        if (e.index !== null) {
            this.setState({
                selectionTripleHop: this.state.selectTripleItemsHop[e.index],
                hopRelationNumber: e.index,
                activeIndexHops: e.index
            })
        }
    }

    onEntityChange = (e) => {
        let selected = [...this.state.selectedEntities]
        if (e.checked) {
            selected.push(e.value)

        } else {
            selected.splice(selected.indexOf(e.value), 1)
        }
        this.setState({
            selectedEntities: selected
        })
    }

    onBlacklistChange = (e) => {
        let selected = [...this.state.selectedBlackList]
        if (e.checked) {
            selected.push(e.value)

        } else {
            selected.splice(selected.indexOf(e.value), 1)
        }
        this.setState({
            selectedBlackList: selected
        })
    }

    blacklistConcept = (mode) => {
        this.setState({
            displayDialogAddToBlacklist: false,
        }, () => this.props.callbackFromBlacklistConcepts(this.state.selectedMode.key, this.state.selectedBlackList, this.state.selectedEntities, this.props.fromTriple,
            this.props.fromSubPath, this.props.fromPath, this.props.fromHops, false, this.props.checkedEntities))
    }

    blacklistConceptTable = (mode) => {
        this.setState({
            displayDialogAddToBlacklist: false,
        }, () => this.props.callbackFromBlacklistConcepts(this.state.selectedModeTable.key, this.state.selectedBlackList, this.state.selectedEntities, this.props.fromTriple,
            this.props.fromSubPath, this.props.fromPath, this.props.fromHops, true, this.props.checkedEntities))
    }

    repositoryTemplate = (rowData) => {
        let repLabel = "Repository not available."

        if (rowData.hasOwnProperty("source_context") && rowData.source_context[0] !== null && rowData.source_db[0] !== "chembl"
        ) {
            let repID = rowData.source_repo[0]
            this.props.userDetails.department?.selectedRepositories.forEach(rep => {
                if (rep.id.toString() === repID) {
                    repLabel = rep.label
                }
            })
        } else if (rowData.hasOwnProperty("sentence")) {
            let repID = rowData.repository[0]
            this.props.userDetails.department?.selectedRepositories.forEach(rep => {
                if (rep.id.toString() === repID) {
                    repLabel = rep.label
                }
            })
        } else {
            if (rowData.source_db) {
                repLabel = rowData.source_db[0].replace(/^./, str => str.toUpperCase())
            }
        }
        return repLabel
    }

    docIDTemplate = (rowData) => {
        let docID = ""

        if (rowData.hasOwnProperty("ocDocId")) {
            docID = rowData.ocDocId
        } else if (rowData.hasOwnProperty("doc_id")) {
            docID = rowData.doc_id
        } else if (rowData.hasOwnProperty("source_id")) {
            docID = rowData.source_id[0]
            rowData.source_id.forEach(id => {
                if (id.startsWith("pmid")) {
                    docID = id
                }
            })
        }
        return <div>
            <span style={{ paddingRight: 15, float: 'left' }}>{docID}</span>
            {rowData.hasOwnProperty("source_id") && !rowData.hasOwnProperty("source_context") ?
                <span><CopyToClipboard text={this.split(docID)}
                    onCopy={() => this.setState({ copied: true })}>
                    <a id="detailSbjct"
                        title='Copy to clipboard'
                        onClick={(e) => this.showCopySuccessGrowl(e)}
                        style={{ display: 'inline-block', marginRight: 10 }}>Copy</a>
                </CopyToClipboard></span>
                : null
            }
            {rowData.hasOwnProperty("source_id") && rowData.source_id.length > 1 ?
                <div>
                    <a title='Click to show more source IDs' onClick={(e) => this.onMoreClick(rowData.source_id, e)}>{`More (${rowData.source_id.length - 1})`}</a>
                </div>
                : null
            }
        </div>
    }

    onMoreClick = (ids, e) => {
        let filteredIDs = []
        ids.forEach(id => {
            if (!id.startsWith("pmid")) {
                filteredIDs = [...filteredIDs, id]
            }
        })
        this.setState({
            evidenceIDs: filteredIDs
        }, () => {
            this.op.toggle(e)
        })
    }

    sourceDateTemplate = (rowData) => {
        let date = ""
        if (rowData.hasOwnProperty("source_date")) {
            date = rowData.source_date[0]
        } else {
            date = "-"
        }
        return date
    }

    addNewBlacklist = () => {
        this.props.addNewBlacklist()
    }

    updateBlacklistName = (name) => {
        this.props.updateBlacklistName(name)
    }

    updateBlacklistDescription = (desc) => {
        this.props.updateBlacklistDescription(desc)
    }

    onTermCheckboxChange = (e) => {
        let selectedTerms = [...this.state.selectedTerms]
        if (e.checked)
            selectedTerms.push(e.value);
        else
            selectedTerms.splice(selectedTerms.indexOf(e.value), 1);
        this.setState({ selectedTerms: selectedTerms });
    }

    onFilter = () => {
        let filters = []
        this.state.selectedTerms && this.state.selectedTerms.forEach(term => {
            Object.keys(this.state.selectedPath).forEach(key => {
                if (key === term) {
                    filters = [...filters, { key: key, value: this.state.selectedPath[key] }]
                }
            })
        })
        this.setState({
            showFilterDialog: false,
            selectedTerms: []
        }, () => this.props.callbackFromFilter(filters, this.props.fromTriple, this.props.fromSubPath, this.props.fromPath, this.props.fromHops))
    }

    handleDropdownChange = (e) => {
        this.props.callbackFromDropdownHopChange(e.value)
    }

    onFullPageChange = (e, fromPaginator) => {
        this.props.callbackFromPageChange(fromPaginator ? e.first : this.props.firstFull, fromPaginator ? e.rows : e.value, this.props.fromTriple, this.props.fromSubPath, this.props.fromPath, this.props.fromHops)
    }

    onSubPageChange = (e, fromPaginator) => {
        this.props.callbackFromPageChange(fromPaginator ? e.first : this.props.firstSub, fromPaginator ? e.rows : e.value, this.props.fromTriple, this.props.fromSubPath, this.props.fromPath, this.props.fromHops)
    }

    onTriplePageChange = (e, fromPaginator) => {
        this.props.callbackFromPageChange(fromPaginator ? e.first : this.props.firstTriple, fromPaginator ? e.rows : e.value, this.props.fromTriple, this.props.fromSubPath, this.props.fromPath, this.props.fromHops)
    }

    onHopPageChange = (e, fromPaginator) => {
        this.props.callbackFromPageChange(fromPaginator ? e.first : this.props.firstHops, fromPaginator ? e.rows : e.value, this.props.fromTriple, this.props.fromSubPath, this.props.fromPath, this.props.fromHops)
    }

    counterTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">#</span>
            {rowData.counter}
        </div>
    }

    entityOneTemplate = (rowData) => {
        if (rowData.start) {
            let entityOne = rowData.start
            let entityKey
            if (this.props.fromPath) {
                entityKey = Object.keys(this.props.prefNames).find(key => this.props.prefNames[key] === entityOne)
            } else if (this.props.fromSubPath) {
                entityKey = Object.keys(this.props.prefNamesSubPath).find(key => this.props.prefNamesSubPath[key] === entityOne)
            } else if (this.props.fromTriple) {
                entityKey = Object.keys(this.props.prefNamesTriple).find(key => this.props.prefNamesTriple[key] === entityOne)
            }
            else if (this.props.fromHops) {
                entityKey = Object.keys(this.props.prefNamesHops).find(key => this.props.prefNamesHops[key] === entityOne)
            }
            if (hasUserRole('ROLE_BLACKLISTS')) {
                return <div>
                    <span className="p-column-title">Entity 1</span>
                    <ToggleButton
                        tooltip={entityOne}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', borderRadius: '3px', maxWidth: 250 }}
                        className='toggleButtonInference'
                        onLabel={entityOne}
                        offLabel={entityOne}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityOne)}>
                    </ToggleButton>
                    <ToggleButton
                        tooltip={entityOne}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '67%' }}
                        className="p-column-title"
                        onLabel={entityOne}
                        offLabel={entityOne}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityOne)}>
                    </ToggleButton>
                </div>
            } else {
                return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span className="p-column-title">Entity 1</span>
                    {entityOne}
                </div>
            }
        }
    }

    relationOneTemplate = (rowData) => {
        return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <span className="p-column-title">Relation 1</span>
            {rowData.relation}
        </div>
    }

    relationTwoTemplate = (rowData) => {
        return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <span className="p-column-title">Relation 2</span>
            {rowData.relation2}
        </div>
    }

    relationThreeTemplate = (rowData) => {
        return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <span className="p-column-title">Relation 3</span>
            {rowData.relation3}
        </div>
    }

    relationFourTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Relation 4</span>
            {rowData.relation4}
        </div>
    }

    relationFiveTemplate = (rowData) => {
        return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <span className="p-column-title">Relation 5</span>
            {rowData.relation5}
        </div>
    }

    entityTwoTemplate = (rowData) => {
        if (rowData.end) {
            let entityTwo = rowData.end
            let entityKey
            if (this.props.fromPath) {
                entityKey = Object.keys(this.props.prefNames).find(key => this.props.prefNames[key] === entityTwo)
            } else if (this.props.fromSubPath) {
                entityKey = Object.keys(this.props.prefNamesSubPath).find(key => this.props.prefNamesSubPath[key] === entityTwo)
            } else if (this.props.fromTriple) {
                entityKey = Object.keys(this.props.prefNamesTriple).find(key => this.props.prefNamesTriple[key] === entityTwo)
            }
            else if (this.props.fromHops) {
                entityKey = Object.keys(this.props.prefNamesHops).find(key => this.props.prefNamesHops[key] === entityTwo)
            }
            if (hasUserRole('ROLE_BLACKLISTS')) {
                return <div>
                    <span className="p-column-title">Entity 2</span>
                    <ToggleButton
                        tooltip={entityTwo}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', borderRadius: '3px', maxWidth: 250 }}
                        className='toggleButtonInference'
                        onLabel={entityTwo}
                        offLabel={entityTwo}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityTwo)}>
                    </ToggleButton>
                    <ToggleButton
                        tooltip={entityTwo}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '67%' }}
                        className="p-column-title"
                        onLabel={entityTwo}
                        offLabel={entityTwo}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityTwo)}>
                    </ToggleButton>
                </div>
            } else {
                return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span className="p-column-title">Entity 2</span>
                    {entityTwo}
                </div>
            }
        }
    }

    entityThreeTemplate = (rowData) => {
        if (rowData.end2) {
            let entityThree = rowData.end2
            let entityKey
            if (this.props.fromPath) {
                entityKey = Object.keys(this.props.prefNames).find(key => this.props.prefNames[key] === entityThree)
            } else if (this.props.fromSubPath) {
                entityKey = Object.keys(this.props.prefNamesSubPath).find(key => this.props.prefNamesSubPath[key] === entityThree)
            } else if (this.props.fromTriple) {
                entityKey = Object.keys(this.props.prefNamesTriple).find(key => this.props.prefNamesTriple[key] === entityThree)
            }
            else if (this.props.fromHops) {
                entityKey = Object.keys(this.props.prefNamesHops).find(key => this.props.prefNamesHops[key] === entityThree)
            }
            if (hasUserRole('ROLE_BLACKLISTS')) {
                return <div>
                    <span className="p-column-title">Entity 3</span>
                    <ToggleButton
                        tooltip={entityThree}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', borderRadius: '3px', maxWidth: 250 }}
                        className='toggleButtonInference'
                        onLabel={entityThree}
                        offLabel={entityThree}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityThree)}>
                    </ToggleButton>
                    <ToggleButton
                        tooltip={entityThree}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '67%' }}
                        className="p-column-title"
                        onLabel={entityThree}
                        offLabel={entityThree}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityThree)}>
                    </ToggleButton>
                </div>
            } else {
                return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span className="p-column-title">Entity 3</span>
                    {entityThree}
                </div>
            }
        }
    }

    entityFourTemplate = (rowData) => {
        if (rowData.end3) {
            let entityFour = rowData.end3
            let entityKey
            if (this.props.fromPath) {
                entityKey = Object.keys(this.props.prefNames).find(key => this.props.prefNames[key] === entityFour)
            } else if (this.props.fromSubPath) {
                entityKey = Object.keys(this.props.prefNamesSubPath).find(key => this.props.prefNamesSubPath[key] === entityFour)
            } else if (this.props.fromTriple) {
                entityKey = Object.keys(this.props.prefNamesTriple).find(key => this.props.prefNamesTriple[key] === entityFour)
            }
            else if (this.props.fromHops) {
                entityKey = Object.keys(this.props.prefNamesHops).find(key => this.props.prefNamesHops[key] === entityFour)
            }
            if (hasUserRole('ROLE_BLACKLISTS')) {
                return <div>
                    <span className="p-column-title">Entity 4</span>
                    <ToggleButton
                        tooltip={entityFour}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', borderRadius: '3px', maxWidth: 250 }}
                        className='toggleButtonInference'
                        onLabel={entityFour}
                        offLabel={entityFour}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityFour)}>
                    </ToggleButton>
                    <ToggleButton
                        tooltip={entityFour}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '67%' }}
                        className="p-column-title"
                        onLabel={entityFour}
                        offLabel={entityFour}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityFour)}>
                    </ToggleButton>
                </div>
            } else {
                return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span className="p-column-title">Entity 4</span>
                    {entityFour}
                </div>
            }
        }
    }

    entityFiveTemplate = (rowData) => {
        if (rowData.end4) {
            let entityFive = rowData.end4
            let entityKey
            if (this.props.fromPath) {
                entityKey = Object.keys(this.props.prefNames).find(key => this.props.prefNames[key] === entityFive)
            } else if (this.props.fromSubPath) {
                entityKey = Object.keys(this.props.prefNamesSubPath).find(key => this.props.prefNamesSubPath[key] === entityFive)
            } else if (this.props.fromTriple) {
                entityKey = Object.keys(this.props.prefNamesTriple).find(key => this.props.prefNamesTriple[key] === entityFive)
            }
            else if (this.props.fromHops) {
                entityKey = Object.keys(this.props.prefNamesHops).find(key => this.props.prefNamesHops[key] === entityFive)
            }
            if (hasUserRole('ROLE_BLACKLISTS')) {
                return <div>
                    <span className="p-column-title">Entity 5</span>
                    <ToggleButton
                        tooltip={entityFive}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', borderRadius: '3px', maxWidth: 250 }}
                        className='toggleButtonInference'
                        onLabel={entityFive}
                        offLabel={entityFive}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityFive)}>
                    </ToggleButton>
                    <ToggleButton
                        tooltip={entityFive}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '67%' }}
                        className="p-column-title"
                        onLabel={entityFive}
                        offLabel={entityFive}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entityFive)}>
                    </ToggleButton>
                </div>
            } else {
                return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span className="p-column-title">Entity 5</span>
                    {entityFive}
                </div>
            }
        }
    }


    entitySixTemplate = (rowData) => {
        if (rowData.end5) {
            let entitySix = rowData.end5
            let entityKey
            if (this.props.fromPath) {
                entityKey = Object.keys(this.props.prefNames).find(key => this.props.prefNames[key] === entitySix)
            } else if (this.props.fromSubPath) {
                entityKey = Object.keys(this.props.prefNamesSubPath).find(key => this.props.prefNamesSubPath[key] === entitySix)
            } else if (this.props.fromTriple) {
                entityKey = Object.keys(this.props.prefNamesTriple).find(key => this.props.prefNamesTriple[key] === entitySix)
            }
            else if (this.props.fromHops) {
                entityKey = Object.keys(this.props.prefNamesHops).find(key => this.props.prefNamesHops[key] === entitySix)
            }
            if (hasUserRole('ROLE_BLACKLISTS')) {
                return <div>
                    <span className="p-column-title">Entity 6</span>
                    <ToggleButton
                        tooltip={entitySix}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', borderRadius: '3px', maxWidth: 250 }}
                        className='toggleButtonInference'
                        onLabel={entitySix}
                        offLabel={entitySix}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entitySix)}>
                    </ToggleButton>
                    <ToggleButton
                        tooltip={entitySix}
                        tooltipOptions={{ position: 'bottom' }}
                        style={{ textAlign: 'left', fontWeight: 'bolder', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle', width: '67%' }}
                        className="p-column-title"
                        onLabel={entitySix}
                        offLabel={entitySix}
                        checked={this.props.checkedEntities.some(e => e.entityKey === entityKey) ? true : false}
                        onChange={(e) => this.handleCheckedEntityChange(e.value, entityKey, entitySix)}>
                    </ToggleButton>
                </div>
            } else {
                return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span className="p-column-title">Entity 6</span>
                    {entitySix}
                </div>
            }
        }
    }

    hitsTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{this.props.fromTriple || (this.props.fromPath && this.props.startTerm.length < 2) || (this.props.fromHops && this.props.hopsToShow === 1) ? 'Occurrences' : 'Occurrences (avg)'}</span>
            <NumberFormat value={rowData.hits} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />
        </div>
    }

    hitsMinTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">Occurrences (min)</span>
            <NumberFormat value={rowData.hitsMin} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />
        </div>
    }

    detailsBody = (rowData) => {
        return <div>
            <a title='Show path details' onClick={e => this.setState({ selectedPath: rowData },
                () => this.onPathSelect(this.state.selectedPath))}
                style={{ display: 'block', paddingBottom: 5 }}
                className="primaryLink showOnHover">
                Details
            </a>
            <a title='Filter by entities or relations' onClick={e => this.setState({ selectedPath: rowData },
                () => this.filter(this.state.selectedPath))}
                style={{ display: 'block' }}
                className="primaryLink showOnHover">
                Filter by
            </a>
        </div>
    }

    handleCheckedEntityChange = (value, entityKey, prefName) => {
        let checkedEntities = this.props.checkedEntities
        if (!checkedEntities.some(e => e.entityKey === entityKey)) {
            checkedEntities.push({ entityKey, prefName })
        } else if (checkedEntities.some(e => e.entityKey === entityKey)) {
            checkedEntities = checkedEntities.filter(obj => obj.entityKey !== entityKey)
        }
        this.props.callbackFromCheckedEntityChange(checkedEntities)
    }

    handleAddToBlacklistDialog = () => {
        this.setState({
            displayDialogAddToBlacklist: true,
            selectedBlackList: []
        })
    }

    handleRemoveSelection = () => {
        this.setState({
            displayDialogRemoveSelection: true
        })
    }

    handleRemoveSelectedEntites = () => {
        this.setState({
            displayDialogRemoveSelection: false
        }, () => this.props.callbackFromRemoveSelectedEntities())
    }

    onHide = () => {
        this.setState({
            displayDialogRemoveSelection: false
        })
    }

    handleSelectionChange = (e) => {
        this.props.handleSelectionChange(e.value, this.props.fromTriple, this.props.fromSubPath, this.props.fromHops, this.props.fromPath)
    }

    closeAddToBlocklistDialog = () => {
        this.setState({
            selectedBlackList: []
        }, () => {
            this.props.closeAddToBlocklistDialog()
        })
    }

    showCopySuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'ID successfully copied to clipboard.' };
        this.growl.show(msg);
    }

    split = (id) => {
        let index = id.lastIndexOf(": ")
        let str = id[0].substring(index + 1)
        return str
    }

    render() {

        let hitCount = this.props.fromTriple ? this.props.matchedTriplePathCount : this.props.fromSubPath ? this.props.matchedSubPathCount : this.props.fromHops ? this.props.matchedPathsCountHops : this.props.matchedPathsCount

        let startOCIDsTriple = []
        let relationOCIDsTriple = []
        let endOCIDsTriple = []
        let hitsTriple = []
        let counter = []

        let startOCIDsSub = []
        let relationOCIDsSub = []
        let endOCIDsSub = []
        let endOCIDs2Sub = []
        let relationOCIDs2Sub = []
        let relationOCIDs3Sub = []
        let endOCIDs3Sub = []
        let relationOCIDs4Sub = []
        let endOCIDs4Sub = []
        let relationOCIDs5Sub = []
        let endOCIDs5Sub = []
        let hitsSub = []
        let hitsSubMin = []
        let counterSub = []

        let startOCIDs = []
        let relationOCIDs = []
        let endOCIDs = []
        let endOCIDs2 = []
        let relationOCIDs2 = []
        let relationOCIDs3 = []
        let endOCIDs3 = []
        let relationOCIDs4 = []
        let endOCIDs4 = []
        let relationOCIDs5 = []
        let endOCIDs5 = []
        let hits = []
        let hitsMin = []
        let counterFull = []

        let startOCIDsHop = []
        let relationOCIDsHop = []
        let endOCIDsHop = []
        let endOCIDs2Hop = []
        let relationOCIDs2Hop = []
        let relationOCIDs3Hop = []
        let endOCIDs3Hop = []
        let relationOCIDs4Hop = []
        let endOCIDs4Hop = []
        let relationOCIDs5Hop = []
        let endOCIDs5Hop = []
        let hitsHop = []
        let hitsMinHop = []
        let counterHop = []

        let tripleSentences = []
        let tripleSentencesSub = [[]]
        let tripleSentencesFull = [[]]
        let tripleSentencesHop = [[]]

        if (this.props.matchedPathsTriple && this.props.matchedPathsTriple.length > 0) {
            this.props.matchedPathsTriple.forEach(paths => {
                if (paths.tripleData[0] && paths.tripleData[0].metadata) {
                    tripleSentences = [...tripleSentences, paths.tripleData[0].metadata]
                } else if (paths.tripleData[0] && paths.tripleData[0].sentences) {
                    tripleSentences = [...tripleSentences, paths.tripleData[0].sentences]
                }
            })
        }

        if (this.props.matchedPathsSubPaths && this.props.matchedPathsSubPaths.length > 0) {
            this.props.matchedPathsSubPaths.forEach((paths, i) => {
                let tmpArray = []
                paths.tripleData.forEach((triple, j) => {
                    if (triple.metadata && triple.metadata.length > 0) {
                        tmpArray = [...tmpArray, triple.metadata]
                    } else if (triple.sentences && triple.sentences.length > 0) {
                        tmpArray = [...tmpArray, triple.sentences]
                    }
                })
                tripleSentencesSub[i] = tmpArray
            })
        }

        if (this.props.matchedPathsHops && this.props.matchedPathsHops.length > 0) {
            this.props.matchedPathsHops.forEach((paths, i) => {
                let tmpArray = []
                paths.tripleData.forEach((triple, j) => {
                    if (triple.metadata && triple.metadata.length > 0) {
                        tmpArray = [...tmpArray, triple.metadata]
                    } else if (triple.sentences && triple.sentences.length > 0) {
                        tmpArray = [...tmpArray, triple.sentences]
                    }
                })
                tripleSentencesHop[i] = tmpArray
            })
        }

        if (this.props.matchedPaths && this.props.matchedPaths.length > 0 && this.props.startTerm.length === 1) {
            this.props.matchedPaths.forEach(paths => {
                if (paths.tripleData[0] && paths.tripleData[0].metadata) {
                    tripleSentencesFull[0].push(paths.tripleData[0].metadata)
                } else if (paths.tripleData[0] && paths.tripleData[0].sentences) {
                    tripleSentencesFull[0].push(paths.tripleData[0].sentences)
                }
            })
        }

        if (this.props.matchedPaths && this.props.matchedPaths.length > 0 && this.props.startTerm.length > 1) {
            this.props.matchedPaths.forEach((paths, i) => {
                let tmpArray = []
                paths.tripleData.forEach((triple, j) => {
                    if (triple.metadata && triple.metadata.length > 0) {
                        tmpArray = [...tmpArray, triple.metadata]
                    } else if (triple.sentences && triple.sentences.length > 0) {
                        tmpArray = [...tmpArray, triple.sentences]
                    }
                })
                tripleSentencesFull[i] = tmpArray
            })
        }

        this.props.matchedPathsTriple && this.props.matchedPathsTriple.length > 0 && this.props.matchedPathsTriple.forEach((match, i) => {
            startOCIDsTriple = [...startOCIDsTriple, match.path[0]]
            relationOCIDsTriple = [...relationOCIDsTriple, match.path[1]]
            endOCIDsTriple = [...endOCIDsTriple, match.path[2]]
            if (match.tripleData && match.tripleData[0] && match.tripleData[0].occurrence) {
                hitsTriple = [...hitsTriple, match.tripleData[0].occurrence]
            } else {
                hitsTriple = [...hitsTriple, 1]
            }
            counter = [...counter, this.props.firstTriple + i + 1]
        })

        this.props.matchedPathsSubPaths && this.props.matchedPathsSubPaths.length > 0 && this.props.matchedPathsSubPaths.forEach((match, i) => {
            startOCIDsSub = [...startOCIDsSub, match.path[0]]
            relationOCIDsSub = [...relationOCIDsSub, match.path[1]]
            endOCIDsSub = [...endOCIDsSub, match.path[2]]
            if (this.props.subPathNumber >= 1) {
                relationOCIDs2Sub = [...relationOCIDs2Sub, match.path[3]]
                endOCIDs2Sub = [...endOCIDs2Sub, match.path[4]]
            }
            if (this.props.subPathNumber >= 2) {
                relationOCIDs3Sub = [...relationOCIDs3Sub, match.path[5]]
                endOCIDs3Sub = [...endOCIDs3Sub, match.path[6]]
            }
            if (this.props.subPathNumber >= 3) {
                relationOCIDs4Sub = [...relationOCIDs4Sub, match.path[7]]
                endOCIDs4Sub = [...endOCIDs4Sub, match.path[8]]
            }
            if (this.props.subPathNumber >= 4) {
                relationOCIDs5Sub = [...relationOCIDs5Sub, match.path[9]]
                endOCIDs5Sub = [...endOCIDs5Sub, match.path[10]]
            }
            if (match.rankScore && match.rankScore) {
                hitsSub = [...hitsSub, Math.round(match.rankScore)]
            } else {
                hitsSub = [...hitsSub, 1]
            }
            if (match.tripleData && match.tripleData.length > 0 && 'occurrence' in match.tripleData[0]) {
                let min = Math.min.apply(null, match.tripleData.map(function (item) {
                    return item.occurrence;
                }))
                hitsSubMin = [...hitsSubMin, min]
            } else {
                hitsSubMin = [...hitsSubMin, 1]
            }
            counterSub = [...counterSub, this.props.firstSub + i + 1]
        })

        this.props.matchedPaths && this.props.matchedPaths.length > 0 && this.props.matchedPaths.forEach((match, i) => {
            startOCIDs = [...startOCIDs, match.path[0]]
            relationOCIDs = [...relationOCIDs, match.path[1]]
            endOCIDs = [...endOCIDs, match.path[2]]
            if (this.props.fullPathNumber >= 2) {
                relationOCIDs2 = [...relationOCIDs2, match.path[3]]
                endOCIDs2 = [...endOCIDs2, match.path[4]]
            }
            if (this.props.fullPathNumber >= 3) {
                relationOCIDs3 = [...relationOCIDs3, match.path[5]]
                endOCIDs3 = [...endOCIDs3, match.path[6]]
            }
            if (this.props.fullPathNumber >= 4) {
                relationOCIDs4 = [...relationOCIDs4, match.path[7]]
                endOCIDs4 = [...endOCIDs4, match.path[8]]
            }
            if (this.props.fullPathNumber >= 5) {
                relationOCIDs5 = [...relationOCIDs5, match.path[9]]
                endOCIDs5 = [...endOCIDs5, match.path[10]]
            }
            if (match.rankScore && match.rankScore) {
                hits = [...hits, Math.round(match.rankScore)]
            } else {
                hits = [...hits, 1]
            }
            if (match.tripleData && match.tripleData.length > 0 && 'occurrence' in match.tripleData[0]) {
                let min = Math.min.apply(null, match.tripleData.map(function (item) {
                    return item.occurrence;
                }))
                hitsMin = [...hitsMin, min]
            } else {
                hitsMin = [...hitsMin, 1]
            }
            counterFull = [...counterFull, this.props.firstFull + i + 1]
        })

        this.props.matchedPathsHops && this.props.matchedPathsHops.length > 0 && this.props.matchedPathsHops.forEach((match, i) => {
            startOCIDsHop = [...startOCIDsHop, match.path[0]]
            relationOCIDsHop = [...relationOCIDsHop, match.path[1]]
            endOCIDsHop = [...endOCIDsHop, match.path[2]]
            if (this.props.hopsToShow > 1) {
                relationOCIDs2Hop = [...relationOCIDs2Hop, match.path[3]]
                endOCIDs2Hop = [...endOCIDs2Hop, match.path[4]]
            }
            if (this.props.hopsToShow > 2) {
                relationOCIDs3Hop = [...relationOCIDs3Hop, match.path[5]]
                endOCIDs3Hop = [...endOCIDs3Hop, match.path[6]]
            }
            if (this.props.hopsToShow > 3) {
                relationOCIDs4Hop = [...relationOCIDs4Hop, match.path[7]]
                endOCIDs4Hop = [...endOCIDs4Hop, match.path[8]]
            }
            if (this.props.hopsToShow > 4) {
                relationOCIDs5Hop = [...relationOCIDs5Hop, match.path[9]]
                endOCIDs5Hop = [...endOCIDs5Hop, match.path[10]]
            }
            if (match.rankScore && match.rankScore) {
                hitsHop = [...hitsHop, Math.round(match.rankScore)]
            } else {
                hitsHop = [...hitsHop, 1]
            }
            if (match.tripleData && match.tripleData.length > 0 && 'occurrence' in match.tripleData[0]) {
                let min = Math.min.apply(null, match.tripleData.map(function (item) {
                    return item.occurrence;
                }))
                hitsMinHop = [...hitsMinHop, min]
            } else {
                hitsMinHop = [...hitsMinHop, 1]
            }
            counterHop = [...counterHop, this.props.firstHops + i + 1]
        })

        let startNamesTriple = []
        let relationNamesTriple = []
        let endNamesTriple = []

        let startNamesSub = []
        let relationNamesSub = []
        let endNamesSub = []
        let endNames2Sub = []
        let relationNames2Sub = []
        let relationNames3Sub = []
        let endNames3Sub = []
        let relationNames4Sub = []
        let endNames4Sub = []
        let relationNames5Sub = []
        let endNames5Sub = []

        let startNames = []
        let relationNames = []
        let endNames = []
        let endNames2 = []
        let relationNames2 = []
        let relationNames3 = []
        let endNames3 = []
        let relationNames4 = []
        let endNames4 = []
        let relationNames5 = []
        let endNames5 = []

        let startNamesHop = []
        let relationNamesHop = []
        let endNamesHop = []
        let endNames2Hop = []
        let relationNames2Hop = []
        let relationNames3Hop = []
        let endNames3Hop = []
        let relationNames4Hop = []
        let endNames4Hop = []
        let relationNames5Hop = []
        let endNames5Hop = []

        startOCIDsTriple.forEach(start => {
            if (this.props.prefNamesTriple[start]) {
                startNamesTriple = [...startNamesTriple, { label: this.props.prefNamesTriple[start], value: start }]
            }
        })

        relationOCIDsTriple.forEach(relation => {
            if (this.props.prefNamesTriple[relation]) {
                relationNamesTriple = [...relationNamesTriple, { label: this.props.prefNamesTriple[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        endOCIDsTriple.forEach(end => {
            if (this.props.prefNamesTriple[end]) {
                endNamesTriple = [...endNamesTriple, { label: this.props.prefNamesTriple[end], value: end }]
            }
        })


        startOCIDs.forEach(start => {
            if (this.props.prefNames[start]) {
                startNames = [...startNames, { label: this.props.prefNames[start], value: start }]
            }
        })

        relationOCIDs.forEach(relation => {
            if (this.props.prefNames[relation]) {
                relationNames = [...relationNames, { label: this.props.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        endOCIDs.forEach(end => {
            if (this.props.prefNames[end]) {
                endNames = [...endNames, { label: this.props.prefNames[end], value: end }]
            }
        })

        relationOCIDs2.forEach(relation => {
            if (this.props.prefNames[relation]) {
                relationNames2 = [...relationNames2, { label: this.props.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs3.forEach(relation => {
            if (this.props.prefNames[relation]) {
                relationNames3 = [...relationNames3, { label: this.props.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs4.forEach(relation => {
            if (this.props.prefNames[relation]) {
                relationNames4 = [...relationNames4, { label: this.props.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs5.forEach(relation => {
            if (this.props.prefNames[relation]) {
                relationNames5 = [...relationNames5, { label: this.props.prefNames[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        endOCIDs2.forEach(end => {
            if (this.props.prefNames[end]) {
                endNames2 = [...endNames2, { label: this.props.prefNames[end], value: end }]
            }
        })

        endOCIDs3.forEach(end => {
            if (this.props.prefNames[end]) {
                endNames3 = [...endNames3, { label: this.props.prefNames[end], value: end }]
            }
        })

        endOCIDs4.forEach(end => {
            if (this.props.prefNames[end]) {
                endNames4 = [...endNames4, { label: this.props.prefNames[end], value: end }]
            }
        })

        endOCIDs5.forEach(end => {
            if (this.props.prefNames[end]) {
                endNames5 = [...endNames5, { label: this.props.prefNames[end], value: end }]
            }
        })

        startOCIDsHop.forEach(start => {
            if (this.props.prefNamesHops[start]) {
                startNamesHop = [...startNamesHop, { label: this.props.prefNamesHops[start], value: start }]
            }
        })

        relationOCIDsHop.forEach(relation => {
            if (this.props.prefNamesHops[relation]) {
                relationNamesHop = [...relationNamesHop, { label: this.props.prefNamesHops[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        endOCIDsHop.forEach(end => {
            if (this.props.prefNamesHops[end]) {
                endNamesHop = [...endNamesHop, { label: this.props.prefNamesHops[end], value: end }]
            }
        })

        relationOCIDs2Hop.forEach(relation => {
            if (this.props.prefNamesHops[relation]) {
                relationNames2Hop = [...relationNames2Hop, { label: this.props.prefNamesHops[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs3Hop.forEach(relation => {
            if (this.props.prefNamesHops[relation]) {
                relationNames3Hop = [...relationNames3Hop, { label: this.props.prefNamesHops[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs4Hop.forEach(relation => {
            if (this.props.prefNamesHops[relation]) {
                relationNames4Hop = [...relationNames4Hop, { label: this.props.prefNamesHops[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs5Hop.forEach(relation => {
            if (this.props.prefNamesHops[relation]) {
                relationNames5Hop = [...relationNames5Hop, { label: this.props.prefNamesHops[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        endOCIDs2Hop.forEach(end => {
            if (this.props.prefNamesHops[end]) {
                endNames2Hop = [...endNames2Hop, { label: this.props.prefNamesHops[end], value: end }]
            }
        })

        endOCIDs3Hop.forEach(end => {
            if (this.props.prefNamesHops[end]) {
                endNames3Hop = [...endNames3Hop, { label: this.props.prefNamesHops[end], value: end }]
            }
        })

        endOCIDs4Hop.forEach(end => {
            if (this.props.prefNamesHops[end]) {
                endNames4Hop = [...endNames4Hop, { label: this.props.prefNamesHops[end], value: end }]
            }
        })

        endOCIDs5Hop.forEach(end => {
            if (this.props.prefNamesHops[end]) {
                endNames5Hop = [...endNames5Hop, { label: this.props.prefNamesHops[end], value: end }]
            }
        })

        startOCIDsSub.forEach(start => {
            if (this.props.prefNamesSubPath[start]) {
                startNamesSub = [...startNamesSub, { label: this.props.prefNamesSubPath[start], value: start }]
            }
        })

        relationOCIDsSub.forEach(relation => {
            if (this.props.prefNamesSubPath[relation]) {
                relationNamesSub = [...relationNamesSub, { label: this.props.prefNamesSubPath[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        endOCIDsSub.forEach(end => {
            if (this.props.prefNamesSubPath[end]) {
                endNamesSub = [...endNamesSub, { label: this.props.prefNamesSubPath[end], value: end }]
            }
        })

        relationOCIDs2Sub.forEach(relation => {
            if (this.props.prefNamesSubPath[relation]) {
                relationNames2Sub = [...relationNames2Sub, { label: this.props.prefNamesSubPath[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs3Sub.forEach(relation => {
            if (this.props.prefNamesSubPath[relation]) {
                relationNames3Sub = [...relationNames3Sub, { label: this.props.prefNamesSubPath[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs4Sub.forEach(relation => {
            if (this.props.prefNamesSubPath[relation]) {
                relationNames4Sub = [...relationNames4Sub, { label: this.props.prefNamesSubPath[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        relationOCIDs5Sub.forEach(relation => {
            if (this.props.prefNamesSubPath[relation]) {
                relationNames5Sub = [...relationNames5Sub, { label: this.props.prefNamesSubPath[relation].replace(/ *\[[^\]]*]/g, '').trim(), value: relation }]
            }
        })

        endOCIDs2Sub.forEach(end => {
            if (this.props.prefNamesSubPath[end]) {
                endNames2Sub = [...endNames2Sub, { label: this.props.prefNamesSubPath[end], value: end }]
            }
        })

        endOCIDs3Sub.forEach(end => {
            if (this.props.prefNamesSubPath[end]) {
                endNames3Sub = [...endNames3Sub, { label: this.props.prefNamesSubPath[end], value: end }]
            }
        })

        endOCIDs4Sub.forEach(end => {
            if (this.props.prefNamesSubPath[end]) {
                endNames4Sub = [...endNames4Sub, { label: this.props.prefNamesSubPath[end], value: end }]
            }
        })

        endOCIDs5Sub.forEach(end => {
            if (this.props.prefNamesSubPath[end]) {
                endNames5Sub = [...endNames5Sub, { label: this.props.prefNamesSubPath[end], value: end }]
            }
        })

        let pathsTriple = []
        startNamesTriple.map((name, i) => {
            pathsTriple[i] = {
                counter: counter[i], start: startNamesTriple[i]["label"], startOCID: startNamesTriple[i]["value"],
                relation: relationNamesTriple[i]["label"], relationOCID: relationNamesTriple[i]["value"],
                end: endNamesTriple[i]["label"], endOCID: endNamesTriple[i]["value"], hits: hitsTriple[i]
            }
            return pathsTriple
        })

        let pathsSub = []
        startNamesSub.map((name, i) => {
            this.props.subPathNumber === 0 ?
                pathsSub[i] = {
                    counter: counterSub[i], start: startNamesSub[i]["label"], startOCID: startNamesSub[i]["value"],
                    relation: relationNamesSub[i]["label"], relationOCID: relationNamesSub[i]["value"],
                    end: endNamesSub[i]["label"], endOCID: endNamesSub[i]["value"],
                    hits: hitsSub[i], hitsMin: hitsSubMin[i]
                }
                : this.props.subPathNumber === 1 ?
                    pathsSub[i] = {
                        counter: counterSub[i], start: startNamesSub[i]["label"], startOCID: startNamesSub[i]["value"],
                        relation: relationNamesSub[i]["label"], relationOCID: relationNamesSub[i]["value"],
                        end: endNamesSub[i]["label"], endOCID: endNamesSub[i]["value"],
                        relation2: relationNames2Sub[i]["label"], relation2OCID: relationNames2Sub[i]["value"],
                        end2: endNames2Sub[i]["label"], end2OCID: endNames2Sub[i]["value"],
                        hits: hitsSub[i], hitsMin: hitsSubMin[i]
                    }
                    : this.props.subPathNumber === 2 ?
                        pathsSub[i] = {
                            counter: counterSub[i], start: startNamesSub[i]["label"], startOCID: startNamesSub[i]["value"],
                            relation: relationNamesSub[i]["label"], relationOCID: relationNamesSub[i]["value"],
                            end: endNamesSub[i]["label"], endOCID: endNamesSub[i]["value"],
                            relation2: relationNames2Sub[i]["label"], relation2OCID: relationNames2Sub[i]["value"],
                            end2: endNames2Sub[i]["label"], end2OCID: endNames2Sub[i]["value"],
                            relation3: relationNames3Sub[i]["label"], relation3OCID: relationNames3Sub[i]["value"],
                            end3: endNames3Sub[i]["label"], end3OCID: endNames3Sub[i]["value"],
                            hits: hitsSub[i], hitsMin: hitsSubMin[i]
                        }
                        : this.props.subPathNumber === 3 ?
                            pathsSub[i] = {
                                counter: counterSub[i], start: startNamesSub[i]["label"], startOCID: startNamesSub[i]["value"],
                                relation: relationNamesSub[i]["label"], relationOCID: relationNamesSub[i]["value"],
                                end: endNamesSub[i]["label"], endOCID: endNamesSub[i]["value"],
                                relation2: relationNames2Sub[i]["label"], relation2OCID: relationNames2Sub[i]["value"],
                                end2: endNames2Sub[i]["label"], end2OCID: endNames2Sub[i]["value"],
                                relation3: relationNames3Sub[i]["label"], relation3OCID: relationNames3Sub[i]["value"],
                                end3: endNames3Sub[i]["label"], end3OCID: endNames3Sub[i]["value"],
                                relation4: relationNames4Sub[i]["label"], relation4OCID: relationNames4Sub[i]["value"],
                                end4: endNames4Sub[i]["label"], end4OCID: endNames4Sub[i]["value"],
                                hits: hitsSub[i], hitsMin: hitsSubMin[i]
                            }
                            :
                            this.props.subPathNumber === 4 ?
                                pathsSub[i] = {
                                    counter: counterSub[i], start: startNamesSub[i]["label"], startOCID: startNamesSub[i]["value"],
                                    relation: relationNamesSub[i]["label"], relationOCID: relationNamesSub[i]["value"],
                                    end: endNamesSub[i]["label"], endOCID: endNamesSub[i]["value"],
                                    relation2: relationNames2Sub[i]["label"], relation2OCID: relationNames2Sub[i]["value"],
                                    end2: endNames2Sub[i]["label"], end2OCID: endNames2Sub[i]["value"],
                                    relation3: relationNames3Sub[i]["label"], relation3OCID: relationNames3Sub[i]["value"],
                                    end3: endNames3Sub[i]["label"], end3OCID: endNames3Sub[i]["value"],
                                    relation4: relationNames4Sub[i]["label"], relation4OCID: relationNames4Sub[i]["value"],
                                    end4: endNames4Sub[i]["label"], end4OCID: endNames4Sub[i]["value"],
                                    relation5: relationNames5Sub[i]["label"], relation5OCID: relationNames5Sub[i]["value"],
                                    end5: endNames5Sub[i]["label"], end5OCID: endNames5Sub[i]["value"],
                                    hits: hitsSub[i], hitsMin: hitsSubMin[i]
                                }
                                : pathsSub = []
            return pathsSub
        })

        let paths = []
        startNames.map((name, i) => {
            this.props.fullPathNumber === 1 ?
                paths[i] = {
                    counter: counterFull[i], start: startNames[i]["label"], startOCID: startNames[i]["value"],
                    relation: relationNames[i]["label"], relationOCID: relationNames[i]["value"],
                    end: endNames[i]["label"], endOCID: endNames[i]["value"],
                    hits: hits[i], hitsMin: hitsMin[i]
                }
                : this.props.fullPathNumber === 2 ?
                    paths[i] = {
                        counter: counterFull[i], start: startNames[i]["label"], startOCID: startNames[i]["value"],
                        relation: relationNames[i]["label"], relationOCID: relationNames[i]["value"],
                        end: endNames[i]["label"], endOCID: endNames[i]["value"],
                        relation2: relationNames2[i]["label"], relation2OCID: relationNames2[i]["value"],
                        end2: endNames2[i]["label"], end2OCID: endNames2[i]["value"],
                        hits: hits[i], hitsMin: hitsMin[i]
                    }
                    : this.props.fullPathNumber === 3 ?
                        paths[i] = {
                            counter: counterFull[i], start: startNames[i]["label"], startOCID: startNames[i]["value"],
                            relation: relationNames[i]["label"], relationOCID: relationNames[i]["value"],
                            end: endNames[i]["label"], endOCID: endNames[i]["value"],
                            relation2: relationNames2[i]["label"], relation2OCID: relationNames2[i]["value"],
                            end2: endNames2[i]["label"], end2OCID: endNames2[i]["value"],
                            relation3: relationNames3[i]["label"], relation3OCID: relationNames3[i]["value"],
                            end3: endNames3[i]["label"], end3OCID: endNames3[i]["value"],
                            hits: hits[i], hitsMin: hitsMin[i]
                        }
                        : this.props.fullPathNumber === 4 ?
                            paths[i] = {
                                counter: counterFull[i], start: startNames[i]["label"], startOCID: startNames[i]["value"],
                                relation: relationNames[i]["label"], relationOCID: relationNames[i]["value"],
                                end: endNames[i]["label"], endOCID: endNames[i]["value"],
                                relation2: relationNames2[i]["label"], relation2OCID: relationNames2[i]["value"],
                                end2: endNames2[i]["label"], end2OCID: endNames2[i]["value"],
                                relation3: relationNames3[i]["label"], relation3OCID: relationNames3[i]["value"],
                                end3: endNames3[i]["label"], end3OCID: endNames3[i]["value"],
                                relation4: relationNames4[i]["label"], relation4OCID: relationNames4[i]["value"],
                                end4: endNames4[i]["label"], end4OCID: endNames4[i]["value"],
                                hits: hits[i], hitsMin: hitsMin[i]
                            }
                            :
                            this.props.fullPathNumber === 5 ?
                                paths[i] = {
                                    counter: counterFull[i], start: startNames[i]["label"], startOCID: startNames[i]["value"],
                                    relation: relationNames[i]["label"], relationOCID: relationNames[i]["value"],
                                    end: endNames[i]["label"], endOCID: endNames[i]["value"],
                                    relation2: relationNames2[i]["label"], relation2OCID: relationNames2[i]["value"],
                                    end2: endNames2[i]["label"], end2OCID: endNames2[i]["value"],
                                    relation3: relationNames3[i]["label"], relation3OCID: relationNames3[i]["value"],
                                    end3: endNames3[i]["label"], end3OCID: endNames3[i]["value"],
                                    relation4: relationNames4[i]["label"], relation4OCID: relationNames4[i]["value"],
                                    end4: endNames4[i]["label"], end4OCID: endNames4[i]["value"],
                                    relation5: relationNames5[i]["label"], relation5OCID: relationNames5[i]["value"],
                                    end5: endNames5[i]["label"], end5OCID: endNames5[i]["value"],
                                    hits: hits[i], hitsMin: hitsMin[i]
                                }
                                : paths = []
            return paths
        })

        let pathsHop = []
        startNamesHop.map((name, i) => {
            this.props.hopsToShow === 1 ?
                pathsHop[i] = {
                    counter: counterHop[i], start: startNamesHop[i]["label"], startOCID: startNamesHop[i]["value"],
                    relation: relationNamesHop[i]["label"], relationOCID: relationNamesHop[i]["value"],
                    end: endNamesHop[i]["label"], endOCID: endNamesHop[i]["value"],
                    hits: hitsHop[i], hitsMin: hitsMinHop[i]
                }
                : this.props.hopsToShow === 2 ?
                    pathsHop[i] = {
                        counter: counterHop[i], start: startNamesHop[i]["label"], startOCID: startNamesHop[i]["value"],
                        relation: relationNamesHop[i]["label"], relationOCID: relationNamesHop[i]["value"],
                        end: endNamesHop[i]["label"], endOCID: endNamesHop[i]["value"],
                        relation2: relationNames2Hop[i]["label"], relation2OCID: relationNames2Hop[i]["value"],
                        end2: endNames2Hop[i]["label"], end2OCID: endNames2Hop[i]["value"],
                        hits: hitsHop[i], hitsMin: hitsMinHop[i]
                    }
                    : this.props.hopsToShow === 3 ?
                        pathsHop[i] = {
                            counter: counterHop[i], start: startNamesHop[i]["label"], startOCID: startNamesHop[i]["value"],
                            relation: relationNamesHop[i]["label"], relationOCID: relationNamesHop[i]["value"],
                            end: endNamesHop[i]["label"], endOCID: endNamesHop[i]["value"],
                            relation2: relationNames2Hop[i]["label"], relation2OCID: relationNames2Hop[i]["value"],
                            end2: endNames2Hop[i]["label"], end2OCID: endNames2Hop[i]["value"],
                            relation3: relationNames3Hop[i]["label"], relation3OCID: relationNames3Hop[i]["value"],
                            end3: endNames3Hop[i]["label"], end3OCID: endNames3Hop[i]["value"],
                            hits: hitsHop[i], hitsMin: hitsMinHop[i]
                        }
                        : this.props.hopsToShow === 4 ?
                            pathsHop[i] = {
                                counter: counterHop[i], start: startNamesHop[i]["label"], startOCID: startNamesHop[i]["value"],
                                relation: relationNamesHop[i]["label"], relationOCID: relationNamesHop[i]["value"],
                                end: endNamesHop[i]["label"], endOCID: endNamesHop[i]["value"],
                                relation2: relationNames2Hop[i]["label"], relation2OCID: relationNames2Hop[i]["value"],
                                end2: endNames2Hop[i]["label"], end2OCID: endNames2Hop[i]["value"],
                                relation3: relationNames3Hop[i]["label"], relation3OCID: relationNames3Hop[i]["value"],
                                end3: endNames3Hop[i]["label"], end3OCID: endNames3Hop[i]["value"],
                                relation4: relationNames4Hop[i]["label"], relation4OCID: relationNames4Hop[i]["value"],
                                end4: endNames4Hop[i]["label"], end4OCID: endNames4Hop[i]["value"],
                                hits: hitsHop[i], hitsMin: hitsMinHop[i]
                            }
                            : this.props.hopsToShow === 5 ?
                                pathsHop[i] = {
                                    counter: counterHop[i], start: startNamesHop[i]["label"], startOCID: startNamesHop[i]["value"],
                                    relation: relationNamesHop[i]["label"], relationOCID: relationNamesHop[i]["value"],
                                    end: endNamesHop[i]["label"], endOCID: endNamesHop[i]["value"],
                                    relation2: relationNames2Hop[i]["label"], relation2OCID: relationNames2Hop[i]["value"],
                                    end2: endNames2Hop[i]["label"], end2OCID: endNames2Hop[i]["value"],
                                    relation3: relationNames3Hop[i]["label"], relation3OCID: relationNames3Hop[i]["value"],
                                    end3: endNames3Hop[i]["label"], end3OCID: endNames3Hop[i]["value"],
                                    relation4: relationNames4Hop[i]["label"], relation4OCID: relationNames4Hop[i]["value"],
                                    end4: endNames4Hop[i]["label"], end4OCID: endNames4Hop[i]["value"],
                                    relation5: relationNames5Hop[i]["label"], relation5OCID: relationNames5Hop[i]["value"],
                                    end5: endNames5Hop[i]["label"], end5OCID: endNames5Hop[i]["value"],
                                    hits: hitsHop[i], hitsMin: hitsMinHop[i]
                                }
                                : pathsHop = []
            return pathsHop
        })

        const footerFilter = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Filter"
                    className='p-button-sm primaryButton'
                    icon="pi pi-filter"
                    onClick={this.onFilter}
                    disabled={this.state.selectedTerms && this.state.selectedTerms.length > 0 ? false : true}
                />
            </div>
        )

        let footer = <div>
            <div style={{ paddingTop: 0 }}>
                <Paginator
                    totalRecords={hitCount}
                    rowsPerPageOptions={[20, 50, 100]}
                    pageLinkSize={5}
                    template={CustomPaginatorTemplate}
                    leftContent={<CustomPaginatorLeftSide
                        first={this.props.fromPath ? this.props.firstFull : this.props.fromSubPath ? this.props.firstSub : this.props.fromTriple ? this.props.firstTriple : this.props.firstHops}
                        contentLength={hitCount}
                        rows={this.props.fromPath ? this.props.rowsFull : this.props.fromSubPath ? this.props.rowsSub : this.props.fromTriple ? this.props.rowsTriple : this.props.rowsHops}>
                    </CustomPaginatorLeftSide>}
                    rightContent={<CustomPaginatorRightSide
                        rows={this.props.fromPath ? this.props.rowsFull : this.props.fromSubPath ? this.props.rowsSub : this.props.fromTriple ? this.props.rowsTriple : this.props.rowsHops}
                        onChange={this.props.fromPath ? (e) => this.onFullPageChange(e, false) : this.props.fromSubPath ? (e) => this.onSubPageChange(e, false) : this.props.fromTriple ? (e) => this.onTriplePageChange(e, false) : (e) => this.onHopPageChange(e, false)}
                    ></CustomPaginatorRightSide>}
                    onPageChange={this.props.fromPath ? (e) => this.onFullPageChange(e, true) : this.props.fromSubPath ? (e) => this.onSubPageChange(e, true) : this.props.fromTriple ? (e) => this.onTriplePageChange(e, true) : (e) => this.onHopPageChange(e, true)}
                    rows={this.props.fromPath ? this.props.rowsFull : this.props.fromSubPath ? this.props.rowsSub : this.props.fromTriple ? this.props.rowsTriple : this.props.rowsHops}
                    first={this.props.fromPath ? this.props.firstFull : this.props.fromSubPath ? this.props.firstSub : this.props.fromTriple ? this.props.firstTriple : this.props.firstHops}>
                </Paginator>
            </div>
        </div>

        const footerRemoveSelection = (
            <div>
                <Button label="Yes" onClick={this.handleRemoveSelectedEntites} className="p-button-secondary p-button-sm" />
                <Button label="No" onClick={this.onHide} className="p-button-secondary p-button-sm" />
            </div>
        )

        let checkboxElements

        checkboxElements = this.state.selectedPath && Object.entries(this.state.selectedPath).map((key, value) => {
            if (key[0] !== 'hits' && key[0] !== 'counter' && key[0] !== 'hitsMin' && !key[0].endsWith("OCID")) {
                if (this.props.fromTriple) {
                    if (this.props.filterTriple && this.props.filterTriple.length !== 0) {
                        if (!this.props.filterTriple.some(filter => filter.key === key[0])) {
                            return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                                key={`${key[0]}`}>
                                <Checkbox inputId={`${key[0]}`}
                                    value={key[0]}
                                    onChange={(e) => this.onTermCheckboxChange(e)}
                                    checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                                />
                                <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                    className='p-checkbox-label'>
                                    {`${key[1]}`}
                                </label>
                            </div>
                        }
                    } else {
                        return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                            key={`${key[0]}`}>
                            <Checkbox inputId={`${key[0]}`}
                                value={key[0]}
                                onChange={(e) => this.onTermCheckboxChange(e)}
                                checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                            />
                            <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                className='p-checkbox-label'>
                                {`${key[1]}`}
                            </label>
                        </div>
                    }
                } else if (this.props.fromSubPath) {
                    if (this.props.filterSub && this.props.filterSub.length !== 0) {
                        if (!this.props.filterSub.some(filter => filter.key === key[0])) {
                            return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                                key={`${key[0]}`}>
                                <Checkbox inputId={`${key[0]}`}
                                    value={key[0]}
                                    onChange={(e) => this.onTermCheckboxChange(e)}
                                    checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                                />
                                <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                    className='p-checkbox-label'>
                                    {`${key[1]}`}
                                </label>
                            </div>
                        }
                    } else {
                        return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                            key={`${key[0]}`}>
                            <Checkbox inputId={`${key[0]}`}
                                value={key[0]}
                                onChange={(e) => this.onTermCheckboxChange(e)}
                                checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                            />
                            <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                className='p-checkbox-label'>
                                {`${key[1]}`}
                            </label>
                        </div>
                    }
                } else if (this.props.fromPath) {
                    if (this.props.filterFull && this.props.filterFull.length !== 0) {
                        if (!this.props.filterFull.some(filter => filter.key === key[0])) {
                            return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                                key={`${key[0]}`}>
                                <Checkbox inputId={`${key[0]}`}
                                    value={key[0]}
                                    onChange={(e) => this.onTermCheckboxChange(e)}
                                    checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                                />
                                <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                    className='p-checkbox-label'>
                                    {`${key[1]}`}
                                </label>
                            </div>
                        }
                    } else {
                        return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                            key={`${key[0]}`}>
                            <Checkbox inputId={`${key[0]}`}
                                value={key[0]}
                                onChange={(e) => this.onTermCheckboxChange(e)}
                                checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                            />
                            <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                className='p-checkbox-label'>
                                {`${key[1]}`}
                            </label>
                        </div>
                    }
                } else if (this.props.fromHops) {
                    // console.log(this.props.filterHops)
                    if (this.props.filterHops && this.props.filterHops.length !== 0) {
                        if (!this.props.filterHops.some(filter => filter.key === key[0])) {
                            return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                                key={`${key[0]}`}>
                                <Checkbox inputId={`${key[0]}`}
                                    value={key[0]}
                                    onChange={(e) => this.onTermCheckboxChange(e)}
                                    checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                                />
                                <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                    className='p-checkbox-label'>
                                    {`${key[1]}`}
                                </label>
                            </div>
                        }
                    } else {
                        return <div className='col-12 md:col-4 lg:col-4' style={{ display: "inline-block" }}
                            key={`${key[0]}`}>
                            <Checkbox inputId={`${key[0]}`}
                                value={key[0]}
                                onChange={(e) => this.onTermCheckboxChange(e)}
                                checked={this.state.selectedTerms.includes(key[0]) ? true : false}
                            />
                            <label htmlFor={`{key[0]}`} style={(key[0] === 'relation' || key[0] === 'relation2' || key[0] === 'relation3' || key[0] === 'relation4' || key[0] === 'relation5') ? {} : { fontWeight: 'bold' }}
                                className='p-checkbox-label'>
                                {`${key[1]}`}
                            </label>
                        </div>
                    }
                }
            }
        })

        let dropdownOptions = []

        for (let i = this.props.hopsOfTable; i <= 5; i++) {
            dropdownOptions = [...dropdownOptions, { label: i, value: i }]
        }

        let radioItems = []

        radioItems = this.props.fromPath ? this.state.selectEntityItemsFull : this.props.fromSubPath ? this.state.selectEntityItemsSub : this.props.fromHops ? this.state.selectEntityItemsHop : this.state.selectEntityItems

        let checkboxEntities = []

        if (radioItems !== undefined) {
            checkboxEntities = radioItems.map(item =>
                <div className='col-12'
                    key={`${item.value}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${item.value}`}
                        onChange={(e) => this.onEntityChange(e)}
                        checked={this.state.selectedEntities.includes(item.value) ? true : false}
                        value={item.value} style={{ 'cursor': 'pointer' }}
                    />
                    <label style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '15vw' }} title={`${item.label}`} className='p-checkbox-label'>{`${item.label}`}</label>
                </div>
            )
        }

        let blocklists = []

        if (this.props.blacklists && this.props.blacklists?.length !== 0) {
            this.props.blacklists?.forEach(list => {
                if (list.owner && (list.owner.id === this.props.userDetails.id) || list.owner && (list.owner.id !== this.props.userDetails.id && list.editableForSharedUsers === true)) {
                    blocklists = [...blocklists, { label: list.title, value: list.id }]
                }
            })
        }

        let checkboxBlocklists = []

        if (blocklists.length > 0) {
            checkboxBlocklists = blocklists.map(item =>
                <div className='col-12'
                    key={`${item.value}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${item.value}`}
                        onChange={(e) => this.onBlacklistChange(e)}
                        checked={this.state.selectedBlackList.includes(item.value) ? true : false}
                        value={item.value} style={{ 'cursor': 'pointer', marginLeft: -2 }}
                    />
                    <label style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '15vw' }} title={`${item.label}`} className='p-checkbox-label'>{`${item.label}`}</label>
                </div>
            )
        }

        let tripleTripleHeader = ''

        if (this.state.selectionTripleTriple !== undefined) {
            tripleTripleHeader = <div>
                <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.selectedPath.start}</label></div>
                <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'normal' }}>{this.state.selectedPath.relation}</label></div>
                <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.selectedPath.end}</label></div>
                <div> <label style={{ color: '#757575' }}><NumberFormat value={this.state.selectedPath.hits} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />{this.state.selectedPath.hits > 1 ? ' paths' : ' path'}</label></div>
            </div>
        }

        let tripleFullHeaderSingle = ''

        if (this.state.selectionTripleFull !== undefined) {
            tripleFullHeaderSingle = <div>
                <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.selectedPath.start}</label></div>
                <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'normal' }}>{this.state.selectedPath.relation}</label></div>
                <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.selectedPath.end}</label></div>
                <div> <label style={{ color: '#757575' }}><NumberFormat value={this.state.selectedPath.hits} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />{this.state.selectedPath.hits > 1 ? ' paths' : ' path'}</label></div>
            </div>
        }

        let tripleFullHeader = []

        if (this.state.selectTripleItemsFull !== undefined) {
            this.state.selectTripleItemsFull.forEach((item, i) => {
                tripleFullHeader[i] = <div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.relations[i].start}</label></div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'normal' }}>{this.state.relations[i].relation}</label></div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.relations[i].end}</label></div>
                    <div> <label style={{ color: '#757575' }}><NumberFormat value={this.state.pathOccurrences[i]} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />{this.state.pathOccurrences[i] > 1 ? ' paths' : ' path'}</label></div>
                </div>
            })
        }

        let tripleSubHeader = []

        if (this.state.selectTripleItems !== undefined) {
            this.state.selectTripleItems.forEach((item, i) => {
                tripleSubHeader[i] = <div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.relations[i].start}</label></div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'normal' }}>{this.state.relations[i].relation}</label></div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.relations[i].end}</label></div>
                    <div> <label style={{ color: '#757575' }}><NumberFormat value={this.state.subPathOccurrences[i]} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />{this.state.subPathOccurrences[i] > 1 ? ' paths' : ' path'}</label></div>
                </div>
            })
        }

        let tripleHopHeader = []

        if (this.state.selectTripleItemsHop !== undefined) {
            this.state.selectTripleItemsHop.forEach((item, i) => {
                tripleHopHeader[i] = <div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.relations[i].start}</label></div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'normal' }}>{this.state.relations[i].relation}</label></div>
                    <div style={{ paddingBottom: 5 }}> <label style={{ color: 'black', fontWeight: 'bold' }}>{this.state.relations[i].end}</label></div>
                    <div> <label style={{ color: '#757575' }}><NumberFormat value={this.state.pathOccurrencesHop[i]} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} />{this.state.pathOccurrencesHop[i] > 1 ? ' paths' : ' path'}</label></div>
                </div>
            })
        }

        let filteredBlacklists = []

        this.props.blacklists && this.props.blacklists?.forEach(list => {
            if (list.owner && (list.owner.id === this.props.userDetails.id) || list.owner && (list.owner.id !== this.props.userDetails.id && list.writable === true)) {
                filteredBlacklists = [...filteredBlacklists, list]
            }
        })

        let panelContent

        panelContent = this.state.evidenceIDs && this.state.evidenceIDs.map((id, i) => {
            return <div key={id} style={{ paddingBottom: 5 }}>
                <span style={{ paddingRight: 15 }}>{id}</span>
                <span><CopyToClipboard text={this.split(id)}
                    onCopy={() => this.setState({ copied: true })}>
                    <a id="detailSbjct"
                        title='Copy to clipboard'
                        onClick={(e) => this.showCopySuccessGrowl(e)}
                        style={{ display: 'inline-block', float: 'right', marginRight: 10 }}>Copy</a>
                </CopyToClipboard></span>
            </div>
        })

        return (
            <div className='col-12 grid-nogutter'>
                <Toast ref={(el) => { this.growl = el }} />
                <ContextMenu model={this.state.menu} ref={this.cm} />
                <OverlayPanel ref={(el) => { this.op = el }}>
                    {panelContent}
                </OverlayPanel>
                <div className='col-12'>
                    <div style={{ marginBottom: 10 }}>
                        <div>
                            {hitCount === 0 &&
                                <p style={{
                                    color: 'black', fontSize: 21, fontWeight: 500, marginTop: 0
                                }}>No paths matched your search</p>
                            }
                            {hitCount > 0 &&
                                <p style={{
                                    color: 'black', fontSize: 21, fontWeight: 500, marginTop: 0
                                }}><NumberFormat value={hitCount} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} /> matching {hitCount === 1 ? 'path' : 'paths'}</p>
                            }
                        </div>
                        {hitCount > 0 ?
                            <div style={{ textAlign: 'right' }}>
                                <a title='Export is limited to 10,000 paths.'
                                    onClick={(e) => this.export(e, hitCount, relationOCIDs)}
                                    style={{ color: '#007fdb' }}>Export</a>
                            </div>
                            : null
                        }
                        {this.props.fromHops && hitCount > 0 &&
                            <div style={{ marginTop: -15, marginBottom: 5 }}>
                                <label style={{ fontSize: '14px', fontweight: '500', marginRight: '5px' }}>Choose number of hops:</label>
                                <Dropdown value={this.props.hopsToShow} options={dropdownOptions} onChange={(e) => this.handleDropdownChange(e)} />
                            </div>
                        }
                    </div>
                    {hitCount > 0 ?
                        <div className={(this.props.fullPathNumber === 5 && this.props.fromPath) || (this.props.hopsToShow === 5 && this.props.fromHops) ?
                            "datatable-responsive-demo-xxl" :
                            (this.props.fullPathNumber === 4 && this.props.fromPath) || (this.props.hopsToShow === 4 && this.props.fromHops) || (this.props.fromSubPath && this.props.subPathNumber === 3) ?
                                "datatable-responsive-demo-xl" :
                                (this.props.fullPathNumber === 3 && this.props.fromPath) || (this.props.hopsToShow === 3 && this.props.fromHops) || (this.props.fromSubPath && this.props.subPathNumber === 2) ?
                                    "datatable-responsive-demo-l" :
                                    (this.props.fullPathNumber === 2 && this.props.fromPath) || (this.props.hopsToShow === 2 && this.props.fromHops) || (this.props.fromSubPath && this.props.subPathNumber === 1) ?
                                        "datatable-responsive-demo-m"
                                        : "datatable-responsive-demo-s"} >
                            <DataTable value={this.props.fromTriple ? pathsTriple : this.props.fromSubPath ? pathsSub : this.props.fromHops ? pathsHop : paths}
                                className={(this.props.fullPathNumber === 5 && this.props.fromPath) || (this.props.hopsToShow === 5 && this.props.fromHops) ?
                                    "p-datatable-responsive-demo-xxl" :
                                    (this.props.fullPathNumber === 4 && this.props.fromPath) || (this.props.hopsToShow === 4 && this.props.fromHops) || (this.props.fromSubPath && this.props.subPathNumber === 3) ?
                                        "p-datatable-responsive-demo-xl" :
                                        (this.props.fullPathNumber === 3 && this.props.fromPath) || (this.props.hopsToShow === 3 && this.props.fromHops) || (this.props.fromSubPath && this.props.subPathNumber === 2) ?
                                            "p-datatable-responsive-demo-l" :
                                            (this.props.fullPathNumber === 2 && this.props.fromPath) || (this.props.hopsToShow === 2 && this.props.fromHops) || (this.props.fromSubPath && this.props.subPathNumber === 1) ?
                                                "p-datatable-responsive-demo-m"
                                                : "p-datatable-responsive-demo-s"}
                                selection={this.props.fromTriple ? this.props.selectedRowsTriple : this.props.fromSubPath ?
                                    this.props.selectedRowsSubPath : this.props.fromHops ? this.props.selectedRowsHops : this.props.selectedRowsPath}
                                onSelectionChange={e => this.handleSelectionChange(e)}
                                selectionMode="checkbox"
                                removableSort={true}
                                rowsPerPageOptions={[20, 50, 100]}
                                globalFilter={this.state.globalFilter}
                                emptyMessage="No paths found"
                                footer={footer}>
                                <Column field="check" selectionMode='multiple' style={{ width: '4em', fontWeight: 'normal', verticalAlign: 'middle' }} />
                                <Column field="counter" header="#" body={this.counterTemplate} style={{
                                    width: '6em',
                                    //textAlign: 'center',
                                    verticalAlign: 'middle'
                                }} />
                                <Column field="start" header="Entity 1" body={this.entityOneTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                <Column field="relation" header="Relation 1" body={this.relationOneTemplate} style={{ verticalAlign: 'middle' }} />
                                <Column field="end" header="Entity 2" body={this.entityTwoTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                {this.props.startTerm && this.props.fullPathNumber >= 2 && this.props.fromPath &&
                                    <Column field="relation2" header="Relation 2" body={this.relationTwoTemplate} style={{ verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.hopsToShow > 1 && this.props.fromHops &&
                                    <Column field="relation2" header="Relation 2" body={this.relationTwoTemplate} style={{ verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 1 && this.props.fromSubPath &&
                                    <Column field="relation2" header="Relation 2" body={this.relationTwoTemplate} style={{ verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.fullPathNumber >= 2 && this.props.fromPath &&
                                    <Column field="end2" header="Entity 3" body={this.entityThreeTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.startTerm && this.props.hopsToShow > 1 && this.props.fromHops &&
                                    <Column field="end2" header="Entity 3" body={this.entityThreeTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 1 && this.props.fromSubPath &&
                                    <Column field="end2" header="Entity 3" body={this.entityThreeTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.fullPathNumber >= 3 && this.props.fromPath &&
                                    <Column field="relation3" header="Relation 3" body={this.relationThreeTemplate} style={{ verticalAlign: 'middle' }} />
                                }
                                {this.props.startTerm && this.props.hopsToShow > 2 && this.props.fromHops &&
                                    <Column field="relation3" header="Relation 3" body={this.relationThreeTemplate} style={{ verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 2 && this.props.fromSubPath &&
                                    <Column field="relation3" header="Relation 3" body={this.relationThreeTemplate} style={{ verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.fullPathNumber >= 3 && this.props.fromPath &&
                                    <Column field="end3" header="Entity 4" body={this.entityFourTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.startTerm && this.props.hopsToShow > 2 && this.props.fromHops &&
                                    <Column field="end3" header="Entity 4" body={this.entityFourTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 2 && this.props.fromSubPath &&
                                    <Column field="end3" header="Entity 4" body={this.entityFourTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.fullPathNumber >= 4 && this.props.fromPath &&
                                    <Column field="relation4" header="Relation 4" body={this.relationFourTemplate} style={{ verticalAlign: 'middle' }} />
                                }
                                {this.props.startTerm && this.props.hopsToShow > 3 && this.props.fromHops &&
                                    <Column field="relation4" header="Relation 4" body={this.relationFourTemplate} style={{ verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 3 && this.props.fromSubPath &&
                                    <Column field="relation4" header="Relation 4" body={this.relationFourTemplate} style={{ verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.fullPathNumber >= 4 && this.props.fromPath &&
                                    <Column field="end4" header="Entity 5" body={this.entityFiveTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.startTerm && this.props.hopsToShow > 3 && this.props.fromHops &&
                                    <Column field="end4" header="Entity 5" body={this.entityFiveTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 3 && this.props.fromSubPath &&
                                    <Column field="end4" header="Entity 5" body={this.entityFiveTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.fullPathNumber >= 5 && this.props.fromPath &&
                                    <Column field="relation5" header="Relation 5" body={this.relationFiveTemplate} style={{ verticalAlign: 'middle' }} />
                                }
                                {this.props.startTerm && this.props.hopsToShow > 4 && this.props.fromHops &&
                                    <Column field="relation5" header="Relation 5" body={this.relationFiveTemplate} style={{ verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 4 && this.props.fromSubPath &&
                                    <Column field="relation5" header="Relation 5" body={this.relationFiveTemplate} style={{ verticalAlign: 'middle' }} />
                                }

                                {this.props.startTerm && this.props.fullPathNumber >= 5 && this.props.fromPath &&
                                    <Column field="end5" header="Entity 6" body={this.entitySixTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.startTerm && this.props.hopsToShow > 4 && this.props.fromHops &&
                                    <Column field="end5" header="Entity 6" body={this.entitySixTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.subPathNumber && this.props.subPathNumber >= 4 && this.props.fromSubPath &&
                                    <Column field="end5" header="Entity 6" body={this.entitySixTemplate} style={{ "fontWeight": 'bolder', verticalAlign: 'middle' }} />
                                }
                                {this.props.fromSubPath &&
                                    <Column field="hitsMin" body={this.hitsMinTemplate} header='Occurrences (min)' sortable={false} style={{ //textAlign: 'center',
                                        width: '8em', verticalAlign: 'middle'
                                    }} />
                                }
                                {this.props.fromPath && this.props.startTerm.length >= 2 &&
                                    <Column field="hitsMin" body={this.hitsMinTemplate} header='Occurrences (min)' sortable={false} style={{ //textAlign: 'center',
                                        width: '8em', verticalAlign: 'middle'
                                    }} />
                                }
                                {this.props.fromHops && this.props.hopsToShow > 1 &&
                                    <Column field="hitsMin" body={this.hitsMinTemplate} header='Occurrences (min)' sortable={false} style={{ //textAlign: 'center',
                                        width: '8em', verticalAlign: 'middle'
                                    }} />
                                }
                                <Column field="hits" body={this.hitsTemplate} header={this.props.fromTriple || (this.props.fromPath && this.props.startTerm.length < 2) || (this.props.fromHops && this.props.hopsToShow === 1) ? 'Occurrences' : 'Occurrences (avg)'}
                                    sortable={false} style={{ //textAlign: 'center', 
                                        width: '8em', verticalAlign: 'middle', fontWeight: 'bolder'
                                    }} />
                                <Column field="" header="" body={this.detailsBody} style={{ //textAlign: 'center',
                                    verticalAlign: 'middle', width: '80px'
                                }} />
                            </DataTable>
                        </div>
                        :
                        null}
                    <Dialog focusOnShow={false}
                        visible={this.state.displayDialog}
                        header={!this.props.fromHops ? `Details of ${this.props.header}` : this.props.fromHops && this.props.hopsToShow === 1 ? `Details of paths with ${this.props.hopsToShow} hop` : `Details of paths with ${this.props.hopsToShow} hops`}
                        style={{ width: '80vw' }}
                        modal={true}
                        dismissableMask={true}
                        closable={true}
                        //footer={dialogFooter} 
                        className='styledDialog'
                        onHide={() => this.setState({ displayDialog: false, activeIndex: 0, subRelationNumber: 0, fullRelationNumber: 0, hopRelationNumber: 0, selectedBlackList: [] })}>
                        {
                            this.state.path &&
                            <div className='col-12' style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 0 }}>
                                {this.props.fromTriple &&
                                    <TabView>
                                        <TabPanel header={
                                            tripleTripleHeader}>
                                        </TabPanel>
                                    </TabView>
                                }
                                {this.props.fromSubPath &&
                                    <TabView activeIndex={this.state.activeIndexSub} onTabChange={(e) => this.onSelectionTripleChange(e)}>
                                        {tripleSubHeader.map((header, i) => {
                                            return (
                                                <TabPanel key={i} header={header} />
                                            )
                                        })
                                        }
                                    </TabView>
                                }
                                {this.props.fromPath && this.props.startTerm.length === 1 &&
                                    <TabView>
                                        <TabPanel header={
                                            tripleFullHeaderSingle}>
                                        </TabPanel>
                                    </TabView>
                                }
                                {this.props.fromPath && this.props.startTerm.length > 1 &&
                                    <TabView activeIndex={this.state.activeIndexFull} onTabChange={(e) => this.onSelectionTripleFullChange(e)}>
                                        {tripleFullHeader.map((header, i) => {
                                            return (
                                                <TabPanel key={i} header={header} />
                                            )
                                        })
                                        }
                                    </TabView>
                                }
                                {this.props.fromHops &&
                                    <TabView activeIndex={this.state.activeIndexHops} onTabChange={(e) => this.onSelectionTripleHopChange(e)}>
                                        {tripleHopHeader.map((header, i) => {
                                            return (
                                                <TabPanel key={i} header={header} />
                                            )
                                        })
                                        }
                                    </TabView>
                                }
                            </div>
                        }
                        {
                            this.state.path &&
                            <div className='col-12 grid p-fluid' style={{ marginTop: 0, paddingLeft: 25, paddingRight: 5, paddingTop: 0 }}>
                                <div className={hasUserRole('ROLE_BLACKLISTS') ? "col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6" : "col-12"} style={{ paddingRight: hasUserRole('ROLE_BLACKLISTS') ? 25 : 0 }}>
                                    <div style={{ borderBottom: '1px solid #bdbdbd' }}>
                                        <label style={{ fontWeight: 'bold', display: 'block', paddingBottom: 14 }}>Evidence</label>
                                    </div>
                                    {this.props.fromTriple &&
                                        <div style={{ marginBottom: 15 }}>
                                            <DataTable
                                                scrollable={true}
                                                scrollHeight="200px"
                                                value={tripleSentences[this.state.tripleCounter]} style={{ marginTop: 10 }}>
                                                <Column field="repository" header="Repository" body={this.repositoryTemplate} style={{ width: '8em' }} />
                                                <Column field="source_date" header="Source date" body={this.sourceDateTemplate} style={{ width: '8em' }} />
                                                <Column field="ocDocId" header="Doc/Source ID" style={{}} body={this.docIDTemplate} />
                                                <Column field="sentence" header="Snippet/Link" body={this.sentenceTemplate} />
                                            </DataTable>
                                        </div>
                                    }
                                    {this.props.fromSubPath &&
                                        <div style={{ marginBottom: 15 }}>
                                            <DataTable
                                                scrollable={true}
                                                scrollHeight="200px"
                                                value={tripleSentencesSub[this.state.tripleCounter][this.state.subRelationNumber]} style={{ marginTop: 10 }}>
                                                <Column field="repository" header="Repository" body={this.repositoryTemplate} style={{ width: '8em' }} />
                                                <Column field="source_date" header="Source date" body={this.sourceDateTemplate} style={{ width: '8em' }} />
                                                <Column field="ocDocId" header="Doc/Source ID" style={{}} body={this.docIDTemplate} />
                                                <Column field="sentence" header="Snippet/Link" body={this.sentenceTemplate} />
                                            </DataTable>
                                        </div>
                                    }

                                    {this.props.fromPath && this.props.startTerm.length === 1 &&
                                        <div style={{ marginBottom: 15 }}>
                                            <DataTable
                                                scrollable={true}
                                                scrollHeight="200px"
                                                value={tripleSentencesFull[0][this.state.tripleCounter]} style={{ marginTop: 10 }}>
                                                <Column field="repository" header="Repository" body={this.repositoryTemplate} style={{ width: '8em' }} />
                                                <Column field="source_date" header="Source date" body={this.sourceDateTemplate} style={{ width: '8em' }} />
                                                <Column field="ocDocId" header="Doc/Source ID" style={{}} body={this.docIDTemplate} />
                                                <Column field="sentence" header="Snippet/Link" body={this.sentenceTemplate} />
                                            </DataTable>
                                        </div>
                                    }

                                    {this.props.fromPath && this.props.startTerm.length > 1 &&
                                        <div style={{ marginBottom: 15 }}>
                                            <DataTable
                                                scrollable={true}
                                                scrollHeight="200px"
                                                value={tripleSentencesFull[this.state.tripleCounter][this.state.fullRelationNumber]} style={{ marginTop: 10 }}>
                                                <Column field="repository" header="Repository" body={this.repositoryTemplate} style={{ width: '8em' }} />
                                                <Column field="source_date" header="Source date" body={this.sourceDateTemplate} style={{ width: '8em' }} />
                                                <Column field="ocDocId" header="Doc/Source ID" style={{}} body={this.docIDTemplate} />
                                                <Column field="sentence" header="Snippet/Link" body={this.sentenceTemplate} />
                                            </DataTable>
                                        </div>
                                    }
                                    {this.props.fromHops &&
                                        <div style={{ marginBottom: 15 }}>
                                            <DataTable
                                                scrollable={true}
                                                scrollHeight="200px"
                                                value={tripleSentencesHop[this.state.tripleCounter][this.state.hopRelationNumber]} style={{ marginTop: 10 }}>
                                                <Column field="repository" header="Repository" body={this.repositoryTemplate} style={{ width: '8em' }} />
                                                <Column field="source_date" header="Source date" body={this.sourceDateTemplate} style={{ width: '8em' }} />
                                                <Column field="ocDocId" header="Doc/Source ID" style={{}} body={this.docIDTemplate} />
                                                <Column field="sentence" header="Snippet/Link" body={this.sentenceTemplate} />
                                            </DataTable>
                                        </div>
                                    }
                                </div>
                                {hasUserRole('ROLE_BLACKLISTS') ?
                                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6">
                                        <div style={{ borderBottom: '1px solid #bdbdbd' }}>
                                            <label style={{ fontWeight: 'bold', display: 'block', paddingBottom: 14 }}>Blocklist</label>
                                        </div>
                                        {filteredBlacklists && filteredBlacklists.length > 0 ?
                                            <React.Fragment>
                                                <div style={{ marginTop: 9, marginBottom: 15 }}>
                                                    <label style={{ color: '#757575' }}>Blocklisting will take effect in new searches with applied blocklists</label>
                                                </div>

                                                <div className="grid p-fluid" style={{ paddingBottom: 20 }}>
                                                    <div className='col-6'>
                                                        <div style={{ paddingBottom: 5 }}>
                                                            <label style={{ fontWeight: 'bold' }}>Entities</label>
                                                        </div>
                                                        {checkboxEntities}
                                                    </div>
                                                    <div className='col-6'>
                                                        <div style={{ paddingBottom: 5 }}>
                                                            <label style={{ fontWeight: 'bold' }}>Blocklists</label>
                                                        </div>
                                                        {checkboxBlocklists}
                                                        <div style={{ paddingTop: 5 }}>
                                                            <a title='Create new blocklist' onClick={this.addNewBlacklist}
                                                                style={{ paddingLeft: 28 }}>
                                                                Create new
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='grid p-fluid' style={{ paddingBottom: 5 }}>
                                                        <div style={{ paddingBottom: 8 }}>
                                                            <label style={{ fontWeight: 'bold', paddingRight: 15 }}>Add selected concepts to selected blocklists:</label>
                                                        </div>
                                                        <div style={{ paddingRight: 10 }}>
                                                            {this.modes.map((mode) => {
                                                                return (
                                                                    <div style={{ display: 'inline', verticalAlign: 'top' }} key={mode.key} className="p-field-radiobutton">
                                                                        <RadioButton style={{ verticalAlign: 'middle', marginTop: -2 }} inputId={mode.key} name="mode" value={mode} onChange={(e) => this.setState({ selectedMode: mode })} checked={this.state.selectedMode.key === mode.key} />
                                                                        <label style={{ color: 'black', marginRight: 10, marginLeft: '0.5rem' }} htmlFor={mode.key}>{mode.name}</label>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                        <div>
                                                            <a title='Add entities to blocklists' onClick={() => this.blacklistConcept()}>
                                                                Add
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            :
                                            <div style={{ paddingTop: 15 }}>
                                                <label style={{ fontWeight: 'bolder' }}>No blocklist available. Create a new one? </label>
                                                <div style={{ paddingTop: 5 }}>
                                                    <a title='Create new blocklist' onClick={this.addNewBlacklist}
                                                        style={{}}>
                                                        Create new blocklist
                                                    </a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    : null}
                            </div>
                        }
                    </Dialog>

                    <CreateBlacklist
                        displayDialogAddBlacklist={this.props.displayDialogAddBlacklist}
                        hideAddBlacklist={this.props.hideAddBlacklist}
                        onSaveBlacklist={this.props.onSaveBlacklist}
                        newBlacklistName={this.props.newBlacklistName}
                        updateBlacklistName={this.updateBlacklistName}
                        newBlacklistDescription={this.props.newBlacklistDescription}
                        updateBlacklistDescription={this.updateBlacklistDescription}
                        userData={this.props.userData}
                        departmentMembers={this.props.departmentMembers}
                        checkedOrgKeys={this.props.checkedOrgKeys}
                        orgArray={this.props.orgArray}
                        handleMemberChange={this.props.handleMemberChange}
                        checkedKeys={this.props.checkedKeys}
                        userArray={this.props.userArray}
                        usersShared={this.props.usersShared}
                        onWriteMembersChange={this.props.onWriteMembersChange}
                        usersWrite={this.props.usersWrite}
                        sharedUsers={this.props.sharedUsers}
                        userNames={this.props.userNames}
                        onUserRemoveClick={this.props.onUserRemoveClick}
                        setUserWrite={this.props.setUserWrite}
                        users={this.props.users}
                        newOrgKeys={this.props.newOrgKeys}
                        newOrgArray={this.props.newOrgArray}
                        onNewOrgArrayChange={this.props.onNewOrgArrayChange}
                        userItems={this.props.userItems}
                        searchUser={this.props.searchUser}
                        allUsers={this.props.allUsers}
                        setUserValue={this.props.setUserValue}
                        userValue={this.props.userValue}
                        onSelectUser={this.props.onSelectUser}>
                    </CreateBlacklist>

                    <Dialog focusOnShow={false} visible={this.state.showFilterDialog} style={{ width: '50vw' }} header="Set filters"
                        modal={true} footer={footerFilter} dismissableMask={true}
                        onHide={() => this.setState({ showFilterDialog: false })} className='styledDialog'>
                        <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 30, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            {checkboxElements}
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogAddToBlacklist} style={{ width: '50vw' }} header="Add concepts to blocklists"
                        modal={true} dismissableMask={true}
                        onHide={() => this.closeAddToBlocklistDialog()} className='styledDialog'>
                        {filteredBlacklists && filteredBlacklists.length === 0 &&
                            <div className='col-12' style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 15 }}>
                                <label style={{ fontWeight: 'bolder' }}>No blocklist available. Create a new one? </label>
                                <div style={{ paddingTop: 5 }}>
                                    <a title='Create new blocklist' onClick={this.addNewBlacklist}
                                        style={{}}>
                                        Create new blocklist
                                    </a>
                                </div>
                            </div>
                        }
                        {filteredBlacklists && filteredBlacklists.length > 0 &&
                            <div className='col-12' style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 15 }}>
                                <div style={{ paddingBottom: 5 }}>
                                    <label style={{ fontWeight: 'bold' }}>Blocklists</label>
                                </div>
                                {checkboxBlocklists}
                                <div style={{ paddingTop: 5 }}>
                                    <a title='Create new blocklist' onClick={this.addNewBlacklist}
                                        style={{ paddingLeft: 28 }}>
                                        Create new
                                    </a>
                                </div>
                                <div style={{ paddingBottom: 5, paddingTop: 20 }}>
                                    <label style={{ fontWeight: 'bold', paddingRight: 15 }}>Add selected concepts to selected blocklists:</label>
                                    {this.modes.map((mode) => {
                                        return (
                                            <div style={{ display: 'inline', verticalAlign: 'top' }} key={mode.key} className="p-field-radiobutton">
                                                <RadioButton style={{ verticalAlign: 'middle', marginTop: -2 }} inputId={mode.key} name="mode" value={mode} onChange={(e) => this.setState({ selectedModeTable: mode })} checked={this.state.selectedModeTable.key === mode.key} />
                                                <label style={{ color: 'black', marginRight: 10, marginLeft: '0.5rem' }} htmlFor={mode.key}>{mode.name}</label>
                                            </div>
                                        )
                                    })
                                    }
                                    <a title='Add entities to blocklists' onClick={() => this.blacklistConceptTable()}
                                        style={{ float: 'right', paddingTop: 5 }}>
                                        Add
                                    </a>
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.state.displayDialogRemoveSelection} style={{ 'width': "40vw" }} header="Remove selection of entities"
                        modal={true} footer={footerRemoveSelection} dismissableMask={true}
                        onHide={() => this.setState({ displayDialogRemoveSelection: false })}>
                        <div className="grid p-fluid" style={{ marginTop: 10 }}>
                            Do you really want to clear the selection of entities?
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default InferenceResultTable;