/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { InputText } from 'primereact/inputtext';
//import axios from 'axios'
//import { getHeaderToken } from '../../../api/index'
import './Blacklists.css'

import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'

import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";

class BlacklistEntries extends Component {
    constructor(props) {
        super(props);
        let columnsEntries = [
            { field: "check", selectionMode: 'multiple', style: { width: '4em' } },
            { field: "prefName", header: "Concept (Preferred name)", sortable: true, style: { textAlign: 'left' }, body: this.conceptTemplate },
            { field: "conceptLookupType", header: "Search mode", body: this.searchModeTemplate, sortable: true, style: { textAlign: 'left' } },
            { field: "ocid", header: "OCID", style: { textAlign: 'left' }, body: this.ocidTemplate, sortable: true }
        ]
        this.state = {
            colsEntries: columnsEntries,
            domain: ['chem', 'substances', 'proteins', 'natprod', 'diseases', 'effects', 'drugs', 'species'],
            selectedBlacklists: [],
            selectedEntries: [],
            globalFilterConcepts: ''
        }
        this.colOptionsEntries = [];
        for (let col of this.state.colsEntries) {
            this.colOptionsEntries.push({ label: ` ${col.header}`, value: col });
        }
    };

    addNewBlacklist = () => {
        this.newBlacklist = true;
        this.setState({
            displayDialogAddBlacklist: true,
            newBlacklistName: ''
        });
    }

    addConcept = (mode) => {
        this.setState({
            displayDialogAddConcept: true,
            mode: mode
        });
    }



    searchModeTemplate = (rowData) => {
        const options = [
            { label: 'EXACT', value: 'EXACT' },
            { label: 'ONTOLOGICAL', value: 'ONTOLOGICAL' }
        ]
        if (rowData.hasOwnProperty('conceptLookupType')) {
            return <React.Fragment>
                <span className="p-column-title">Mode</span>
                {this.props.selectedBlacklist !== undefined && ((this.props.userData.userDetails.id === this.props.selectedBlacklistAction.owner.id) ||
                    (this.props.userData.userDetails.id !== this.props.selectedBlacklistAction.owner.id && this.props.selectedBlacklistAction.editableForSharedUsers === true)) ?
                    <span style={{ marginLeft: -6 }}>
                        <Dropdown
                            className='dropdownNoBorder'
                            value={rowData.conceptLookupType}
                            options={options}
                            onChange={(e) => this.props.onSearchModeConceptChange(e, rowData)}
                            style={{ marginTop: -10, marginBottom: -10 }} />
                    </span>
                    :
                    <span style={{}}>
                        {rowData.conceptLookupType}
                    </span>

                }
            </React.Fragment>
        }
    }

    ocidTemplate = (rowData) => {
        if (rowData.hasOwnProperty('ocid')) {
            return <React.Fragment>
                <span className="p-column-title">OCID</span>
                {rowData.ocid}
            </React.Fragment>
        }
    }

    conceptTemplate = (rowData) => {
        if (rowData.hasOwnProperty('prefName')) {
            return <React.Fragment>
                <span className="p-column-title">Concept</span>
                {rowData.prefName}
                {this.props.selectedBlacklistAction === undefined && rowData.conceptBlackListTitle ?
                    <span style={{ display: 'block', paddingTop: 5 }}>
                        <label style={{ color: '#757575' }}>In blocklist: {rowData.conceptBlackListTitle}</label>
                    </span>
                    :
                    null}
            </React.Fragment>
        }
    }


    /*refreshBlacklistEntries = async () => {
        var self = this;
        await axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${self.state.selectedBlacklistID}`, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showCollectionRefreshSuccessGrowl()
                let blacklistEntries = response.data
                self.setState({
                    blacklistEntries: blacklistEntries
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showBlacklistEntriesRefreshFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showBlacklistEntriesRefreshFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Blacklist not updated.', life: 6000 };
        this.growl.show(msg);
    }*/

    /*actionTemplateDelete = (rowData) => {
        return <div>
            <Button type="button" icon="pi-md-trash" className="p-button-rounded p-button-danger"
                disabled={rowData.name === 'History' || rowData.name === this.props.userData.userDetails.forename.concat('\'s watchlist') 
                || rowData.name === 'My Favorites' || rowData.name === 'My Queries' || rowData.sharedComment ? true : false}
                onClick={() => this.handleRemoveBlacklistDialog(rowData)}
                title='Warning! By clicking the button this blacklist will be removed irrecoverable!'>
            </Button>
        </div>
    }

    actionTemplateEntries = (rowData) => {
        return <div>
            <Button type="button" icon="pi-md-list" className="p-button-rounded p-button-success"
                //disabled={rowData.numberConcepts > 0 ? false : true}
                onClick={() => this.showEntries(rowData)}></Button>
        </div>
    }

    actionTemplateDeleteEntry = (rowData) => {
        return <div>
            <Button type="button" icon="pi-md-trash" className="p-button-rounded p-button-danger"
                onClick={() => this.handleRemoveEntryDialog(rowData)}
                disabled={false}
                title='Warning! By clicking the button this entry will be removed from the blacklist!'>
            </Button>
        </div>
    }*/

    /*handleRemoveBlacklistDialog = (rowData) => {
        this.setState({
            displayDialogRemoveBlacklist: true,
            rowDataBlacklistRemove: rowData
        })
    }

    onHideRemoveBlacklist = () => {
        this.setState({
            displayDialogRemoveBlacklist: false
        })
    }*/

    /*handleRemoveEntryDialog = (rowData) => {
        this.setState({
            displayDialogRemoveEntry: true,
            rowDataEntryRemove: rowData
        })

    }*/

    showBlacklistDeleteSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Blacklist removed from database.' };
        this.growl.show(msg);
    }

    showBlacklistDeleteFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection not removed from database.', life: 6000 };
        this.growl.show(msg);
    }

    /*handleRemoveBlacklist = async () => {
        //console.log(this.state)
        //let id = this.state.rowDataBlacklistRemove.id
        let ids = ''
        this.state.selectedBlacklists && this.state.selectedBlacklists.forEach(entry => {
            ids += entry.id + ","
        })
        ids = ids.slice(0, -1)
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists?blackListIds=${ids}`, { headers: getHeaderToken(), data: { 'blackListIds': ids } })
            .then(function (response) {
                if (response.status === 200) {
                    self.showBlacklistDeleteSuccessGrowl()
                    self.refreshBlacklists()
                    self.setState({
                        displayDialogRemoveBlacklist: false,
                        selectedBlacklists: []
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showBlacklistDeleteFailGrowl()
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }*/

    /*handleRemoveEntry = async () => {
        //let id = this.state.rowDataEntryRemove.id
        let ids = ''
        this.state.selectedEntries && this.state.selectedEntries.forEach(entry => {
            ids += entry.id + ","
        })
        ids = ids.slice(0, -1)
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?negatedConceptIds=${ids}`, { headers: getHeaderToken(), data: { 'negatedConceptIds': ids } })
            .then(function (response) {
                if (response.status === 200) {
                    self.showEntryRemoveSuccessGrowl()
                    self.refreshBlacklists()
                    self.refreshBlacklistEntries()
                    self.setState({
                        displayDialogRemoveEntry: false,
                        selectedEntries: []
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showEntryRemoveFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }*/

    showEntryRemoveSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Entry removed from blocklist.' };
        this.growl.show(msg);
    }

    showEntryRemoveFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Entry could not be removed from blocklist.', life: 6000 };
        this.growl.show(msg);
    }

    showEntryAddSuccessGrowl = () => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Entry successfully added to blocklist.' };
        this.growl.show(msg);
    }

    showEntryAddFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Entry could not be added to blocklist.', life: 6000 };
        this.growl.show(msg);
    }

    onHide = () => {
        this.setState({
            displayDialogRemoveEntry: false
        })
    }

    /*myCallbackFromSubmit = async (concepts) => {
        if (concepts && Object.keys(concepts).length > 0) {
            let conceptArray = []
            Object.keys(concepts).forEach(concept => {
                conceptArray = [...conceptArray, { ocid: concepts[concept].ocid, conceptLookupType: this.state.mode === 'ONTOLOGICAL' ? 'ONTOLOGICAL' : 'EXACT', scope: 'entity', prefName: concepts[concept].preferredName }]
            })
            let self = this
            axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/v1/blacklists/concepts?blackListId=${self.state.selectedBlacklistID}`, conceptArray, { headers: getHeaderToken() })
                .then(function (response) {
                    self.showEntryAddSuccessGrowl()
                    self.refreshBlacklists()
                    self.refreshBlacklistEntries()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showEntryAddFailGrowl()
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }


        this.setState({
            displayDialogAddConcept: false
        })
    }*/

    handleSelectionChange = (e) => {
        this.props.selectConcepts(e.value)
    }

    handleUnselect = (e) => {
        this.props.handleUnselect()
    }

    handleRemoveBlacklistDialog = () => {
        this.props.onRemoveBlacklist()
    }

    handleModifyBlacklistDialog = () => {
        this.props.onModifyBlacklist()
    }

    handleRemoveBlacklist = () => {
        this.props.handleRemoveBlacklist()
    }

    handleRemoveConceptsDialog = () => {
        this.props.handleRemoveConceptsDialog()
    }

    handleSearchModeConceptsDialog = () => {
        this.props.handleSearchModeConceptsDialog()
    }

    handleRemoveConcepts = async () => {
        let ids = ''
        this.props.selectedConceptsAction && this.props.selectedConceptsAction.forEach(concept => {
            ids += concept.id + ","
        })
        ids = ids.slice(0, -1)
        this.props.removeConcepts(ids)
    }

    handleSearchModeConcepts = async () => {
        let ids = ''
        this.props.selectedConceptsAction && this.props.selectedConceptsAction.forEach(concept => {
            ids += concept.id + ","
        })
        ids = ids.slice(0, -1)
        this.props.onSearchModeConceptChange(this.props.searchModeConcepts, ids)
    }

    handleAddConcepts = () => {
        this.props.handleAddConcepts()
    }

    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults || this.state.fetchingExportFile

        let usersShared = ''
        let usersWrite = ''
        let suborgsShared = ''
        if (this.props.selectedBlacklistAction && this.props.selectedBlacklistAction.userSharesRead && this.props.selectedBlacklistAction.userSharesRead.length > 0) {
            this.props.selectedBlacklistAction.userSharesRead.forEach(user => {
                if (this.props.allPossibleUsers?.length === 0 || this.props.allNotAllowedUsersRead?.includes(user.id)) {
                    usersShared += `${user.forename} ${user.lastName} (${user.name})` + '*, '
                } else {
                    usersShared += `${user.forename} ${user.lastName} (${user.name})` + ', '
                }
            })
        }
        if (this.props.selectedBlacklistAction && this.props.selectedBlacklistAction.userSharesWrite && this.props.selectedBlacklistAction.userSharesWrite.length > 0) {
            this.props.selectedBlacklistAction.userSharesWrite.forEach(user => {
                if (this.props.allPossibleUsers?.length === 0 || this.props.allNotAllowedUsersWrite?.includes(user.id)) {
                    usersShared += `${user.forename} ${user.lastName} (${user.name})` + '*, '
                    usersWrite += `${user.forename} ${user.lastName} (${user.name})` + '*, '
                } else {
                    usersShared += `${user.forename} ${user.lastName} (${user.name})` + ', '
                    usersWrite += `${user.forename} ${user.lastName} (${user.name})` + ', '
                }
            })
        }
        if (this.props.selectedBlacklistAction && this.props.selectedBlacklistAction.departmentSharesRead && this.props.selectedBlacklistAction.departmentSharesRead.length > 0) {
            this.props.selectedBlacklistAction.departmentSharesRead.forEach(dep => {
                if (this.props.allPossibleSuborgs?.length === 0 || this.props.allNotAllowedSuborgs?.includes(dep.id)) {
                    suborgsShared += `${dep.companyName} - ${dep.name}` + '*, '
                } else {
                    suborgsShared += `${dep.companyName} - ${dep.name}` + ', '
                }
            })
        }
        usersShared = usersShared.substring(0, usersShared.length - 2)
        usersWrite = usersWrite.substring(0, usersWrite.length - 2)
        suborgsShared = suborgsShared.substring(0, suborgsShared.length - 2)

        let dropdownFieldsEntries = [
        ];

        this.state.colsEntries && this.state.colsEntries.forEach(entry => {
            dropdownFieldsEntries = [...dropdownFieldsEntries, {
                label: entry.header,
                value: entry.field
            }]
        })

        let columnDataEntries

        if (this.props.selectedBlacklistAction !== undefined)// && (this.props.selectedBlacklistAction && this.props.userData.userDetails.id === this.props.selectedBlacklistAction.owner.id) ||
        //(this.props.selectedBlacklistAction && this.props.userData.userDetails.id !== this.props.selectedBlacklistAction.owner.id && this.props.selectedBlacklistAction.editableForSharedUsers === true)) 
        {
            columnDataEntries = this.state.colsEntries.map((col, i) => {
                return <Column className="columnheader" key={col.field}
                    field={col.field} selectionMode={col.selectionMode} sortable={col.sortable} header={col.header} body={col.body} style={col.style} />
            })
        } else {
            columnDataEntries = this.state.colsEntries.filter(column => column.field !== "check").map((col, i) => {
                return <Column className="columnheader" key={col.field}
                    field={col.field} selectionMode={col.selectionMode} sortable={col.sortable} header={col.header} body={col.body} style={col.style} />
            });
        }

        const footerRemoveBlacklist = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete" onClick={this.handleRemoveBlacklist} className='p-button-sm primaryButton' />
                <Button label="Cancel" onClick={this.props.onHideRemoveBlacklist} className="p-button-secondary p-button-sm" />
            </div>
        )

        const footerRemoveConcepts = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Delete" onClick={this.handleRemoveConcepts} className='p-button-sm primaryButton' />
                <Button label="Cancel" onClick={() => this.props.handleCloseRemoveConceptsDialog()} className="p-button-secondary p-button-sm" />
            </div>
        )

        const footerSearchModeConcepts = (
            <div style={{ paddingTop: 5 }}>
                <Button label="Cancel" onClick={() => this.props.handleCloseSearchModeConceptsDialog()} className="p-button-secondary p-button-sm" />
                <Button label="Change" onClick={this.handleSearchModeConcepts} className='p-button-sm primaryButton' />
            </div>
        )

        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;

        let ontBrowserDialogDomains = []

        if (typeof this.state.domain === 'string') {
            this.props.availableDomains && this.props.availableDomains.forEach(item => {
                if (item.value === this.state.domain) {
                    ontBrowserDialogDomains = [...ontBrowserDialogDomains, item]
                }
            })
        } else {
            this.props.availableDomains && this.props.availableDomains.forEach(item => {
                this.state.domain.forEach(dom => {
                    if (item.value === dom) {
                        ontBrowserDialogDomains = [...ontBrowserDialogDomains, item]
                    }
                })
            })
        }

        let headerBlacklistEntries = <div className='grid' style={{
            marginRight: 0,
            borderBottom: '1px solid #757575',
            paddingTop: 18, paddingBottom: 10,
            marginLeft: 2
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title={this.props.selectedBlacklist !== undefined ? "Search blocklist concepts" : "Search concepts"}>
                    {'search'}
                </i>
                <InputText
                    style={{ width: '82%', border: 'none', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap' }}
                    type="search"
                    className="collectionFilter"
                    value={this.state.globalFilterConcepts}
                    onInput={(e) => this.setState({ globalFilterConcepts: e.target.value })}
                    placeholder={this.props.selectedBlacklist !== undefined ? "Search blocklist concepts" : "Search concepts"}
                />
            </div>
        </div>


        /*let footerConcepts = <div className='col-12' style={{ paddingTop: 5, paddingBottom: 5, justifyContent: 'center', display: 'flex' }}>
            {this.props.selectedConceptsAction && this.props.selectedConceptsAction.length > 0 &&
                <div className='col-5' style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #d6d6d6', padding: '2em' }}>
                    <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{this.props.selectedConceptsAction.length} {this.props.selectedConceptsAction.length !== 1 ? 'items' : 'item'} selected</label>
                    {/  *(this.props.selectedWatchlist !== undefined && !this.props.selectedWatchlist.defaultCollection) || (this.props.selectedWatchlist !== undefined) ?
                        <a style={{ marginTop: 5, marginRight: 5 }}
                            title={this.props.selectedConceptsAction.length && this.props.selectedConceptsAction.length === 1 ? `Copy selected document` : `Copy ${this.props.selectedConceptsAction.length} selected documents`}
                            onClick={this.handleCopyQueryDialog} >Copy</a>
                        //: null
                    }
                    {/   *<a style={{ marginTop: 5 }}
                        title={this.props.selectedConceptsAction.length && this.props.selectedConceptsAction.length === 1 ? `Move selected document` : `Move ${this.props.selectedConceptsAction.length} selected documents`}
            onClick={this.handleMoveDocumentDialog}>Move</a>*  /}
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedConceptsAction.length && this.props.selectedConceptsAction.length === 1 ? `Change search mode of selected concept` : `Change search mode of ${this.props.selectedConceptsAction.length} selected concepts`}
                        onClick={this.handleSearchModeConceptsDialog}>Change search mode</a>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.props.selectedConceptsAction.length && this.props.selectedConceptsAction.length === 1 ? `Remove selected concept` : `Remove ${this.props.selectedConceptsAction.length} selected concepts`}
                        onClick={this.handleRemoveConceptsDialog}>Remove</a>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title='Unselect'
                        onClick={this.handleUnselect}>Unselect</a>
                </div>
            }
        </div>;*/

        let blacklistConcepts = []

        //console.log(this.props.selectedQueries)

        if (this.props.blacklistEntries) {
            this.props.blacklistEntries.forEach(concept => {
                blacklistConcepts = [...blacklistConcepts, concept]
            })
        }

        const searchModes = [
            { label: 'EXACT', value: 'EXACT' },
            { label: 'ONTOLOGICAL', value: 'ONTOLOGICAL' }
        ]

        //console.log(this.props.selectedBlacklistAction)

        return (
            <div className="col-12 grid-nogutter" style={{ paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, marginBottom: this.props.selectedConceptsAction && this.props.selectedConceptsAction.length > 0 ? 50 : 0, paddingRight: 0 }}>
                        <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 5, paddingTop: 9, paddingRight: 0 }}>
                            <div className='col-12 grid' style={{ paddingBottom: 0, paddingRight: 0, paddingLeft: 7 }}>
                                <div style={{}}>
                                    <label style={{
                                        marginTop: -1, fontSize: '1.1em', marginRight: 25, fontWeight: 'bold',
                                        overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', width: '100%'
                                    }}
                                        title={this.props.selectedBlacklist === undefined ? 'All blocked concepts' : `Blocklist: ${this.props.blacklistName}`}>
                                        {this.props.selectedBlacklist === undefined ? 'All blocked concepts' : `Blocklist: ${this.props.blacklistName}`}</label>
                                </div>
                                {this.props.selectedBlacklist !== undefined && (this.props.userData.userDetails.id === this.props.selectedBlacklistAction.owner.id) ?
                                    <div style={{ marginTop: 0, marginLeft: 'auto', marginRight: 0 }}>
                                        <a onClick={(e) => this.handleModifyBlacklistDialog(e)}
                                            style={{
                                                display: 'inline-block',
                                                marginRight: 20, marginTop: 0
                                            }}>Edit & Share</a>
                                        <a onClick={(e) => this.handleRemoveBlacklistDialog(e)}
                                            style={{ display: 'inline-block', marginRight: 0, marginTop: 0 }}>Delete</a>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>
                                <label style={{ marginTop: 0, float: 'left', marginBottom: 0, color: '#757575' }}>{this.props.blacklistDescription}</label>
                            </div>

                            {(this.props.selectedBlacklistAction !== undefined && !this.props.selectedBlacklistAction.defaultCollection) || (this.props.selectedBlacklistAction !== undefined) ?
                                <div className='col-12'
                                    style={{ display: 'inline-block', paddingBottom: 10, paddingLeft: 0, paddingTop: 0 }}>
                                    <label style={{ marginTop: 0, float: 'left', marginBottom: 5, color: '#757575' }}>
                                        {this.props.selectedBlacklistAction.departmentSharesRead && this.props.selectedBlacklistAction.departmentSharesRead.length > 0 ? <span title={`Shared with suborganizations:  ${suborgsShared} `} style={{
                                            whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                            display: 'inline-block'
                                        }}>{`Shared with suborganizations:  ${suborgsShared}`}&nbsp;</span> : ''}
                                        {this.props.selectedBlacklistAction.departmentSharesRead && this.props.selectedBlacklistAction.departmentSharesRead.length > 0 &&
                                            ((this.props.selectedBlacklistAction.userSharesRead && this.props.selectedBlacklistAction.userSharesRead.length > 0) ||
                                                (this.props.selectedBlacklistAction.userSharesWrite && this.props.selectedBlacklistAction.userSharesWrite.length > 0)) ?
                                            ' - '
                                            : ''
                                        }
                                        {(this.props.selectedBlacklistAction.userSharesRead && this.props.selectedBlacklistAction.userSharesRead.length > 0) ||
                                            (this.props.selectedBlacklistAction.userSharesWrite && this.props.selectedBlacklistAction.userSharesWrite.length > 0) ?
                                            <span title={`Shared with users: ${usersShared}`} style={{
                                                whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                                display: 'inline-block'
                                            }}>{`Shared with users: ${usersShared}`}&nbsp;</span> : ''}
                                        {(this.props.selectedBlacklistAction.userSharesWrite && this.props.selectedBlacklistAction.userSharesWrite.length > 0) || (this.props.selectedBlacklistAction.userSharesRead && this.props.selectedBlacklistAction.userSharesRead.length > 0) ?
                                            this.props.selectedBlacklistAction.userSharesWrite && this.props.selectedBlacklistAction.userSharesWrite.length > 0 ? <span title={`Editable for users: ${usersWrite}`} style={{
                                                whiteSpace: 'nowrap', maxWidth: '40vw', verticalAlign: 'bottom', overflow: 'hidden', textOverflow: 'ellipsis',
                                                display: 'inline-block'
                                            }}>{` - Editable for users: ${usersWrite}`}</span> : ' - Not editable for shared users'
                                            : ''
                                        }{this.props.allNotAllowedSuborgs?.length > 0 || this.props.allNotAllowedUsersRead?.length > 0 || this.props.allNotAllowedUsersWrite.length > 0 ?
                                            <span style={{}}>
                                                <span style={{ verticalAlign: 'middle' }}>&nbsp; -</span>
                                                <a className="infoIconLink"
                                                    style={{ cursor: 'default' }}
                                                    title="*: Suborganizations and users you are not allowed to administrate anymore but still share this collection with">
                                                    <img src={infoIcon} alt="Sharing information"
                                                        style={{ marginLeft: 10, verticalAlign: 'middle' }} />
                                                </a>
                                            </span>
                                            : ''
                                        }
                                    </label>
                                </div>
                                : null
                            }

                            {this.props.selectedBlacklist !== undefined && ((this.props.userData.userDetails.id === this.props.selectedBlacklistAction.owner.id) ||
                                (this.props.userData.userDetails.id !== this.props.selectedBlacklistAction.owner.id && this.props.selectedBlacklistAction.writable)) ?
                                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, paddingTop: 8 }}>
                                    <a onClick={(e) => this.handleAddConcepts(e)}
                                        style={{ display: 'inline-block', marginTop: 0, float: 'left', marginBottom: 0, paddingRight: 5 }}>Add concepts</a>
                                    <span style={{ marginLeft: -6 }}>
                                        <Dropdown
                                            className='dropdownNoBorder'
                                            value={this.props.searchMode}
                                            options={searchModes}
                                            onChange={(e) => this.props.onSearchModeChange(e)}
                                            style={{ marginTop: -9 }} />
                                    </span>
                                </div>
                                : null
                            }
                        </div>
                        {this.props.blacklistEntries && this.props.blacklistEntries.length > 0 ?
                            <div className="datatable-responsive-demo">
                                {headerBlacklistEntries}
                                <DataTable ref={(el) => this.dt = el}
                                    style={{ marginLeft: 0, marginRight: 0 }}
                                    selection={this.props.selectedConceptsAction}
                                    removableSort={true}
                                    onSelectionChange={e => this.handleSelectionChange(e)}
                                    selectionMode={(this.props.selectedBlacklistAction !== undefined) //&& (this.props.selectedBlacklistAction && this.props.userData.userDetails.id === this.props.selectedBlacklistAction.owner.id) ||
                                        //(this.props.selectedBlacklistAction && this.props.userData.userDetails.id !== this.props.selectedBlacklistAction.owner.id && this.props.selectedBlacklistAction.editableForSharedUsers === true))
                                        ? "checkbox" : null}
                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                    className="p-datatable-responsive-demo"
                                    value={blacklistConcepts}
                                    responsive="true"
                                    paginator={true}
                                    paginatorPosition="bottom"
                                    rows={this.props.rowsConcepts}
                                    globalFilter={this.state.globalFilterConcepts}
                                    first={this.props.firstConcepts}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    onPage={(e) => this.props.onCustomPage(e, true)}
                                    paginatorTemplate={CustomPaginatorTemplate}
                                    paginatorLeft={<CustomPaginatorLeftSide
                                        first={this.props.firstConcepts}
                                        contentLength={blacklistConcepts.length}
                                        rows={this.props.rowsConcepts}>
                                    </CustomPaginatorLeftSide>}
                                    paginatorRight={<CustomPaginatorRightSide
                                        rows={this.props.rowsConcepts}
                                        onChange={(e) => this.props.onCustomPage(e, false)}>
                                    </CustomPaginatorRightSide>}
                                //footer={footerConcepts}
                                //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"

                                >
                                    {columnDataEntries}
                                </DataTable>
                            </div>
                            :
                            <div className="dataTable" style={{ marginTop: 20 }}>
                                <label style={{ marginRight: 10, marginBottom: 20, verticalAlign: 'bottom', fontWeight: 'bold' }}>
                                    This blocklist contains no concepts.</label>
                            </div>
                        }
                    </div>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogRemoveBlacklist} style={{ 'width': "50vw" }} header="Delete blocklist"
                        modal={true} footer={footerRemoveBlacklist} dismissableMask={true}
                        onHide={() => this.props.onHideRemoveBlacklist()} className='styledDialog'>
                        <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            Warning! This blocklist will be deleted irrecoverable! Do you really want to delete this blocklist and all of its concepts?
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogRemoveConcepts} style={{ 'width': "50vw" }} header={this.props.selectedConceptsAction && this.props.selectedConceptsAction.length > 1 ? "Delete concepts" : this.props.selectedConceptsAction && this.props.selectedConceptsAction.length === 1 ? "Delete concept" : null}
                        modal={true} footer={footerRemoveConcepts} dismissableMask={true}
                        onHide={() => this.props.handleCloseRemoveConceptsDialog()} className='styledDialog'>
                        <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            {this.props.selectedConceptsAction && this.props.selectedConceptsAction.length > 1 ?
                                `Warning! These concepts will be deleted from this blocklist! Do you really want to delete these concepts?`
                                : this.props.selectedConceptsAction && this.props.selectedConceptsAction.length === 1 ?
                                    `Warning! This concept will be deleted from this blocklist! Do you really want to delete this concept?`
                                    : null
                            }
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false} visible={this.props.displayDialogSearchModeConcepts} style={{ 'width': "50vw" }} header={this.props.selectedConceptsAction && this.props.selectedConceptsAction.length > 1 ? "Change search mode" : this.props.selectedConceptsAction && this.props.selectedConceptsAction.length === 1 ? "Change search mode" : null}
                        modal={true} footer={footerSearchModeConcepts} dismissableMask={true} blockScroll
                        onHide={() => this.props.handleCloseSearchModeConceptsDialog()} className='styledDialog'>
                        <div style={{ paddingLeft: 25, paddingRight: 20, paddingBottom: 20, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            <div style={{ marginBottom: 10 }}>
                                <label className="valignMiddle" style={{ marginRight: 5 }}>Search mode:</label>
                                <Dropdown
                                    className='dropdownNoBorder valignMiddle'
                                    appendTo={document.body}
                                    value={this.props.searchModeConcepts}
                                    options={searchModes}
                                    onChange={(e) => this.props.onSearchModeChangeConcept(e)} />
                            </div>
                            {this.props.selectedConceptsAction && this.props.selectedConceptsAction.length > 1 ?
                                `Search mode of these concepts will be changed! Do you really want to change the search mode?`
                                : this.props.selectedConceptsAction && this.props.selectedConceptsAction.length === 1 ?
                                    `Search mode of this concept will be changed! Do you really want to change the search mode?`
                                    : null
                            }
                        </div>
                    </Dialog>
                </LoadingOverlay>
            </div>
        );
    }
}

export default BlacklistEntries;

