import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import { getHeaderToken } from '../../../api/index'
import { createCheckedKeys } from '.././util'
//import axios from 'axios'
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
//import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
//import { APP_PROPERTIES } from '../../../properties/index';

class CreateCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newCollectionName: '',
            newCollectionDescription: '',
            sharedForDepartment: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            editableForSharedUsers: false,
            userNames: [],
            newOrgArray: [],
            userValue: '',
            userItems: [],
            newOrgKeys: []
        };
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    onHide = () => {
        this.resetData()
        this.props.onHide()
    }

    resetData = () => {
        this.setState({
            newCollectionName: '',
            newCollectionDescription: '',
            sharedForDepartment: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            editableForSharedUsers: false,
            userNames: [],
            newOrgArray: [],
            userValue: '',
            userItems: [],
            newOrgKeys: []
        })
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.props.orgArray && this.props.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.props.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.props.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.props?.departmentMembers, this.props.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.props.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }

    handleCollectionDescriptionChange = (desc) => {
        this.setState({
            newCollectionDescription: desc
        })
    }


    handleCollectionNameChange = (name) => {
        this.setState({
            newCollectionName: name
        })
    }

    onSubmit = () => {
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }
        this.props.onSubmit(this.state.newCollectionName, this.state.newCollectionDescription, this.state.sharedForDepartment, usersRead, this.state.usersWrite, this.state.newOrgKeys, this.state.editableForSharedUsers)
    }

    searchUser = (event) => {
        setTimeout(() => {
            let _filteredUsers;

            if (!event.query.trim().length) {
                _filteredUsers = [...this.props.allUsers];
            }
            else {
                _filteredUsers = this.props.allUsers.filter((user) => {
                    return user.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({
                userItems: _filteredUsers
            })
        }, 150);
    }

    setUserValue = (e) => {
        this.setState({
            userValue: e.value
        })
    }

    onSelectUser = (user) => {
        if (!this.state.userNames.some(userName => userName.value === user.value)) {
            let newUserNames = [user, ...this.state.userNames]
            let newUserShared = [user.value, ...this.state.usersShared]
            this.setState({
                userNames: newUserNames,
                usersShared: newUserShared
            })
        }
        this.setState({
            userValue: ''
        })
    }

    onUserRemoveClick = (user) => {
        let newUserNames = this.state.userNames.filter(userName => userName.value !== user.value)
        let newUserShared = this.state.usersShared.filter(userShared => userShared !== user.value)
        let newUsersWrite = this.state.usersWrite.filter(userWrite => userWrite !== user.value)
        this.setState({
            userNames: newUserNames,
            usersShared: newUserShared,
            usersWrite: newUsersWrite
        })
    }

    onNewOrgArrayChange = (e) => {
        this.setState({
            newOrgKeys: e.value
        })
    }

    setUserWrite = (e, user) => {
        let newUsersWrite = [...this.state.usersWrite]
        if (e.value) {
            newUsersWrite = [...newUsersWrite, user.value]
        } else {
            newUsersWrite.splice(newUsersWrite.indexOf(user.value), 1)
        }
        this.setState({
            usersWrite: newUsersWrite
        })
    }

    render() {

        const footerAddCollection =
            <div className='col-12' style={{ paddingRight: 15, paddingBottom: 0 }}>
                <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 5 }}>* required</label>
                <Button className='p-button-secondary p-button-sm'
                    label="Cancel"
                    onClick={() => this.props.onHide()}
                />
                <Button className='primaryButton p-button-sm'
                    label="Create"
                    onClick={() => this.onSubmit()}
                    disabled={this.props.collectionName !== '' ? false : true}
                    style={{ marginRight: -5 }}
                />
                {/*this.props.showAddDocument ?
                    <Button className='primaryButton p-button-sm'
                        label="Create and add document"
                        onClick={() => this.props.onSubmit(this.state.name, this.state.description, true)}
                        disabled={!this.state.name}
                        style={{ marginRight: -5 }} />
                    : null
                */}
            </div>

        let sortedUsers = this.state.userNames?.sort((a, b) => a.label < b.label ? -1 : 1)

        let users = sortedUsers.map((user, i) => {
            return <>
                {i === 0 &&
                    <div key={`${i}_${user.label}`} className="grid" style={{ margin: 0, paddingRight: 0, paddingTop: 0 }}>
                        <div style={{ paddingBottom: 0, paddingRight: 0, paddingTop: 0 }} className="col-9 lg:col-9 xl:col-10">
                        </div>
                        <div style={{ paddingBottom: 0, paddingRight: 0, paddingTop: 0 }} className="col-3 lg:col-3 xl:col-2">
                            Can edit
                            <span><a className="infoIconLink valignMiddle"
                                style={{ marginTop: -1, cursor: 'default' }}
                                title="Users that can edit a document collection are allowed to i) add/remove documents and ii) move/copy documents being part of this document collection">
                                <img src={infoIcon} alt="Information about editing"
                                    style={{ marginLeft: 5 }} />
                            </a></span>
                        </div>
                    </div>
                }
                <div className="grid" style={{ margin: 0, paddingRight: 0 }}>
                    <div className="col-9 lg:col-9 xl:col-10" style={{ marginTop: 5, background: '#dee2e6', color: '#495057', borderRadius: 16, paddingRight: 0 }}>
                        <label>{user.label} <i style={{ fontSize: '0.7rem', cursor: 'pointer', paddingLeft: 5 }} onClick={(e) => this.onUserRemoveClick(user)} className="pi pi-times"></i></label>
                    </div>
                    <div className="col-3 lg:col-3 xl:col-2">
                        <InputSwitch style={{ marginTop: 3, paddingRight: 0 }} checked={this.state.usersWrite?.includes(user.value)} onChange={(e) => this.setUserWrite(e, user)} />
                    </div>
                </div>
            </>
        })

        return (
            <Dialog focusOnShow={false}
                visible={this.props.displayDialog}
                style={{ width: '70vw' }}
                breakpoints={{ '1500px': '95vw', '1050px': '100vw' }}
                header="Create new document collection"
                modal={true}
                blockScroll
                footer={footerAddCollection}
                dismissableMask={false}
                onShow={() => this.resetData()}
                onHide={() => this.onHide()} className='styledDialog'>
                <Toast ref={(el) => { this.growl = el }} />
                <div className="grid p-fluid" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                    <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                        <label htmlFor="name">Name *</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ padding: '.5em' }}>
                        <InputText id="name"
                            maxLength={50}
                            autoComplete="off"
                            onChange={(e) => { this.handleCollectionNameChange(e.target.value) }}
                            value={this.state.newCollectionName} />
                        <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                        <label htmlFor="description">Description</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ padding: '.5em' }}>
                        <InputText id="description"
                            maxLength={200}
                            autoComplete="off"
                            onChange={(e) => { this.handleCollectionDescriptionChange(e.target.value) }}
                            value={this.state.newCollectionDescription} />
                        <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                        <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                    </div>
                    <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ display: 'flex', paddingRight: 10, paddingTop: 5 }}>
                        <div className="w-full" >
                            <MultiSelect
                                id='userMultiSelect'
                                pt={{
                                    checkboxIcon: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            if (e.target.className.baseVal !== "") {
                                                e.target.parentNode.click();
                                            } else {
                                                e.target.parentNode.parentNode.click();
                                            }
                                        },
                                    },
                                    headerCheckbox: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            e.target.parentNode.click();
                                        },
                                    },
                                }}
                                appendTo={document.body}
                                filter
                                resetFilterOnHide
                                display='chip'
                                filterPlaceholder='Filter suborgs.'
                                value={this.state.newOrgKeys}
                                disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.props.departmentMembers && this.props.departmentMembers?.length !== 0 ? false : true}
                                options={this.props.newOrgArray}
                                onChange={(e) => this.onNewOrgArrayChange(e)}
                                placeholder="Select suborganizations"
                                style={{// width: 150, minWidth: 150,
                                    borderBottom: '1px solid gray', maxWidth: '27vw'
                                }} />
                        </div>
                        <div className="w-full " >
                            <AutoComplete
                                className='autocomplete-input-field width100perc'
                                style={{ marginLeft: 10, paddingRight: 5, marginTop: this.state.newOrgKeys?.length > 0 ? -1 : -4 }}
                                placeholder='Type to find users'
                                field='label'
                                value={this.state.userValue}
                                completeMethod={(e) => this.searchUser(e)}
                                suggestions={this.state.userItems}
                                onSelect={(e) => this.onSelectUser(e.value)}
                                onChange={(e) => this.setUserValue(e)}
                                disabled={this.props.departmentMembers && this.props.departmentMembers?.length !== 0 ? false : true} />
                            {this.state.userNames?.length > 0 ?
                                <div style={{ marginLeft: 10, marginTop: 10 }}>
                                    {/*<div>Visible for:</div>*/}
                                    <ScrollPanel className='userScrollPanel' style={{ width: '100%', height: '105px', maxHeight: `calc(${(users?.length * 42) + 20}px)` }}>
                                        {users}
                                    </ScrollPanel></div>
                                : null
                            }
                        </div>
                    </div>
                    <div className='col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10' style={{ marginLeft: 'auto' }}>
                        <div className="full-text-message" style={{ marginRight: 0 }}>
                            Note: Users changing the suborganization will lose access to document collections shared on suborganization level, but will keep access to document collections shared on personal level.
                        </div>
                    </div>
                </div>
                <div>
                    {/*<div className='col-12' style={{ paddingTop: 10, paddingRight: 0, marginTop: 10 }}>
                        <label style={{ float: 'left', paddingTop: 20 }}>* required</label>
                        <Button
                            label="Create"
                            className='p-button-sm primaryButton'
                            onClick={this.onSaveCollection}
                            disabled={this.state.newCollectionName && this.state.newCollectionName.length > 0 ? false : true}
                            style={{ float: 'right' }} />
                        <Button label="Cancel"
                            onClick={(e) => this.hideCreateCollection(e)}
                            className="p-button-secondary p-button-sm"
                            style={{ float: 'right', marginRight: 5 }} />
        </div>*/}
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.data
})
const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCollection));
