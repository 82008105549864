// add:
// <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/"                 title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/"                 title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>
// compound structure icon: http://www.iconsmind.com
//
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { checkResultAndGetPayload } from '../../../api';
import {
    createDocumentCollection,
    fetchAvailableDocumentCollections
} from '../../../api/content/DocumentApi';
import { APP_PROPERTIES } from '../../../properties/index';
import { getHeaderToken } from '../../../api/index'
import { createUserArray, } from '../util';
import axios from 'axios'
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CreateCollection from "./CreateCollection";
//import ConfirmationDialog from "../ConfirmationDialog";


class AddRemoveDocuments extends Component {

    constructor(props) {
        super(props);
        let columns = [
            { field: "check", selectionMode: this.props.loadCollection ? 'single' : 'multiple', style: { width: '4em', fontWeight: 'normal' } },
            { field: "name", header: "Name", sortable: true, style: {}, body: this.nameTemplate },
            { field: "numberDocuments", header: "Documents", sortable: true, style: { fontWeight: 'normal', textAlign: 'left' }, body: this.numberDocumentsTemplate },
            { field: "", header: "Sharing", body: this.sharedTemplate, sortable: true, style: { textAlign: 'left' } }
        ]
        this.state = {
            cols: columns,
            selectedCollections: []
        };

        this.growl = React.createRef();
    }


    /**
     * Clears selected collections when dialog is closed. 
     * Upper component will hide dialog.
     */
    onHide = () => {

        this.setState({
            selectedCollections: []
        });

        this.props.onCloseAddToCollectionsDialog();
    }

    onSubmitAddToCollections = (collections) => {
        this.setState({
            selectedCollections: []
        });

        this.props.onSubmitAddToCollections(collections)
    }

    /**
     * Fetches available collections.
     */
    fetchAvailableCollections = async () => {

        const filteredDocumentCollections = await this.getAvailableDocumentCollections();
        this.setState({
            documentCollections: filteredDocumentCollections
        });

        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/users`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    departmentMembers: response.data
                }, () => {
                    let userArray = createUserArray(response.data, self.props.userData, 'user')
                    let orgArray = createUserArray(response.data, self.props.userData, 'org')
                    let allPossibleUsers = []
                    userArray && userArray.forEach(org => {
                        org.children.forEach(dep => {
                            dep.children.forEach(user => {
                                allPossibleUsers = [...allPossibleUsers, user.id]
                            })
                        })
                    })
                    let allPossibleSuborgs = []
                    orgArray && orgArray.forEach(org => {
                        org.children.forEach(dep => {
                            allPossibleSuborgs = [...allPossibleSuborgs, dep.id]
                        })
                    })
                    let newOrgArray = []
                    orgArray?.forEach(org => {
                        org.children?.forEach(dep => {
                            newOrgArray = [...newOrgArray, { label: `${org.label} - ${dep.label}`, value: dep.id }]
                        })
                    })
                    let userList = []
                    userArray?.forEach(comp => {
                        comp.children?.forEach(dep => {
                            dep.children.forEach(user => {
                                userList = [...userList, { label: `${user.label} [${comp.label} - ${dep.label}]`, value: user.id }]
                            })
                        })
                    })
                    self.setState({
                        userArray: userArray,
                        orgArray: orgArray,
                        allPossibleUsers: allPossibleUsers,
                        allPossibleSuborgs: allPossibleSuborgs,
                        allUsers: userList,
                        newOrgArray: newOrgArray
                    })
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    /**
     * Filters available collections. Checks if they are editable for current user.
     */
    getAvailableDocumentCollections = async () => {

        const response = await fetchAvailableDocumentCollections(0, 100);
        const result = checkResultAndGetPayload(response, this.growl);

        let filteredDocumentCollections = [];
        if (result && result.content) {
            result.content.forEach(collection => {
                if (!this.props.loadCollection) {
                    if (collection.writable //&& !collection.hasOwnProperty('sharedComment')
                    ) { // sharedComment
                        filteredDocumentCollections.push(collection);
                    }
                } else {
                    if ((collection.numberDocuments > 0)) { // sharedComment
                        filteredDocumentCollections.push(collection);
                    }
                }
            })

        }

        return filteredDocumentCollections;
    }

    onCollectionChange = (e) => {
        this.setState({
            selectedCollections: e.value
        })
    }

    addNewCollection = () => {

        this.setState({
            displayDialogAddCollection: true,
            newCollectionName: '',
            newCollectionDescription: '',
            sharedForDepartment: false,
            editableForSharedUsers: false,
            usersShared: []
        });
    }

    handleCollectionNameChange = (name) => {
        this.setState({
            newCollectionName: name
        })
    }

    handleCollectionDescriptionChange = (desc) => {
        this.setState({
            newCollectionDescription: desc
        })
    }

    handleSharedDepartmentChange = (share) => {
        this.setState({
            sharedForDepartment: share
        })
    }

    handleMemberChange = (members) => {
        this.setState({
            usersShared: members
        })
    }

    handleEditableChange = (editable) => {
        this.setState({
            editableForSharedUsers: editable
        })
    }

    /**
     * Saves new collection with given name and description.
     */
    onSaveCollection = async (newCollectionName, newCollectionDescription, sharedForDepartment, usersRead, usersWrite, orgShared, editableForSharedUsers) => {

        const response = await createDocumentCollection(newCollectionName, newCollectionDescription, sharedForDepartment, usersRead, usersWrite, orgShared, editableForSharedUsers);
        //console.log(response)
        checkResultAndGetPayload(response, this.growl);

        if (response.statusCode <= 200) {
            const filteredDocumentCollections = await this.getAvailableDocumentCollections();
            let currentSelection = [...this.state.selectedCollections, filteredDocumentCollections[0]]

            let selectedCollections = [];
            if (filteredDocumentCollections) {
                filteredDocumentCollections.forEach(list => {
                    currentSelection.forEach(sel => {
                        if (list.id === sel.id) {
                            selectedCollections = [...selectedCollections, list]
                        }
                    })
                })
            }

            this.setState({
                displayDialogAddCollection: false,
                documentCollections: filteredDocumentCollections,
                selectedCollections: selectedCollections
            });
        }
    }

    nameTemplate = (rowData) => {
        if (rowData.name) {
            return <React.Fragment>
                <span className="p-column-title">Name</span>
                <label>{rowData.name}</label>
            </React.Fragment>
        }
    }

    numberDocumentsTemplate = (rowData) => {
        //console.log(rowData.numberDocuments)
        if (rowData.hasOwnProperty('numberDocuments')) {
            return <React.Fragment>
                <span className="p-column-title">Documents</span>
                <label>{rowData.numberDocuments}</label>
            </React.Fragment>
        }
    }

    sharedTemplate = (rowData) => {
        return <div style={{}}>
            <span className="p-column-title">Sharing</span>
            {!rowData.shared ?
                ((rowData.departmentSharesRead && rowData.departmentSharesRead.length > 0) || (rowData.userSharesRead && rowData.userSharesRead.length > 0) || (rowData.userSharesWrite && rowData.userSharesWrite.length > 0)) ?
                    <span title={`You share this document collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This document collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This document collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    render() {
        //console.log(this.props)

        const footerRemoveDocuments = <div>
            <Button className='p-button-sm primaryButton' label="Yes" onClick={() => this.onRemoveDocument()}
            />
            <Button label="No" onClick={() => this.onHideRemoveDocument()}
                className="p-button-secondary p-button-sm" />
        </div>;

        /*const footerAddCollection = <div>
            <label style={{ float: 'left', paddingTop: 20 }}>* required</label>
            <Button className='p-button-secondary p-button-sm' label="Cancel" onClick={() => this.setState({ displayDialogAddCollection: false })} />
            <Button className='primaryButton p-button-sm' label="Create" onClick={() => this.onSaveCollection()}
                disabled={!this.state.newCollectionName || this.state.newCollectionName === ''}
                style={{ marginRight: -5 }} />
        </div>;*/

        //console.log(this.state.documentCollections)

        let headerCollections = <div className='grid' style={{
            marginRight: 0,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search document collections">
                    {'search'}
                </i>
                <InputText
                    style={{ width: '82%', border: 'none', float: 'left' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterCollections: e.target.value })}
                    placeholder="Search document collections" />
            </div>
        </div>

        let columnData = this.state.cols.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} filterMatchMode={col.filterMatchMode} />
        })

        return (
            <React.Fragment>

                <Toast ref={this.growl} />

                <Dialog focusOnShow={false}
                    visible={this.props.displayDialogAddToCollections}
                    header={this.props.loadCollection ? `Load document collection` : `Add documents to document collections`}
                    style={{ minWidth: "50vw", width: '50vw' }}
                    modal={true}
                    dismissableMask={true}
                    closable={true}
                    //footer={dialogFooter} 
                    onShow={() => this.fetchAvailableCollections()}
                    onHide={() => this.onHide()}
                    className='styledDialog'>
                    <React.Fragment>
                        {this.state.documentCollections && this.state.documentCollections.length === 0 && !this.props.loadCollection ?
                            <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                <h4 style={{}}>Please create a new collection.</h4>
                                <a style={{ marginBottom: 5, marginLeft: 0, marginTop: 5 }}
                                    title="Create new collection" onClick={() => this.addNewCollection()}>
                                    Create collection</a>
                            </div>
                            : ((this.state.documentCollections && this.state.documentCollections.length !== 0) || (!this.state.documentCollections)) && !this.props.loadCollection ?
                                <div style={{ paddingLeft: 25, paddingTop: 20 }}>
                                    <a style={{ marginBottom: 5, marginLeft: 0, marginTop: 5 }}
                                        title="Create new collection" onClick={() => this.addNewCollection()}>
                                        Create collection</a>
                                </div>
                                : null
                        }
                        {this.state.documentCollections && this.state.documentCollections.length &&
                            <React.Fragment>
                                <div className="col-12 grid p-fluid" style={{ paddingLeft: 30, paddingRight: 15, paddingBottom: 20, paddingTop: 10, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                    <div className='col-12'>
                                        <div className="datatable-responsive-demo">
                                            {headerCollections}
                                            <DataTable ref={(el) => this.dt = el}
                                                className="p-datatable-responsive-demo"
                                                //scrollable
                                                //scrollHeight='250px'
                                                selection={this.state.selectedCollections}
                                                selectionMode={this.props.loadCollection ? "radiobutton" : "checkbox"}
                                                removableSort={true}
                                                onSelectionChange={e => this.onCollectionChange(e)}
                                                //className="dataTableQueries standardTableHeader"
                                                value={this.state.documentCollections}
                                                responsive="true"
                                                paginator={true}
                                                //paginatorPosition="bottom"
                                                rows={5}
                                                //paginatorPosition="bottom"
                                                //rows={this.state.rowsQueries}
                                                globalFilter={this.state.globalFilterCollections}
                                            //first={this.state.firstQueries}
                                            //rowsPerPageOptions={[10, 20, 50]}
                                            //footer={footerWatchlists}
                                            //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            >
                                                {columnData}
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12' style={{ paddingRight: 15, paddingTop: 8, marginBottom: 33 }}>
                                    <Button className="p-button-sm primaryButton"
                                        style={{ marginRight: 5, float: 'right', marginTop: 5 }}
                                        label={this.props.loadCollection ? "Load collection" : "Add documents"}
                                        //icon="pi-md-add-circle"
                                        disabled={this.state.selectedCollections && (this.state.selectedCollections.length > 0 || Object.keys(this.state.selectedCollections).length !== 0) ? false : true}
                                        onClick={(e) => this.onSubmitAddToCollections(this.state.selectedCollections)}
                                    />
                                    <Button className="p-button-secondary p-button-sm"
                                        style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }}
                                        label="Cancel"
                                        //icon="pi-md-add-circle"
                                        //disabled={this.state.selectedCollections && this.state.selectedCollections.length > 0 ? false : true}
                                        onClick={(e) => this.onHide()}
                                    />
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                </Dialog>
                {/*

                <ConfirmationDialog
                    displayDialog={this.state.displayDialogRemoveFromCollections}
                    onShow={this.fetchAvailableCollections}
                    onHide={() => this.props.onCloseRemoveFromCollectionsDialog()}
                    onSubmit={() => this.onRemoveDocument()}
                    headerText="Remove documents from collection"
                    messageText={`This document will be removed from the collection! Do you really want to proceed?
                    If you share the collection the document will also be removed for your colleagues. Proceed?`}
                    submitButtonLabel={"Remove"}
                />
                */}
                <Dialog focusOnShow={false}
                    visible={this.props.displayDialogRemoveFromCollections}
                    style={{ minWidth: "50vw" }}
                    header="Remove documents from collection"
                    modal={true}
                    footer={footerRemoveDocuments}
                    dismissableMask={true}
                    onShow={this.fetchAvailableCollections}
                    onHide={() => this.props.onCloseRemoveFromCollectionsDialog()}>
                    <div className="grid p-fluid" style={{ marginTop: 10 }}>
                        Warning! This document will be removed from the collection! Do you really want to proceed?
                        If you share the collection the document will also be removed for your colleagues.
                    </div>
                </Dialog>

                <CreateCollection
                    displayDialog={this.state.displayDialogAddCollection}
                    onSubmit={this.onSaveCollection}
                    onHide={() => {
                        this.setState({ displayDialogAddCollection: false })
                    }}
                    userArray={this.state.userArray}
                    orgArray={this.state.orgArray}
                    allUsers={this.state.allUsers}
                    newOrgArray={this.state.newOrgArray}
                    departmentMembers={this.state.departmentMembers}
                />

            </React.Fragment>
        );
    }
}

export default AddRemoveDocuments;
