/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { InputText } from 'primereact/inputtext';
import { hasUserRole } from '.././util'
import './Blacklists.css'
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip'

class BlacklistOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };

    addNewBlacklist = () => {
        this.props.onAddBlacklist()
    }

    onSortBlacklists = (e) => {
        this.props.onSortBlacklists(e.value)
    }

    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults
        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;


        let ontBrowserDialogDomains = []

        if (typeof this.state.domain === 'string') {
            this.props.availableDomains && this.props.availableDomains.forEach(item => {
                if (item.value === this.state.domain) {
                    ontBrowserDialogDomains = [...ontBrowserDialogDomains, item]
                }
            })
        } else {
            this.props.availableDomains && this.props.availableDomains.forEach(item => {
                this.state.domain.forEach(dom => {
                    if (item.value === dom) {
                        ontBrowserDialogDomains = [...ontBrowserDialogDomains, item]
                    }
                })
            })
        }

        let sortOptions = [
            { label: 'Entries', value: 'numberConcepts' },
            { label: 'Name', value: 'title' }
        ]

        let numberAllConcepts = this.props.allConcepts ? this.props.allConcepts.length : 0

        let headerBlacklists = <div className='grid' style={{
            marginRight: 0,
            borderBottom: '1px solid #727272',
            paddingTop: 15, paddingBottom: 10
        }}>
            <div className="col-12" style={{ padding: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title='Search blocklists'>
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', width: '84%' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterBlacklists: e.target.value })}
                    placeholder="Search blocklists"
                />
            </div>
        </div>

        let filtered

        let privateLists = []
        let sharedByLists = []
        let sharedWithLists = []


        /*this.props.allBlacklists && this.props.allBlacklists.length > 0 && this.props.allBlacklists.forEach(list => {
            if ((list.owner.id === this.props.userData.userDetails.id) && list.sharedWith.length === 0 && !list.sharedForDepartment) {
                privateLists = [...privateLists, list]
            } else if ((list.owner.id === this.props.userData.userDetails.id) && (list.sharedForDepartment || list.sharedWith.length > 0)) {
                sharedByLists = [...sharedByLists, list]
            } else if (list.owner.id !== this.props.userData.userDetails.id) {
                sharedWithLists = [...sharedWithLists, list]
            }
        })*/

        //let allListsSeparated = privateLists.concat(sharedByLists).concat(sharedWithLists)

        //console.log("Private: ", privateLists)
        //console.log("Shared by me: ", sharedByLists)
        //console.log("Shared with: ", sharedWithLists)
        //console.log("All: ", allListsSeparated)

        let globalFilter = ''
        if (this.state.globalFilterBlacklists && this.state.globalFilterBlacklists !== '') {
            globalFilter = this.state.globalFilterBlacklists
        }

        if (this.state.globalFilterBlacklists && globalFilter !== '') {
            if (this.props.allBlacklists && globalFilter) {
                filtered = this.props.allBlacklists.filter(function (el) {
                    //filtered = allListsSeparated.filter(function (el) {
                    return el.title.includes(globalFilter)
                    //|| (el.description && el.description.includes(globalFilter)
                    //)
                })
            }
        } else {
            filtered = this.props.allBlacklists
            //filtered = allListsSeparated
        }


        let blacklists = Object.entries(_.groupBy(filtered?.map(col => ({
            ...col, groupName: !col?.shared ?
                (((col?.departmentSharesRead && col?.departmentSharesRead?.length > 0) || (col?.userSharesRead && col?.userSharesRead?.length > 0) || (col?.userSharesWrite && col?.userSharesWrite?.length > 0)) ? 'Shared by you' : 'private') : 'Shared with you'
        })), 'groupName'))?.sort((x, y) => x[0].toString().length - y[0].toString().length)?.map((item) => {

            return <div key={item[0]}>
                <ReactTooltip place='right' isMultiline={true} type='light' delayShow={300}
                    desiredPlace={"right"} className='tooltip-custom' />
                <div className='col-group-title'>{item[0]}</div>
                {item[1].map((list, index) => {
                    return <div key={list.id} className='col-12' style={{
                        display: 'inline-block', paddingLeft: 0, borderBottom: index + 1 === item[1]?.length ? 'none' : '1px solid #d8d8d8',
                        backgroundColor: this.props.selectedBlacklist !== undefined && this.props.selectedBlacklist === list.id && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                    }} onClick={(e) => this.props.handleBlacklistChange(e, list)}>
                        <a onClick={(e) => this.props.handleBlacklistChange(e, list)}
                            style={{
                                marginTop: 0, paddingLeft: 8, float: 'left', marginBottom: 5
                                , color: 'black'
                            }} className='tooltip-title' title={list.title}>{list.title}</a>
                        <div style={{ display: 'inline-block', float: 'right' }}>
                            <label style={{
                                color: '#757575',
                                backgroundColor: this.props.selectedBlacklist !== undefined && this.props.selectedBlacklist === list.id && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                            }} onClick={(e) => this.props.handleBlacklistChange(e, list)}>{list.numberConcepts}</label>
                        </div>
                        <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 8, paddingTop: 0, paddingBottom: 0 }}>
                            {/* <label onClick={(e) => this.props.handleBlacklistChange(e, list)}>{list.description}  </label> */}
                            {
                                list.description &&

                                <div
                                    style={{
                                        backgroundColor: this.props.selectedBlacklist !== undefined && this.props.selectedBlacklist === list.id && '#e8f3fc'
                                    }}
                                    className='tooltip-description' title={list.description} onClick={(e) => this.props.handleBlacklistChange(e, list)}>{list.description}</div>
                            }

                            {
                                <span style={{ cursor: 'pointer' }} onClick={(e) => this.props.handleBlacklistChange(e, list)}>
                                    {list.shared &&
                                        <span style={{ color: '#757575' }} title={`This blocklist is shared with you by another user`}>
                                            {`Shared with you ${list.owner && list.owner.forename && list.owner.lastName && `(by ${list.owner.forename} ${list.owner.lastName})`}`}
                                        </span>
                                    }
                                    {list.shared && list.writable &&
                                        <span style={{ color: '#757575' }} title={`This blocklist is editable for shared users.`}>
                                            {' - Editable for shared users'}
                                        </span>
                                    }
                                    {list.shared && !list.writable &&
                                        <span style={{ color: '#757575' }} title={`This blocklist is not editable for shared users.`}>
                                            {' - Not editable for shared users'}
                                        </span>
                                    }
                                </span>
                            }
                            {
                                list.userSharesWrite && list.userSharesWrite.length > 0 ?
                                    <span style={{ color: '#757575' }} title={`This blocklist is editable for shared users.`}>
                                        {'Editable for shared users'}
                                    </span>
                                    : list.userSharesRead && list.userSharesRead.length > 0 ?
                                        <span style={{ color: '#757575' }} title={`This blocklist is not editable for shared users.`}>
                                            {'Not editable for shared users'}
                                        </span>
                                        : null
                            }
                        </div>
                    </div>


                })
                }
            </div>

        })





        return (
            <div className="col-12 grid-nogutter" style={{ paddingTop: 0, paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                        <div className='col-12' style={{
                            padding: 0
                            //, borderRight: '1px solid #d6d6d6' 
                        }}>
                            <div className='col-12'>
                                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, marginLeft: -4, paddingRight: 0 }}>
                                    <h1 style={{ marginTop: 0, fontSize: '1.1em', float: 'left', marginBottom: 5 }} className="pageHeader">Blocklists</h1>
                                    {/*this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") ?
                                        <a onClick={(e) => this.props.onImportClick(e)}
                                            style={{ display: 'inline-block', float: 'right', marginRight: -5, marginTop: 2 }}>Import</a>
                                        : null
                    */}
                                    <a onClick={(e) => this.addNewBlacklist(e)}
                                        style={{
                                            display: 'inline-block', float: 'right',
                                            marginRight: //this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") ? 15 : 
                                            -5, marginTop: 2
                                        }}>
                                        New blocklist</a>
                                </div>
                                {headerBlacklists}
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10, paddingBottom: 0, paddingLeft: 0 }}>
                                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, borderBottom: '1px solid #727272', paddingRight: 0, paddingTop: 0 }}>
                                    <label style={{ float: 'left', marginTop: -1 }}>Name</label>
                                    <label style={{ float: 'right', marginTop: -1 }}>Entries</label>
                                    <div style={{ display: 'inline-block', float: 'right', marginRight: 0, marginTop: -8 }}>
                                        <label style={{ marginRight: 0, verticalAlign: '-moz-middle-with-baseline' }}>Sort by: </label>
                                        <Dropdown
                                            className='dropdownNoBorder'
                                            value={this.props.sortBlacklistsBy}
                                            options={sortOptions}
                                            disabled={this.props.allBlacklists && this.props.allBlacklists.length > 1 ? false : true}
                                            onChange={(e) => this.onSortBlacklists(e)}
                                            style={{ marginRight: 5 }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10, paddingLeft: 0 }}>
                                <div className='col-12' style={{
                                    display: 'inline-block', paddingBottom: 10, borderBottom: '1px solid #757575',
                                    backgroundColor: this.props.selectedBlacklist === undefined && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                                }} onClick={(e) => this.props.handleBlacklistChange(e, 'All blocked concepts')}>
                                    {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>All saved queries</label>*/}
                                    <a onClick={(e) => this.props.handleBlacklistChange(e, 'All blocked concepts')}
                                        style={{
                                            marginTop: 0, float: 'left', marginBottom: 5
                                            , color: 'black'
                                        }}>All blocked concepts</a>
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.props.handleBlacklistChange(e, 'All blocked concepts')}>{numberAllConcepts}</label>
                                    </div>
                                    <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }}>
                                        <label style={{
                                            color: '#757575', cursor: 'pointer'
                                        }} onClick={(e) => this.props.handleBlacklistChange(e, 'All blocked concepts')}>All of your personal blocked concepts</label>
                                    </div>
                                </div>
                                {blacklists}
                                {/*<PagPaginator
                            first={this.state.first}
                            rows={this.state.rows}
                            template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            totalRecords={this.props.docCollections ? this.props.docCollections.length + 1 : 0}
                            onPageChange={(e) => this.pageChange(e)}
                        rowsPerPageOptions={[10, 20, 50]}></Paginator>*/}
                            </div>
                            {/*this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") && this.props.allBlacklists && this.props.allBlacklists.length > 0 ?
                                <div className='col-12' style={{ paddingTop: 0, paddingRight: 10 }}>
                                    <a onClick={(e) => this.props.onExportClick(e)}
                                        style={{
                                            marginTop: 0, float: 'right'
                                        }}>Export</a>
                                </div>
                                : null
                                    */}
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}

export default BlacklistOverview;

