/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
//import { Button } from 'primereact/button';
//import dateFormat from 'dateformat';
//import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
//import { Paginator } from 'primereact/paginator';
import { RadioButton } from 'primereact/radiobutton'
//import { APP_PROPERTIES } from '../../../properties/index';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import './Library.css'
import moment from 'moment';
import { hasUserRole } from '.././util'
//import { TimeScale } from 'chart.js/auto';
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip'


class DocCollections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            rows: 10,
            globalFilterCollections: ''
        }
    }

    addNewCollection = () => {
        this.props.onAddCollection()
    }


    truncate = (str, n) => {
        if (str.length <= n) return str; // Nothing to do
        if (n <= 1) return "…"; // Well... not much else we can return here!

        return str.slice(0, n) + "…";
    }

    collectionAlertChange = (col, status, e) => {
        //this.props.collectionAlertChange(col, status, e)
        this.props.openAddAlertDialog(col)
    }

    handleCollectionChange = (e, collection) => {
        e.stopPropagation()
        this.props.handleCollectionChange(collection)
    }

    onSortCollection = (e) => {
        this.props.onSortCollection(e.value)
    }


    render() {

        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults
        //const showIcons = APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons;

        let headerCollections =
            <div className='grid' style={{
                marginRight: 0,
                //borderBottom: '1px solid #d6d6d6',
                paddingTop: 15, paddingBottom: 10, borderBottom: '1px solid #727272'
            }}>
                <div className="col-12" style={{ padding: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                    <i className="material-icons"
                        style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                        title='Search collections'>
                        {'search'}
                    </i>
                    <InputText
                        style={{ border: 'none', width: '82%' }}
                        type="search"
                        className="collectionFilter"
                        onInput={(e) => this.setState({ globalFilterCollections: e.target.value })}
                        placeholder="Search collections"
                    />
                </div>
            </div>

        let sortOptions = [
            { label: 'Created', value: 'created' },
            { label: 'Entries', value: 'numberDocuments' },
            { label: 'Modified', value: 'modified' },
            { label: 'Name', value: 'name' }
        ]

        let numberAllDocuments = this.props.allDocuments ? this.props.allDocuments.length : 0

        let filtered

        let globalFilter = ''
        if (this.state.globalFilterCollections && this.state.globalFilterCollections !== '') {
            globalFilter = this.state.globalFilterCollections
        }

        if (this.state.globalFilterCollections && globalFilter !== '') {
            if (this.props.docCollections && globalFilter) {
                filtered = this.props.docCollections.filter(function (el) {
                    //filtered = allListsSeparated.filter(function (el) {
                    return el.name.includes(globalFilter) || (el.description && el.description.includes(globalFilter))
                })
            }
        } else {
            filtered = this.props.docCollections
            //filtered = allListsSeparated
        }

        const collections = Object.entries(_.groupBy(filtered?.map(col => ({
            ...col, groupName: !col?.shared ?
                (((col?.departmentSharesRead && col?.departmentSharesRead?.length > 0) || (col?.userSharesRead && col?.userSharesRead?.length > 0) || (col?.userSharesWrite && col?.userSharesWrite?.length > 0)) ? 'Shared by you' : 'private') : 'Shared with you'
        })), 'groupName'))?.sort((x, y) => x[0].toString().length - y[0].toString().length)?.map((item) => {

            return <div key={item[0]}>
                <ReactTooltip place='right' isMultiline={true} type='light' delayShow={300}
                    desiredPlace={"right"} className='tooltip-custom' />
                <div className='col-group-title'>{item[0]}</div>
                {item[1].map((col, index) => {
                    return <div key={col.id} className='col-12' style={{
                        display: 'inline-block', paddingBottom: 10, paddingLeft: 0, borderBottom: index + 1 === item[1]?.length ? 'none' : '1px solid #d8d8d8',
                        backgroundColor: this.props.collectionID === col.id && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                    }} onClick={(e) => this.handleCollectionChange(e, col)}>
                        {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>{col.name}</label>*/}
                        <div style={{ display: 'flow-root' }}>
                            {!this.props.addNewCollection && col.numberDocuments > 0 &&
                                <RadioButton
                                    inputId={col.name}
                                    name="name"
                                    value={col}
                                    onChange={(e) => this.handleCollectionChange(e, col)}
                                    checked={this.props.collectionID === col.id}
                                    //disabled={category.key === 'R'} 
                                    style={{ marginRight: 10, float: 'left', marginTop: -3, marginBottom: 10 }}>
                                </RadioButton>
                            }
                            <a onClick={(e) => this.handleCollectionChange(e, col)}
                                style={{
                                    marginTop: 0, float: 'left', marginBottom: 5, paddingLeft: 8
                                    , color: 'black'
                                }} className='tooltip-title' title={col.name}>{col.name}</a>
                            {this.props.addNewCollection && hasUserRole("ROLE_ALERTS") ?
                                ((col.notifications && col.notifications.length === 0) || col.notifications === undefined) ?
                                    <div style={{ display: 'inline-block', float: 'right', marginTop: -4 }}>
                                        <i className="material-icons-outlined" id='read'
                                            title={'Add alert to this document collection'}
                                            onClick={(e) => this.collectionAlertChange(col, 'add', e)}>
                                            {'notification_add'}
                                        </i>
                                    </div>
                                    : (col.notifications && col.notifications.length > 0 && col.notifications[0].active) ?
                                        <div style={{ display: 'inline-block', float: 'right', marginTop: -4 }}>
                                            <i className="material-icons" id='read'
                                                title={'You receive alerts for this document collection'}
                                                onClick={(e) => this.collectionAlertChange(col, 'pause', e)}>
                                                {'notifications_active'}
                                            </i>
                                        </div>
                                        : (col.notifications && col.notifications.length > 0 && !col.notifications[0].active) ?
                                            <div style={{ display: 'inline-block', float: 'right', marginTop: -4 }}>
                                                <i className="material-icons" id='read'
                                                    title={'Alerts for this document collection are paused'}
                                                    onClick={(e) => this.collectionAlertChange(col, 'activate', e)}>
                                                    {'notifications_paused'}
                                                </i>
                                            </div>
                                            : null
                                : null
                            }
                            <div style={{ display: 'inline-block', float: 'right', marginRight: this.props.addNewCollection && hasUserRole("ROLE_ALERTS") ? 22 : 0, cursor: 'pointer' }} onClick={(e) => this.handleCollectionChange(e, col)}>
                                <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleCollectionChange(e, col)}>{col.numberDocuments}</label>
                            </div>
                        </div>
                        <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 8, paddingTop: 0, cursor: 'pointer', paddingBottom: 0, marginTop: -5 }} onClick={(e) => this.handleCollectionChange(e, col)}>
                            {/* {col.description.length <= 50 ? */}
                            {
                                col.description && <div className='tooltip-description' title={col.description} onClick={(e) => this.handleCollectionChange(e, col)}>{col.description}</div>

                            }

                            {/* col.description ? " - Last modified: " : */}
                            <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleCollectionChange(e, col)}>{col.modified !== undefined && `${"Last modified: "}` + moment(col.modified + "Z").format("YYYY-MM-DD")} </label>
                            {
                                <span style={{ cursor: 'pointer' }} onClick={(e) => this.handleCollectionChange(e, col)}>
                                    {col.shared &&
                                        <span style={{ color: '#757575' }} title={`This search collection is shared with you by another user`}>
                                            {`- Shared with you ${col.owner && col.owner.forename && col.owner.lastName && `(by ${col.owner.forename} ${col.owner.lastName})`}`}
                                        </span>
                                    }
                                    {col.shared && col.writable &&
                                        <span style={{ color: '#757575' }} title={`This search collection is editable for shared users.`}>
                                            {' - Editable for shared users'}
                                        </span>
                                    }
                                    {col.shared && !col.writable &&
                                        <span style={{ color: '#757575' }} title={`This search collection is not editable for shared users.`}>
                                            {' - Not editable for shared users'}
                                        </span>
                                    }
                                </span>
                            }
                            {
                                col.userSharesWrite && col.userSharesWrite.length > 0 ?
                                    <span style={{ color: '#757575' }} title={`This search collection is editable for shared users.`}>
                                        {' - Editable for shared users'}
                                    </span>
                                    : col.userSharesRead && col.userSharesRead.length > 0 ?
                                        <span style={{ color: '#757575' }} title={`This search collection is not editable for shared users.`}>
                                            {' - Not editable for shared users'}
                                        </span>
                                        : null
                            }
                        </div>
                    </div>
                }
                )}

            </div>




        })




        return (
            <div className="col-12 grid-nogutter" style={{ paddingTop: 0, paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />

                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    <div className='col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                        <div className='col-12' style={{
                            padding: 0
                            //, borderRight: '1px solid #d6d6d6' 
                        }}>
                            <div className='col-12'>
                                <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0, marginLeft: -4, paddingRight: 0 }}>
                                    <h1 style={{ marginTop: 0, fontSize: '1.1em', float: 'left', marginBottom: 5 }} className="pageHeader">Document collections</h1>
                                    {/*this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && this.props.addNewCollection && !hasUserRole("ROLE_SUPPORT_ADMIN") ?
                                        <a onClick={(e) => this.props.onImportClick(e)}
                                            style={{ display: 'inline-block', float: 'right', marginRight: -5, marginTop: 1 }}>Import</a>
                                        : null
                    */}
                                    {this.props.addNewCollection &&
                                        <a onClick={(e) => this.addNewCollection(e)}
                                            style={{
                                                display: 'inline-block', float: 'right',
                                                marginRight: //this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && this.props.addNewCollection && !hasUserRole("ROLE_SUPPORT_ADMIN") ? 15 :
                                                 -5,
                                                marginTop: 1
                                            }}>New collection</a>
                                    }
                                </div>
                                {headerCollections}
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10, paddingBottom: 0, paddingLeft: 0 }}>
                                <div className='col-12' style={{ marginTop: this.props.addNewCollection ? -7 : -2, display: 'inline-block', paddingBottom: this.props.addNewCollection ? 0 : 5, paddingTop: 0, paddingLeft: 0, borderBottom: '1px solid #727272' }}>
                                    <label style={{ marginTop: this.props.addNewCollection ? 7 : 0, float: 'left', marginBottom: 0, paddingRight: 5 }}>Name</label>
                                    <div style={{ display: 'inline-block', float: 'right', marginRight: -25, marginTop: 0 }}>
                                        {this.props.addNewCollection &&
                                            <React.Fragment>
                                                <span style={{ marginLeft: 0 }}>
                                                    <label style={{ marginRight: 0, verticalAlign: '-moz-middle-with-baseline' }}>Sort by: </label>
                                                </span>
                                                <Dropdown
                                                    className='dropdownNoBorder'
                                                    value={this.props.sortCollectionBy}
                                                    options={sortOptions}
                                                    disabled={this.props.docCollections && this.props.docCollections.length > 1 ? false : true}
                                                    onChange={(e) => this.onSortCollection(e)}
                                                    style={{ marginRight: 5 }} />
                                            </React.Fragment>
                                        }
                                        <span style={{ marginLeft: 0 }}>
                                            <label style={{ marginRight: 15, verticalAlign: '-moz-middle-with-baseline' }}>Entries</label>
                                        </span>
                                        {this.props.addNewCollection && hasUserRole("ROLE_ALERTS") &&
                                            <span>
                                                <label style={{ marginRight: 17, verticalAlign: '-moz-middle-with-baseline' }}>Alert</label>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-12' style={{ paddingTop: 0, paddingRight: 10, paddingLeft: 0 }}>
                                {this.props.addNewCollection &&
                                    <div className='col-12' style={{
                                        display: 'inline-block', paddingBottom: 10, paddingLeft: 8,
                                        backgroundColor: this.props.collectionID === undefined && '#e8f3fc', marginTop: -3, cursor: 'pointer'
                                    }} onClick={(e) => this.handleCollectionChange(e, 'All documents')}>
                                        {/*<label style={{ marginTop: 0, float: 'left', marginBottom: 5 }}>All documents</label>*/}
                                        <a onClick={(e) => this.handleCollectionChange(e, 'All documents')}
                                            style={{
                                                marginTop: 0, float: 'left', marginBottom: 5
                                                , color: 'black'
                                            }}>All documents</a>
                                        <div>
                                            <div style={{ display: 'inline-block', float: 'right', marginRight: hasUserRole("ROLE_ALERTS") ? 46 : 0, cursor: 'pointer' }} onClick={(e) => this.handleCollectionChange(e, 'All documents')}>
                                                <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleCollectionChange(e, 'All documents')}>{numberAllDocuments}</label>
                                            </div>
                                            <div className='col-12' style={{ display: 'inline-block', float: 'left', paddingLeft: 0, paddingTop: 0, cursor: 'pointer', paddingBottom: 0 }} onClick={(e) => this.handleCollectionChange(e, 'All documents')}>
                                                <label style={{ color: '#757575', cursor: 'pointer' }} onClick={(e) => this.handleCollectionChange(e, 'All documents')}>All documents contained in your library</label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {collections}

                                {/*<PagPaginator
                                    first={this.state.first}
                                    rows={this.state.rows}
                                    template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    totalRecords={this.props.docCollections ? this.props.docCollections.length + 1 : 0}
                                    onPageChange={(e) => this.pageChange(e)}
                                rowsPerPageOptions={[10, 20, 50]}></Paginator>*/}
                            </div>
                            {/*this.props.userData.userDetails.highestAdminType === "ROLE_SUPER_ADMIN" && !hasUserRole("ROLE_SUPPORT_ADMIN") && this.props.docCollections && this.props.docCollections.length > 0 && this.props.addNewCollection ?
                                <div className='col-12' style={{ paddingTop: 0, paddingRight: 12 }}>
                                    <a onClick={(e) => this.props.onExportClick(e)}
                                        style={{
                                            marginTop: 0, float: 'right'
                                        }}>Export</a>
                                </div>
                                : null
                                    */}
                        </div>


                    </div>

                </LoadingOverlay>
            </div>
        )
    }
}

export default DocCollections
