import './QueryBuilder.css';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import { addThousandsSeparatorToNumber, isArrayEmpty, isObjectEmpty, sortObjectMapByAttribute } from "../../util";
import { APP_PROPERTIES, REPOSITORY_INFO_NOT_AVAILABLE, REPO_ROLE_DOC_SEARCH } from "../../../../properties";
import RepositoryOverview from "../../docresults/RepositoryOverview";
import DocumentResultWrapper from "../../docresults/DocumentResultWrapper";
import QueryBuilderForm from "./searchForm/QueryBuilderForm";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { KEY_PREFIX, addPatentFamilyGroupingToQueryString } from "../../general/docsearch/advancedSearch";
import { createSortFieldsList, determineActivePatentFamilyGrouping, determineActiveRepositoryV2, determineActiveSortCriteria } from "../../util/content";
import { addSearchResult, createEmptySearchResult } from "../../general/docsearch/searchUtil";
import { useDispatch, useSelector } from "react-redux";
import { createDocumentSearchRequestV2, exportDocumentsMetaData, exportDocumentsMetaDataByDocIDs, fetchDocuments, fetchSortableFields } from "../../../../api/content/DocumentApi";
import { checkResultAndGetPayload } from "../../../../api";
import { DEFAULT_QUERY_SETTINGS, DEFAULT_ROW_COUNT, DEFAULT_SORT_CRITERIA } from "../../general/docsearch/searchConstants";
import { extractSavedSearchData } from "../helpers/savedSearches";
import GlobalGrowl from "../../../common/queryBuilder/common/GlobalGrowl";
import { createGrowlMessage } from "../helpers/advancedSearch";
import PageHeader from '../../../common/queryBuilder/header/PageHeader';
import { QUERY_BUILDER_EXAMPLES } from '../../../common/queryBuilder/header/examples';
import ExportCenterSuccessMessage from '../../export/ExportCenterSuccessMessage';
import { setAdvancedSearch3FormContent, setSavedAdvancedSearch, setSearchGroupToEdit, showGlobalGrowl } from '../../../../redux/features/queryBuilder/actions';
import { filterDefinitionsSelector, repositoryFilterMapSelector } from '../../../../redux/common/filters/selectors/filters';
import { availableRepositoriesSelector } from '../../../../redux/common/userdata/selectors/userdata';


const INITIAL_MAIN_QUERY = { key: `${KEY_PREFIX}0`, query: "" };
const INITIAL_ADV_QUERIES = [{ ...INITIAL_MAIN_QUERY }];


const QueryBuilder = () => {

    const growl = useRef(null);

    const availableRepositories = useSelector(availableRepositoriesSelector);
    const filterDefinitions = useSelector(filterDefinitionsSelector);
    const repositoryFilterMap = useSelector(repositoryFilterMapSelector);
    const dispatch = useDispatch();

    const [repositories, setRepositories] = useState([]);

    const [advancedQueries, setAdvancedQueries] = useState(INITIAL_ADV_QUERIES);
    const [repositoryInfo, setRepositoryInfo] = useState(REPOSITORY_INFO_NOT_AVAILABLE);

    const [fetchingDocuments, setFetchingDocuments] = useState();
    const [loadSavedSearch, setLoadSavedSearch] = useState();
    const [fetchingExportData, setFetchingExportData] = useState();
    const [fetchingNumOfHits, setFetchingNumOfHits] = useState();

    const [showResults, setShowResults] = useState();
    const [showRepositoryStatistics, setShowRepositoryStatistics] = useState();

    const [repoStats, setRepoStats] = useState({});
    const [documents, setDocuments] = useState(null);
    const [selectedDocuments, setSelectedDocuments] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(20);
    const [sortCriteria, setSortCriteria] = useState({});
    const [sortFields, setSortFields] = useState({});

    const [groupByPatFamilies, setGroupByPatFamilies] = useState();
    const [patentFamilyGroupingOptions, setPatentFamilyGroupingOptions] = useState();
    const [patentFamilyGroupingValue, setPatentFamilyGroupingValue] = useState();
    const [isPatentFamilyGroupingAllowed, setIsPatentFamilyGroupingAllowed] = useState();
    // TODO: check if needed
    // const [isLoaderActive, setIsLoaderActive] = useState();

    useEffect(() => {
        const reposSorted = !isArrayEmpty(availableRepositories) ?
            sortObjectMapByAttribute(availableRepositories?.filter(
                repo => repo.active && repo.features?.includes(REPO_ROLE_DOC_SEARCH.id)
            ), 'orderPriority', false) : [];
        setRepositories(reposSorted);
    }, [availableRepositories]);

    useEffect(() => {
        if (showResults) {
            const offsetTop = document.getElementById("repositoryOverviewContainer")?.offsetTop;
            if (offsetTop) { window.scrollTo(0, offsetTop + 10) }
        }
    }, [showResults, documents]);


    const handleRunSearch = async (queries) => {
        if (!queries?.query && isArrayEmpty(queries?.filterQueries)) {
            const message = createGrowlMessage('Empty search', 'Empty search not allowed.', 'warn');
            dispatch(showGlobalGrowl(message));
            return;
        }

        const newActiveRepository = determineActiveRepositoryV2(repositories, repositoryInfo);
        const { repoStats, reposToFetch } = createEmptySearchResult(repositories);

        setAdvancedQueries(queries);
        setFetchingNumOfHits(true);
        setShowRepositoryStatistics(true);
        setRepoStats(repoStats);

        repositories?.forEach(repo => {
            // === fetch documents for active repository === //
            if (newActiveRepository.id === repo.id && !newActiveRepository.unavailable) {
                handleRunSearchInRepository(queries, newActiveRepository);
            }
            // === fetch number of hits for inactive repositories === //
            const request = createDocumentSearchRequestV2(repo.id, queries.query, queries.filterQueries);
            fetchDocuments(1, 0, request)
                .then(response => {
                    const repoStatsNew = addSearchResult(response, repo, repoStats);
                    setRepoStats({ ...repoStatsNew });
                })
                .catch(err => { console.log(err); })
                .finally(() => {
                    delete reposToFetch[repo.name];
                    if (isObjectEmpty(reposToFetch)) {
                        setFetchingNumOfHits(false);
                    }
                })
        });
    }

    const handleRunSearchInRepository = async (queries, repoInfo) => {
        const schema = await fetchRepositorySchema(repoInfo?.id);
        const sortFields = createSortFieldsList(schema?.sortCriteria, repoInfo);
        const sortCriteria = determineActiveSortCriteria(sortFields, DEFAULT_QUERY_SETTINGS.defaultSortingOptions, DEFAULT_SORT_CRITERIA);
        const { isPatFamGroupingAllowed, patFamGroupingOptions, patFamGroupingValue } =
            determineActivePatentFamilyGrouping(filterDefinitions, repositoryFilterMap, repoInfo);

        setShowRepositoryStatistics(true);
        setRepositoryInfo(repoInfo);
        setSortFields(sortFields);
        setSelectedDocuments({});
        setGroupByPatFamilies(true);
        setIsPatentFamilyGroupingAllowed(isPatFamGroupingAllowed);
        setPatentFamilyGroupingOptions(patFamGroupingOptions);

        fetchDocumentList(queries, repoInfo, 0, DEFAULT_ROW_COUNT, sortCriteria, isPatFamGroupingAllowed, patFamGroupingValue);
    }

    const fetchDocumentList = async (queries, repoInfo, start, count, sort, isPatFamGroupingAllowed, patFamGroupingValue, scroll = true) => {
        setFetchingDocuments(true);
        // FIXME: causes rerender by doc view pagination -> doc view should be done differently
        // setShowResults(false);
        setFirst(start);
        setRows(count);
        setSortCriteria(sort);
        setPatentFamilyGroupingValue(patFamGroupingValue);

        const queryWithPatFam = addPatentFamilyGroupingToQueryString(queries.query, isPatFamGroupingAllowed, patFamGroupingValue);
        const request = createDocumentSearchRequestV2(repoInfo?.id, queryWithPatFam, queries.filterQueries, null, sort?.sortField, sort?.sortMode, sort?.sortFactor);

        const response = await fetchDocuments(start + 1, count, request, false);
        const result = checkResultAndGetPayload(response, growl);

        setDocuments(result);
        setShowResults(true);
        setFetchingDocuments(false);
    }

    const fetchRepositorySchema = async (repositoryID) => {
        let schema;
        if (APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.fetchSortableFields) {
            const response = await fetchSortableFields(repositoryID);
            schema = checkResultAndGetPayload(response, growl);
        }
        return schema;
    }

    const handlePageChange = (startIndex, count) => {
        fetchDocumentList(advancedQueries, repositoryInfo, startIndex, count, sortCriteria, isPatentFamilyGroupingAllowed, patentFamilyGroupingValue);
    }

    const handleSortFieldChange = (sortField, runSearch = false) => {
        const sort = sortFields?.find(sf => sf.value === sortField) || DEFAULT_SORT_CRITERIA;
        fetchDocumentList(advancedQueries, repositoryInfo, 0, DEFAULT_ROW_COUNT, sort, isPatentFamilyGroupingAllowed, patentFamilyGroupingValue);
    }

    const handleSortModeChange = (sortMode, runSearch = false) => {
        const sort = sortCriteria ? { ...sortCriteria, sortMode: sortMode } : {};
        fetchDocumentList(advancedQueries, repositoryInfo, 0, DEFAULT_ROW_COUNT, sort, isPatentFamilyGroupingAllowed, patentFamilyGroupingValue);
    }

    const handleGroupByPatentFamilyChange = async (patFamGrouping) => {
        fetchDocumentList(advancedQueries, repositoryInfo, 0, DEFAULT_ROW_COUNT, sortCriteria, isPatentFamilyGroupingAllowed, patFamGrouping);
    }

    const handleExport = async (exportData, targetID, outputFormat) => {
        setFetchingExportData(true);

        let response;
        if (exportData.docIDs) {
            response = await exportDocumentsMetaDataByDocIDs(exportData.docIDs, targetID, outputFormat);
        }
        else {
            const queryWithPatFam = addPatentFamilyGroupingToQueryString(advancedQueries.query, true, patentFamilyGroupingValue);
            response = await exportDocumentsMetaData(repositoryInfo.id, queryWithPatFam, null, sortCriteria?.sortField,
                sortCriteria?.sortMode, exportData.maxNumOfResults, sortCriteria?.sortFactor, targetID, outputFormat);
        }
        checkResultAndGetPayload(response, growl, 'Success', <ExportCenterSuccessMessage />, null, true);

        setFetchingExportData(false);
    }

    const handleClearResults = () => {
        setShowResults(false);
        setShowRepositoryStatistics(false);
    }

    const handleSelectExample = (example) => {
        dispatch(setAdvancedSearch3FormContent(example?.searchGroups || []));
        dispatch(setSearchGroupToEdit(null));
        handleClearResults();
    }

    // TODO: handle saved searches in AdvancedSearchForm or here?
    const handleSelectSavedSearch = (savedSearch) => {
        const advancedSearchFromList = extractSavedSearchData(savedSearch);
        if (advancedSearchFromList) {
            const { formContent, ...savedSearchData } = advancedSearchFromList;
            dispatch(setSavedAdvancedSearch(savedSearchData));
            dispatch(setAdvancedSearch3FormContent(formContent?.searchGroups || []));
            dispatch(setSearchGroupToEdit(null));
            handleClearResults();
        }
    }


    const blockPage = fetchingDocuments || loadSavedSearch || fetchingExportData;
    const blockSearch = fetchingNumOfHits;
    let fetchingInfoText = 'Fetching data...';
    if (fetchingDocuments) { fetchingInfoText = "Fetching documents..."; }
    else if (fetchingExportData) { fetchingInfoText = "Export running..."; }
    else if (fetchingNumOfHits) { fetchingInfoText = "Fetching number of hits..."; }


    return <>
        <Toast ref={growl} />

        {/* TODO: move to upper component */}
        <GlobalGrowl />

        <LoadingOverlay
            active={blockPage}
            spinner={true}
            text={fetchingInfoText} >

            <div className="grid">
                <div className="col-12">

                    <LoadingOverlay
                        active={blockSearch}
                        text={fetchingInfoText} >

                        <PageHeader
                            pageID='advanced3'
                            heading='Query Builder'
                            infoText='Use the Query Builder tool to create powerful and complex searches without having to learn about our API syntax.'
                            examples={QUERY_BUILDER_EXAMPLES}
                            onSelectExample={handleSelectExample}
                            isBetaVersion />

                        <QueryBuilderForm
                            repositories={repositories}
                            onRunSearch={handleRunSearch}
                            onClearResults={handleClearResults}
                            onSelectSavedSearch={handleSelectSavedSearch}
                        />

                    </LoadingOverlay>
                </div>

                <div id='repositoryOverviewContainer' className="col-12">
                    {showRepositoryStatistics ?
                        <RepositoryOverview
                            headerText={`${repoStats && repoStats.hits ? addThousandsSeparatorToNumber(repoStats.hits) : 'No'} ${repoStats?.hits === 1 ? 'hit' : 'hits'}`}
                            repoStatisticsResults={repoStats}
                            fetching={fetchingNumOfHits}
                            onRepositoryClick={(repoInfo) => handleRunSearchInRepository(advancedQueries, repoInfo)}
                            selectedRepository={repositoryInfo.id}
                        /> : null
                    }
                </div>

                {showResults ?
                    <div id='resultsContainer' className="col-12">
                        {documents?.documentCount > 0 ?
                            <DocumentResultWrapper
                                first={first}
                                rows={rows}
                                showResults={showResults}
                                // setIsLoaderActive={setIsLoaderActive}
                                repositoryInfo={repositoryInfo}
                                documents={documents}
                                selectedDocuments={selectedDocuments}
                                onDocumentSelectionChange={(selDocs) => setSelectedDocuments(selDocs)}
                                ratingReadOnly={true}
                                onPageChange={handlePageChange}
                                onExport={handleExport}
                                sortFields={sortFields}
                                sortCriteria={sortCriteria}
                                onSortFieldChange={handleSortFieldChange}
                                onSortModeChange={handleSortModeChange}
                                query={advancedQueries.query}
                                filterQueries={advancedQueries.filterQueries}
                                hideSimilaritySearch={true}
                                showGroupByPatentFamily={true}
                                isPatentFamilyMutliple={true}
                                groupByPatentFamily={groupByPatFamilies}
                                patentFamilyGroupingValues={patentFamilyGroupingOptions}
                                onPatentFamilyGroupingChange={handleGroupByPatentFamilyChange}
                                patentFamilyGrouping={patentFamilyGroupingValue}
                                hasExportCenter={true}
                                internalQuery={advancedQueries.query}
                            />
                            :
                            <div id='noResultsContainer'>No hits found in {repositoryInfo?.label}</div>}
                    </div> : null}
            </div>
        </LoadingOverlay>
    </>
}

export default QueryBuilder;