/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from "react";
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch';
import {
    saveNewBlacklist
} from "../../../api/content/BlacklistApi";
import CreateBlacklist from './CreateBlacklist'
import { fetchUserShareStatusBlocklists } from "../../../api/content/SaveSearch";
import { createUserArray, createCheckedKeys } from "../../webapi/util";
import { checkResultAndGetPayload } from "../../../api";
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";


class AddToBlacklist extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedBlackList: [],
            departmentMembers: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            usersShared: [],
            orgShared: [],
            userArray: [],
            orgArray: [],
            usersWrite: [],
            newBlacklistName: '',
            newBlacklistDescription: '',
            userNames: [],
            newOrgArray: [],
            userValue: '',
            userItems: [],
            allUsers: [],
            newOrgKeys: []
        };

        this.growl = createRef();
    }

    componentDidMount() {
        this.updateUserShareStatus();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userData.userDetails !== prevProps.userData.userDetails) {
            this.updateUserShareStatus();
        }
    }

    updateUserShareStatus = async () => {

        const response = await fetchUserShareStatusBlocklists();
        const result = checkResultAndGetPayload(response, this.growl);

        if (result) {
            this.setState({
                departmentMembers: result
            }, () => {
                let userArray = createUserArray(result, this.props.userData, 'user')
                let orgArray = createUserArray(result, this.props.userData, 'org')

                let newOrgArray = []
                orgArray?.forEach(org => {
                    org.children?.forEach(dep => {
                        newOrgArray = [...newOrgArray, { label: `${org.label} - ${dep.label}`, value: dep.id }]
                    })
                })
                let userList = []
                userArray?.forEach(comp => {
                    comp.children?.forEach(dep => {
                        dep.children.forEach(user => {
                            userList = [...userList, { label: `${user.label} [${comp.label} - ${dep.label}]`, value: user.id }]
                        })
                    })
                })

                this.setState({
                    allUsers: userList,
                    newOrgArray: newOrgArray,
                    userArray: userArray,
                    orgArray: orgArray
                })
            });
        }
    }

    searchUser = (event) => {
        setTimeout(() => {
            let _filteredUsers;

            if (!event.query.trim().length) {
                _filteredUsers = [...this.state.allUsers];
            }
            else {
                _filteredUsers = this.state.allUsers.filter((user) => {
                    return user.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({
                userItems: _filteredUsers
            })
        }, 150);
    }

    setUserValue = (e) => {
        this.setState({
            userValue: e.value
        })
    }

    onSelectUser = (user) => {
        if (!this.state.userNames.some(userName => userName.value === user.value)) {
            let newUserNames = [user, ...this.state.userNames]
            let newUserShared = [user.value, ...this.state.usersShared]
            this.setState({
                userNames: newUserNames,
                usersShared: newUserShared
            })
        }
        this.setState({
            userValue: ''
        })
    }

    onUserRemoveClick = (user) => {
        let newUserNames = this.state.userNames.filter(userName => userName.value !== user.value)
        let newUserShared = this.state.usersShared.filter(userShared => userShared !== user.value)
        let newUsersWrite = this.state.usersWrite.filter(userWrite => userWrite !== user.value)
        this.setState({
            userNames: newUserNames,
            usersShared: newUserShared,
            usersWrite: newUsersWrite
        })
    }

    onNewOrgArrayChange = (e) => {
        this.setState({
            newOrgKeys: e.value
        })
    }

    setUserWrite = (e, user) => {
        let newUsersWrite = [...this.state.usersWrite]
        if (e.value) {
            newUsersWrite = [...newUsersWrite, user.value]
        } else {
            newUsersWrite.splice(newUsersWrite.indexOf(user.value), 1)
        }
        this.setState({
            usersWrite: newUsersWrite
        })
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.state.orgArray && this.state.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.state.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.state.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.state?.departmentMembers, this.state.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.state.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }
    onHideAddToBlacklist = () => {

        this.setState({
            selectedBlackList: []
        });

        this.props.onClose();
    }

    addNewBlacklist = () => {
        this.setState({
            displayDialogAddBlacklist: true,
            newBlacklistName: '',
            newBlacklistDescription: '',
            editableForSharedUsers: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            userItems: [],
            userNames: [],
            userValue: '',
            newOrgKeys: []
        })
    }

    updateBlacklistName = (name) => {
        this.setState({
            newBlacklistName: name
        })
    }

    updateBlacklistDescription = (desc) => {
        this.setState({
            newBlacklistDescription: desc
        })
    }

    addTermsToBlacklists = (mode) => {
        this.setState({
            selectedBlackList: []
        });
        this.props.onAddTermsToBlacklistSubmit(this.state.selectedBlackList, 'EXACT', 'entity');
    }


    onSaveBlacklist = async () => {

        // save new blacklist
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }
        let departments = []
        this.state.newOrgKeys.forEach(dep => {
            departments = [...departments, Number(dep)]
        })
        const response = await saveNewBlacklist(this.state.newBlacklistName, this.state.newBlacklistDescription, departments, usersRead, this.state.usersWrite);
        const result = checkResultAndGetPayload(response, this.growl);

        //console.log('result: ', result);

        // get all blacklists
        //const responseBlacklists = await fetchBlacklists();
        //const blacklists = checkResultAndGetPayload(responseBlacklists, this.growl);

        if (response?.status === 'SUCCESS') {
            this.props.onBlacklistsChanged();

            this.setState({
                displayDialogAddBlacklist: false,
                //documentCollections: blacklists
            });
        }
    }

    onBlacklistChange = (e) => {
        let selected = [...this.state.selectedBlackList]
        if (e.checked) {
            selected.push(e.value)

        } else {
            selected.splice(selected.indexOf(e.value), 1)
        }
        this.setState({
            selectedBlackList: selected
        })
    }

    hideAddBlacklist = () => {
        this.setState({
            displayDialogAddBlacklist: false
        })
    }

    render() {

        const footerAddConceptsToBlacklist =
            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Button label="Cancel"
                    onClick={() => this.onHideAddToBlacklist()}
                    className="p-button-secondary p-button-sm" />
                <Button label="Add to blocklists"
                    onClick={() => this.addTermsToBlacklists()}
                    className="primaryButton p-button-sm"
                    disabled={this.state.selectedBlackList.length === 0 ? true : false}
                />
            </div>


        let filteredBlacklists = []

        this.props.blacklists && this.props.blacklists.forEach(list => {
            if (list.owner && (list.owner.id === this.props.userData.userDetails.id) || list.owner && (list.owner.id !== this.props.userData.userDetails.id && list.writable === true)) {
                filteredBlacklists = [...filteredBlacklists, list]
            }
        })

        let checkboxBlocklists = []

        if (filteredBlacklists.length > 0) {
            checkboxBlocklists = filteredBlacklists.map(item =>
                <div className='col-12'
                    key={`${item.id}`} style={{ paddingLeft: 0 }}>
                    <Checkbox inputId={`${item.id}`}
                        onChange={(e) => this.onBlacklistChange(e)}
                        checked={this.state.selectedBlackList.includes(item.id) ? true : false}
                        value={item.id} style={{ 'cursor': 'pointer', marginLeft: -2 }}
                    />
                    <label className='p-checkbox-label'>{`${item.title}`}</label>
                </div>
            )
        }

        let sortedUsers = this.state.userNames?.sort((a, b) => a.label < b.label ? -1 : 1)

        let users = sortedUsers.map((user, i) => {
            return <>
                {i === 0 &&
                    <div key={`${i}_${user.label}`} className="grid" style={{ margin: 0, paddingRight: 0, paddingTop: 0 }}>
                        <div style={{ paddingBottom: 0, paddingRight: 0, paddingTop: 0 }} className="col-9 lg:col-9 xl:col-10">
                        </div>
                        <div style={{ paddingBottom: 0, paddingRight: 0, paddingTop: 0 }} className="col-3 lg:col-3 xl:col-2">
                            Can edit
                            <span><a className="infoIconLink valignMiddle"
                                style={{ marginTop: -1, cursor: 'default' }}
                                title="Users that can edit a blocklist are allowed to i) add/remove concepts, ii) move/copy concepts and iii) change the search mode of concepts being part of this blocklist">
                                <img src={infoIcon} alt="Information about editing"
                                    style={{ marginLeft: 5 }} />
                            </a></span>
                        </div>
                    </div>
                }
                <div className="grid" style={{ margin: 0, paddingRight: 0 }}>
                    <div className="col-9 lg:col-9 xl:col-10" style={{ marginTop: 5, background: '#dee2e6', color: '#495057', borderRadius: 16, paddingRight: 0 }}>{user.label} <i style={{ fontSize: '0.7rem', cursor: 'pointer', paddingLeft: 5 }} onClick={(e) => this.onUserRemoveClick(user)} className="pi pi-times"></i>
                    </div>
                    <div className="col-3 lg:col-3 xl:col-2">
                        <InputSwitch style={{ marginTop: 3, paddingRight: 0 }} checked={this.state.usersWrite?.includes(user.value)} onChange={(e) => this.setUserWrite(e, user)} />
                    </div>
                </div>
            </>
        })

        return (
            <React.Fragment>

                <Toast ref={this.growl} />

                <Dialog
                    focusOnShow={false}
                    visible={this.props.visible}
                    header="Add concepts to blocklists"
                    footer={footerAddConceptsToBlacklist}
                    style={{ width: '60vw' }}
                    modal={true}
                    dismissableMask={true}
                    onHide={() => this.onHideAddToBlacklist()}
                    className='styledDialog'>
                    {!this.props.blacklists || filteredBlacklists.length === 0 ?
                        <div style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 15, paddingBottom: 15, paddingTop: 15 }}>
                            <label style={{ marginLeft: 10, marginRight: 10, fontWeight: 'bolder' }}>No blocklist available. Please create a new one to continue.</label>
                            <div style={{ marginLeft: 10 }}>
                                <a style={{ display: 'inline-block', marginTop: 10 }}
                                    onClick={() => this.addNewBlacklist()}
                                    className="primaryLink">
                                    Create new blocklist</a>
                            </div>
                        </div>
                        :
                        <div style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingBottom: 15, paddingTop: 15 }}>
                            <div style={{ marginLeft: 0, paddingBottom: 15 }}>
                                <a style={{ display: 'inline-block', marginTop: 0 }}
                                    onClick={() => this.addNewBlacklist()}
                                    className="primaryLink">
                                    Create new blocklist</a>
                            </div>
                            <h4 style={{ marginBottom: 5 }}>Select blocklists</h4>
                            {checkboxBlocklists}
                        </div>
                    }
                </Dialog>

                <CreateBlacklist
                    displayDialogAddBlacklist={this.state.displayDialogAddBlacklist}
                    hideAddBlacklist={this.hideAddBlacklist}
                    onSaveBlacklist={this.onSaveBlacklist}
                    newBlacklistName={this.state.newBlacklistName}
                    updateBlacklistName={this.updateBlacklistName}
                    newBlacklistDescription={this.state.newBlacklistDescription}
                    updateBlacklistDescription={this.updateBlacklistDescription}
                    userData={this.props.userData}
                    departmentMembers={this.state.departmentMembers}
                    checkedOrgKeys={this.state.checkedOrgKeys}
                    orgArray={this.state.orgArray}
                    handleMemberChange={this.handleMemberChange}
                    checkedKeys={this.state.checkedKeys}
                    userArray={this.state.userArray}
                    usersShared={this.state.usersShared}
                    onWriteMembersChange={this.onWriteMembersChange}
                    usersWrite={this.state.usersWrite}
                    sharedUsers={this.state.sharedUsers}
                    userNames={this.state.userNames}
                    onUserRemoveClick={this.onUserRemoveClick}
                    setUserWrite={this.setUserWrite}
                    users={users}
                    newOrgKeys={this.state.newOrgKeys}
                    newOrgArray={this.state.newOrgArray}
                    onNewOrgArrayChange={this.onNewOrgArrayChange}
                    userItems={this.state.userItems}
                    searchUser={this.searchUser}
                    allUsers={this.state.allUsers}
                    setUserValue={this.setUserValue}
                    userValue={this.state.userValue}
                    onSelectUser={this.onSelectUser}>
                </CreateBlacklist>

            </React.Fragment>
        );
    }
}

export default AddToBlacklist;
