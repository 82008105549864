/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { getHeaderToken } from '../../../api/index'
import axios from 'axios'
import { AutoComplete } from "primereact/autocomplete";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { APP_PROPERTIES } from '../../../properties/index';
import Watchlists from './Watchlists'
import WatchlistQueries from './WatchlistQueries'
import LoadingOverlay from "@speedy4all/react-loading-overlay";
import moment from 'moment';
import { hasUserRole, isArrayEmpty, createUserArray, createCheckedKeys } from '.././util'
import { videos } from '../../../properties/videos'
import { saveAs } from 'file-saver';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import AddEditAlert from '../Alerts/AddEditAlert'
import TutorialVideos from '../general/TutorialVideos';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';
import infoIcon from "../../../assets/images/icons/info/dimensions-icon-info.png";
class WatchlistsAndQueries extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        let columnsWatchlists = [
            { field: "check", selectionMode: 'multiple', style: { width: '4em', fontWeight: 'normal' } },
            { field: "name", header: "Name", sortable: true, style: {}, body: this.nameTemplate },
            {
                field: "numberQueries",
                header: "Searches",
                sortable: true,
                style: { fontWeight: 'normal', textAlign: 'left' },
                body: this.numberQueriesTemplate
            },
            { field: "", header: "Sharing", body: this.sharedTemplate, sortable: true, style: { textAlign: 'left' } }
        ]
        this.state = {
            displayCustomToast: false,
            colsWatchlists: columnsWatchlists,
            sortWatchlistsBy: 'created',
            watchlistName: 'All saved searches',
            watchlistDescription: 'All saved searches that can be part of your search collections',
            displayDialogAddWatchlist: false,
            displayDialogRemoveWatchlist: false,
            displayDialogModifyWatchlist: false,
            displayDialogCopyQuery: false,
            displayDialogMoveQuery: false,
            displayDialogRemoveQueries: false,
            displayDialogDeleteQueries: false,
            displayDialogEditQuery: false,
            newWatchlistName: '',
            newWatchlistDescription: '',
            canEdit: true,
            selectedWatchlistsAction: null,
            startTime: null,
            filteredQuery: null,
            pageTutorials: [],
            section: '',
            sectionLabel: '',
            queryIntervals: [],
            displayExportWatchlists: false,
            displayImportWatchlists: false,
            globalFilterWatchlists: '',
            fetching: true,
            firstQueries: 0,
            rowsQueries: 10,
            displayAddEditAlert: false,
            selectedCollectionAddEdit: undefined,
            selectedAlertType: 'watchlist',
            displayConfirmationDialog: false,
            foreignQuery: false,
            foreignQueryCount: 0,
            allPossibleUsers: [],
            allPossibleSuborgs: [],
            allNotAllowedUsersRead: [],
            allNotAllowedUsersWrite: [],
            allNotAllowedSuborgs: [],
            allWatchlists: [],
            userNames: [],
            newOrgArray: [],
            userValue: '',
            userItems: [],
            allUsers: [],
            newOrgKeys: []
        }
        this.op = createRef();
    }

    componentDidMount = async () => {

        if (APP_PROPERTIES.APP_ID === 'dimensionss') {
            this.setState({ pageTutorials: videos?.filter(vid => vid.section?.includes('searches')) });
        }

        var self = this;

        /*axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications/email-check`, { headers: getHeaderToken() })
            .then(function (response) {
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            }) */

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications/intervals`, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                let queryIntervals = []
                response.data.forEach(entry => {
                    queryIntervals = [...queryIntervals, { label: entry, value: entry }]
                })
                self.setState({ queryIntervals: queryIntervals })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/page/0/count/100000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    selectedQueries: response.data.content,
                    allQueries: response.data.content,
                    fetching: false
                }, () => {
                    for (let i = 0; i < 1; i++) {
                        axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/page/0/count/100000/list`, {}, { headers: getHeaderToken() })
                            .then(function (response) {
                                let allWatchlists = response.data.content.filter(function (collection) {
                                    return collection.name !== 'History' && collection.name !== 'My Favorites'// && !collection.hasOwnProperty('sharedComment')
                                })
                                self.setState({
                                    allWatchlists: allWatchlists,
                                    selectedWatchlist: undefined
                                })
                                const urlParams = new URLSearchParams(window.location.search)
                                //console.log(urlParams)
                                //let watchlistId = parseInt(urlParams.get('watchlistId'))
                                let watchlistId = urlParams.get('searchcol')
                                //console.log(watchlistId)
                                let queryId = urlParams.get('query')
                                let date = urlParams.get('date')
                                //console.log(watchlistId)
                                let filteredWatchlist = allWatchlists.find(obj =>
                                    obj.id.toString() === watchlistId)
                                let filteredQuery = self.state.allQueries.find(obj =>
                                    obj.id.toString() === queryId)
                                //console.log(watchlistId)
                                if (filteredWatchlist === undefined && watchlistId !== null && watchlistId !== 'na') {
                                    //console.log("watch")
                                    let msg = { severity: 'error', summary: 'Error!', detail: 'Search collection not found. Maybe the collection was deleted after you received the alert for it.', life: 6000 };
                                    self.growl.show(msg);
                                }
                                if (filteredQuery === undefined && queryId !== null) {
                                    //console.log("watch")
                                    let msg = { severity: 'error', summary: 'Error!', detail: 'Search not found. Maybe the search was deleted after you received the alert for it.', life: 6000 };
                                    self.growl.show(msg);
                                }
                                if (filteredWatchlist !== undefined && watchlistId !== 'na') {
                                    self.setState({
                                        selectedWatchlist: filteredWatchlist,
                                        filteredQuery: queryId,
                                        //startTime: date
                                    }, () => self.handleWatchlistChange(filteredWatchlist, date, filteredQuery !== undefined ? true : false)
                                    )
                                } if (watchlistId !== undefined && watchlistId === 'na' && filteredQuery !== undefined) {
                                    self.setState({
                                        filteredQuery: queryId,
                                        startTime: date
                                    })
                                }
                                window.history.replaceState(null, null, window.location.pathname)
                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showFailGrowl(error.response.data)
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                    }
                })
            }).catch(function (error) {
                if (error.response.status === 400) {
                    self.setState({
                        fetching: false
                    }, () => {
                        self.showFailGrowl(error.response.data)
                    })
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        fetching: false
                    }, () => {
                        console.log("not found")
                    })
                }
            })

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/users`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    departmentMembers: response.data
                }, () => {
                    let userArray = createUserArray(response.data, self.props.userData, 'user')
                    let orgArray = createUserArray(response.data, self.props.userData, 'org')
                    let allPossibleUsers = []
                    userArray && userArray.forEach(org => {
                        org.children.forEach(dep => {
                            dep.children.forEach(user => {
                                allPossibleUsers = [...allPossibleUsers, user.id]
                            })
                        })
                    })
                    let allPossibleSuborgs = []
                    orgArray && orgArray.forEach(org => {
                        org.children.forEach(dep => {
                            allPossibleSuborgs = [...allPossibleSuborgs, dep.id]
                        })
                    })
                    let newOrgArray = []
                    orgArray?.forEach(org => {
                        org.children?.forEach(dep => {
                            newOrgArray = [...newOrgArray, { label: `${org.label} - ${dep.label}`, value: dep.id }]
                        })
                    })
                    let userList = []
                    userArray?.forEach(comp => {
                        comp.children?.forEach(dep => {
                            dep.children.forEach(user => {
                                userList = [...userList, { label: `${user.label} [${comp.label} - ${dep.label}]`, value: user.id }]
                            })
                        })
                    })
                    self.setState({
                        userArray: userArray,
                        orgArray: orgArray,
                        allPossibleUsers: allPossibleUsers,
                        allPossibleSuborgs: allPossibleSuborgs,
                        allUsers: userList,
                        newOrgArray: newOrgArray
                    })
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userData.userDetails !== prevProps.userData.userDetails) {
            var self = this
            //axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/department/user/list`, { headers: getHeaderToken() })
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/users`, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        departmentMembers: response.data
                    }, () => {
                        let userArray = createUserArray(response.data, self.props.userData, 'user')
                        let orgArray = createUserArray(response.data, self.props.userData, 'org')
                        let allPossibleUsers = []
                        userArray && userArray.forEach(org => {
                            org.children.forEach(dep => {
                                dep.children.forEach(user => {
                                    allPossibleUsers = [...allPossibleUsers, user.id]
                                })
                            })
                        })
                        let allPossibleSuborgs = []
                        orgArray && orgArray.forEach(org => {
                            org.children.forEach(dep => {
                                allPossibleSuborgs = [...allPossibleSuborgs, dep.id]
                            })
                        })
                        let newOrgArray = []
                        orgArray?.forEach(org => {
                            org.children?.forEach(dep => {
                                newOrgArray = [...newOrgArray, { label: `${org.label} - ${dep.label}`, value: dep.id }]
                            })
                        })
                        let userList = []
                        userArray?.forEach(comp => {
                            comp.children?.forEach(dep => {
                                dep.children.forEach(user => {
                                    userList = [...userList, { label: `${user.label} [${comp.label} - ${dep.label}]`, value: user.id }]
                                })
                            })
                        })
                        self.setState({
                            userArray: userArray,
                            orgArray: orgArray,
                            allPossibleUsers: allPossibleUsers,
                            allPossibleSuborgs: allPossibleSuborgs,
                            allUsers: userList,
                            newOrgArray: newOrgArray,
                        })
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    searchUser = (event) => {
        setTimeout(() => {
            let _filteredUsers;

            if (!event.query.trim().length) {
                _filteredUsers = [...this.state.allUsers];
            }
            else {
                _filteredUsers = this.state.allUsers.filter((user) => {
                    return user.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({
                userItems: _filteredUsers
            })
        }, 150);
    }

    setUserValue = (e) => {
        this.setState({
            userValue: e.value
        })
    }

    onSelectUser = (user) => {
        if (!this.state.userNames.some(userName => userName.value === user.value)) {
            let newUserNames = [user, ...this.state.userNames]
            let newUserShared = [user.value, ...this.state.usersShared]
            this.setState({
                userNames: newUserNames,
                usersShared: newUserShared
            })
        }
        this.setState({
            userValue: ''
        })
    }

    onUserRemoveClick = (user) => {
        let newUserNames = this.state.userNames.filter(userName => userName.value !== user.value)
        let newUserShared = this.state.usersShared.filter(userShared => userShared !== user.value)
        let newUsersWrite = this.state.usersWrite.filter(userWrite => userWrite !== user.value)
        this.setState({
            userNames: newUserNames,
            usersShared: newUserShared,
            usersWrite: newUsersWrite
        })
    }

    onNewOrgArrayChange = (e) => {
        this.setState({
            newOrgKeys: e.value
        })
    }

    setUserWrite = (e, user) => {
        let newUsersWrite = [...this.state.usersWrite]
        if (e.value) {
            newUsersWrite = [...newUsersWrite, user.value]
        } else {
            newUsersWrite.splice(newUsersWrite.indexOf(user.value), 1)
        }
        this.setState({
            usersWrite: newUsersWrite
        })
    }

    onAddWatchlist = () => {
        this.setState({
            displayDialogAddWatchlist: true,
            newWatchlistName: '',
            newWatchlistDescription: '',
            sharedForDepartment: false,
            usersShared: [],
            usersWrite: [],
            orgShared: [],
            checkedKeys: {},
            checkedOrgKeys: {},
            editableForSharedUsers: false,
            userItems: [],
            userNames: [],
            userValue: '',
            newOrgKeys: []
        })
    }

    handleEditable = (e) => {
        this.setState({
            editableForSharedUsers: e.value
        })
    }

    updateDescription = (desc) => {
        this.setState({
            newWatchlistDescription: desc
        })
    }

    updateName = (name) => {
        this.setState({
            newWatchlistName: name
        })
    }

    handleShareWithDepartment = (e) => {
        this.setState({
            sharedForDepartment: e.value
        })
    }

    handleMemberChange = async (members, isSubOrg) => {
        let users = [], orgs = [], sharedUsers = [], usersWrite = []
        let orgKeys = []
        this.state.orgArray && this.state.orgArray.forEach(org => {
            orgKeys = [...orgKeys, org.key]
        })
        Object.keys(members).forEach(user => {
            if (this.state.departmentMembers?.some(mem => mem.id === Number(user))) {
                let sharedUser = this.state.departmentMembers?.filter(u => {
                    return u.id === Number(user)
                })
                users = [...users, Number(user)]
                sharedUsers = [...sharedUsers, { label: `${sharedUser[0].lastName}, ${sharedUser[0].forename} (${sharedUser[0].username})`, value: sharedUser[0].id }]
            } else {
                if (!orgKeys.includes(user)) {
                    orgs.push(user)
                }
            }
        })

        let checkedKeysWithCompaniesAndDepartments = await createCheckedKeys(members, this.state?.departmentMembers, this.state.userArray)
        let orgChecked = {}
        let userChecked = {}
        Object.entries(checkedKeysWithCompaniesAndDepartments).forEach(item => {
            if (orgs.includes(item[0])) {
                orgChecked[item[0]] = { partialChecked: item[1]['partialChecked'], checked: item[1]['partialChecked'] ? false : true }
            } else {
                userChecked[item[0]] = item[1]
            }
        })

        if (!isSubOrg) {
            userChecked = Object.fromEntries(Object.entries(userChecked).filter(([key]) => !key.includes("-")))
            if (this.state.usersWrite?.length > 0) {
                usersWrite = this.state.usersWrite
                usersWrite.forEach(user => {
                    if (!users.includes(user)) {
                        let index = usersWrite.indexOf(user)
                        usersWrite.splice(index, 1)
                    }
                })
            }
            this.setState({
                usersWrite: usersWrite,
                usersShared: users,
                sharedUsers: sharedUsers,
                checkedKeys: userChecked
            })
        } else {
            let allOrgs = []
            this.state.userArray?.forEach(org => {
                allOrgs = [...allOrgs, org.key]
            })
            let onlySubOrgs = []
            orgs.forEach(org => {
                if (!allOrgs.includes(org)) {
                    onlySubOrgs = [...onlySubOrgs, org.split("-")[0]]
                }
            })
            this.setState({
                orgShared: onlySubOrgs,
                checkedOrgKeys: orgChecked
            })
        }
    }

    onWriteMembersChange = (members) => {
        //console.log(members)
        this.setState({
            usersWrite: members
        })
    }
    onSaveWatchlist = () => {
        let usersRead = []
        if (this.state.usersShared?.length > 0) {
            if (this.state.usersWrite?.length > 0) {
                usersRead = this.state.usersShared.filter(user => !this.state.usersWrite.includes(user))
            } else {
                usersRead = this.state.usersShared
            }
        }
        this.saveWatchlist(this.state.newWatchlistName, this.state.newWatchlistDescription, this.state.sharedForDepartment, usersRead, this.state.usersWrite, this.state.orgShared, this.state.editableForSharedUsers)
    }

    saveWatchlist = async (name, description, sharedForDepartment, usersRead, usersWrite, orgShared, editable) => {
        let departments = []
        this.state.newOrgKeys.forEach(dep => {
            departments = [...departments, Number(dep)]
        })
        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection`, {
            name: name,
            description: description,
            departmentSharesRead: departments,
            //sharedForDepartment: sharedForDepartment,
            //userSharesRead: editable ? [] : usersShared,
            //userSharesWrite: editable ? usersShared : [],
            userSharesRead: usersRead,
            userSharesWrite: usersWrite
        }, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showCreateWatchlistSuccessGrowl()
                self.setState({
                    displayDialogAddWatchlist: false
                }, () => self.refreshWatchlists())
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showCreateWatchlistFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        displayDialogAddWatchlist: false
                    })
                    console.log("not found")
                }
            })
    }

    refreshWatchlists = async (watchlistID) => {
        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/page/0/count/100000/list`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                let watchlists = response.data.content.filter(function (collection) {
                    return collection.name !== 'History' && collection.name !== 'My Favorites' //&& !collection.hasOwnProperty('sharedComment')
                })
                let allWatchlistsOrdered
                if (watchlists && watchlists.length > 0 && self.state.sortWatchlistsBy === "numberQueries") {
                    allWatchlistsOrdered = watchlists.sort(function (a, b) { return b[self.state.sortWatchlistsBy] - a[self.state.sortWatchlistsBy] })
                } else if (watchlists && watchlists.length > 0 && self.state.sortWatchlistsBy === "name") {
                    allWatchlistsOrdered = watchlists.sort((a, b) => a.name.localeCompare(b.name))
                } else if (watchlists && watchlists.length > 0 && self.state.sortWatchlistsBy === "created" || self.state.sortWatchlistsBy === "modified") {
                    allWatchlistsOrdered = watchlists.sort(function (a, b) { return new Date(b[self.state.sortWatchlistsBy]) - new Date(a[self.state.sortWatchlistsBy]) })
                }
                self.setState({
                    allWatchlists: allWatchlistsOrdered,
                    displayDialogAddWatchlist: false,
                    //displayDialogCopyQuery: false,
                    displayDialogModifyWatchlist: false,
                    displayDialogRemoveQueries: false,
                    displayDialogDeleteQueries: false,
                    displayDialogRemoveWatchlist: false,
                    //displayDialogMoveQuery: false,
                    displayDialogEditQuery: false
                }, () => {
                    if (watchlistID !== undefined) {
                        self.state.allWatchlists.forEach(list => {
                            if (list.id === watchlistID && self.state.selectedWatchlist && list.id === self.state.selectedWatchlist.id) {
                                let canEdit
                                if (list.shared && list.writable === true) {
                                    canEdit = true
                                } else if (list.shared && list.writable === false) {
                                    canEdit = false
                                } else {
                                    canEdit = true
                                }
                                let showOnDashboard
                                if (list.tags && list.tags.some(e => e.name === 'dashboard')) {
                                    showOnDashboard = true
                                } else {
                                    showOnDashboard = false
                                }
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${list.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        self.setState({
                                            selectedQueries: response.data.content,
                                            watchlistID: list.id,
                                            selectedWatchlistID: list.id,
                                            watchlistName: list.name,
                                            watchlistNameSelected: list.name,
                                            watchlistDescription: list.description,
                                            selectedWatchlist: list,
                                            canEdit: canEdit,
                                            //selectedQueriesAction: [],
                                            selectedWatchlistsAction: null,
                                            showOnDashboard: showOnDashboard
                                        }, () => {
                                            if (Array.isArray(self.state.selectedQueriesAction)) {
                                                let selectedQueries = []
                                                self.state.selectedQueriesAction.forEach(selQu => {
                                                    self.state.selectedQueries.forEach(qu => {
                                                        if (selQu.id === qu.id) {
                                                            selectedQueries = [...selectedQueries, qu]
                                                        }
                                                    })
                                                })
                                                //console.log("sel:", selectedQueries)
                                                self.setState({ selectedQueriesAction: selectedQueries })
                                            }
                                        })
                                    })
                                    .catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showFailGrowl(error.response.data)
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }
                        })
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showWatchlistsRefreshFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    refreshAllQueries = () => {
        var self = this
        self.setState({
            fetching: true
        })
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/page/0/count/100000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showQueryRefreshSuccessGrowl()
                self.setState({
                    allQueries: response.data.content,
                    fetching: false
                })
                if (self.state.selectedWatchlist === undefined) {
                    self.setState({
                        selectedQueries: response.data.content
                    }, () => {
                        if (Array.isArray(self.state.selectedQueriesAction)) {
                            let selectedQueries = []
                            self.state.selectedQueriesAction.forEach(selQu => {
                                self.state.selectedQueries.forEach(qu => {
                                    if (selQu.id === qu.id) {
                                        selectedQueries = [...selectedQueries, qu]
                                    }
                                })
                            })
                            //console.log("sel:", selectedAlerts)
                            self.setState({ selectedQueriesAction: selectedQueries })
                        }
                    })
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.setState({
                        fetching: false
                    }, () => {
                        self.showQueryRefreshFailGrowl()
                    })
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        fetching: false
                    }, () => {
                        console.log("not found")
                    })
                }
            })
    }

    showWatchlistsRefreshFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection list not updated.', life: 6000 };
        this.growl.show(msg);
    }

    showCreateWatchlistSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Collection successfully added to database.' };
        this.growl.show(msg);
    }

    showCreateWatchlistFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showQueryMoveFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showCopyQueryFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    showNameLengthWarnGrowl = (name) => {
        let msg = { severity: 'warn', summary: 'Warning!', detail: `Search collection name "${name}" is too long. Please limit the name to 50 characters within the input field.`, life: 10000 };
        this.growl.show(msg);
    }

    hideCreateWatchlist = () => {
        this.setState({
            displayDialogAddWatchlist: false
        })
    }

    onSortWatchlists = (value) => {
        //console.log(value)
        //console.log(this.state.allWatchlists)
        let sortBy = value
        let allWatchlists = JSON.parse(JSON.stringify(this.state.allWatchlists))
        let allWatchlistsOrdered
        if (this.state.allWatchlists && this.state.allWatchlists.length > 0 && sortBy === "numberQueries") {
            allWatchlistsOrdered = allWatchlists.sort(function (a, b) { return b[sortBy] - a[sortBy] })
        } else if (this.state.allWatchlists && this.state.allWatchlists.length > 0 && sortBy === "name") {
            allWatchlistsOrdered = allWatchlists.sort((a, b) => a.name.localeCompare(b.name))
        } else if (this.state.allWatchlists && this.state.allWatchlists.length > 0 && sortBy === "created" || sortBy === "modified") {
            allWatchlistsOrdered = allWatchlists.sort(function (a, b) { return new Date(b[sortBy]) - new Date(a[sortBy]) })
        }
        this.setState({
            sortWatchlistsBy: value,
            allWatchlists: allWatchlistsOrdered
        })
    }

    openAddAlertDialog = (col, type) => {
        //console.log(col)
        this.setState({
            displayAddEditAlert: true,
            selectedCollectionAddEdit: col,
            selectedAlertType: type
        })
    }

    closeAddEditAlert = () => {
        this.setState({
            displayAddEditAlert: false
        })
    }

    onHideConfirmationDialog = () => {
        this.setState({
            displayConfirmationDialog: false
        })
    }

    handleWatchlistChange = (list, date, auto) => {
        if (list === 'All saved searches') {
            this.toastBC.current.clear()
            this.setState({
                watchlistName: 'All saved searches',
                watchlistDescription: 'All saved searches that can be part of your search collections',
                selectedQueries: this.state.allQueries,
                watchlistID: undefined,
                selectedWatchlistID: undefined,
                selectedWatchlist: undefined,
                selectedQueriesAction: [],
                selectedWatchlistsAction: null,
                startTime: null,
                filteredQuery: auto ? this.state.filteredQuery : null,
                firstQueries: 0,
                displayCustomToast: false,
                allNotAllowedUsersRead: [],
                allNotAllowedUsersWrite: [],
                allNotAllowedSuborgs: [],
                fetching: false
            }, () => {
                this.refreshAllQueries()
                //this.refreshWatchlists()

            })
        } else {
            this.setState({
                fetching: true
            })
            this.toastBC.current.clear()
            let canEdit
            if (list.shared && list.writable === true) {
                canEdit = true
            } else if (list.shared && list.writable === false) {
                canEdit = false
            } else {
                canEdit = true
            }
            let showOnDashboard
            if (list.tags && list.tags.some(e => e.name === 'dashboard')) {
                showOnDashboard = true
            } else {
                showOnDashboard = false
            }
            var self = this;
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${list.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                .then(function (response) {
                    let allNotAllowedUsersRead = []
                    let allNotAllowedUsersWrite = []
                    let allNotAllowedSuborgs = []
                    if (list.userSharesRead && list.userSharesRead.length > 0 && self.state.allPossibleUsers && self.state.allPossibleUsers.length > 0) {
                        list.userSharesRead.forEach(user => {
                            if (!self.state.allPossibleUsers.includes(user.id)) {
                                allNotAllowedUsersRead = [...allNotAllowedUsersRead, user.id]
                            }
                        })
                    }
                    if (list.userSharesWrite && list.userSharesWrite.length > 0 && self.state.allPossibleUsers && self.state.allPossibleUsers.length > 0) {
                        list.userSharesWrite.forEach(user => {
                            if (!self.state.allPossibleUsers.includes(user.id)) {
                                allNotAllowedUsersWrite = [...allNotAllowedUsersWrite, user.id]
                            }
                        })
                    }
                    if (list.departmentSharesRead && list.departmentSharesRead.length > 0 && self.state.allPossibleSuborgs && self.state.allPossibleSuborgs.length > 0) {
                        list.departmentSharesRead.forEach(dep => {
                            if (!self.state.allPossibleSuborgs.includes(dep.id)) {
                                allNotAllowedSuborgs = [...allNotAllowedSuborgs, dep.id]
                            }
                        })
                    }
                    self.setState({
                        selectedQueries: response.data.content,
                        watchlistID: list.id,
                        selectedWatchlistID: list.id,
                        watchlistName: list.name,
                        watchlistNameSelected: list.name,
                        watchlistDescription: list.description,
                        selectedWatchlist: list,
                        canEdit: canEdit,
                        selectedQueriesAction: [],
                        selectedWatchlistsAction: null,
                        showOnDashboard: showOnDashboard,
                        startTime: date !== undefined ? date : null,
                        filteredQuery: auto ? self.state.filteredQuery : null,
                        firstQueries: 0,
                        displayCustomToast: false,
                        allNotAllowedUsersRead: allNotAllowedUsersRead,
                        allNotAllowedUsersWrite: allNotAllowedUsersWrite,
                        allNotAllowedSuborgs: allNotAllowedSuborgs,
                        fetching: false
                    }, () => {
                        //self.refreshAllQueries()
                        //self.refreshWatchlists()
                    })
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.setState({
                            fetching: false
                        }, () => {
                            self.showFailGrowl(error.response.data)
                        })
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.setState({
                            fetching: false
                        }, () => {
                            console.log("not found")
                        })
                    }
                })
        }
    }

    onDeleteAlert = () => {
        this.setState({
            displayConfirmationDialog: true
        })
    }

    callbackFromSave = async (collection, activeOption, interval, startTime) => {
        var self = this;
        if (this.state.selectedAlertType === 'watchlist' && activeOption === 'active' && ((collection.notifications && collection.notifications.length === 0) ||
            collection.notifications === undefined)) {
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications`, {
                active: true,
                queryCollectionId: collection.id,
                interval: interval,
                nextSendingDate: moment(startTime).format("YYYY-MM-DD")
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        displayAddEditAlert: false
                    })
                    self.refreshQueryCollections(collection.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (this.state.selectedAlertType === 'watchlist' && (activeOption === 'active' || activeOption === 'paused') && (collection.notifications && collection.notifications.length !== 0)) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${collection.notifications[0].id}`, {
                active: activeOption === 'active' ? true : false,
                queryCollectionId: collection.id,
                interval: interval,
                nextSendingDate: moment(startTime).format("YYYY-MM-DD")
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.setState({
                        displayAddEditAlert: false
                    })
                    self.refreshQueryCollections(collection.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (this.state.selectedAlertType === 'watchlist' && activeOption === 'remove' && (collection.notifications && collection.notifications.length !== 0)) {
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${collection.notifications[0].id}`,
                { headers: getHeaderToken(), data: collection.notifications[0].id })
                .then(function (response) {
                    if (response.status === 200) {
                        self.setState({
                            displayAddEditAlert: false,
                            displayConfirmationDialog: false
                        })
                        self.refreshQueryCollections(collection.id)
                    }
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (this.state.selectedAlertType === 'query' && activeOption === 'active' && ((collection.notifications && collection.notifications.length === 0) ||
            collection.notifications === undefined)) {
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications`, {
                active: true,
                queryId: collection.id,
                interval: interval,
                nextSendingDate: moment(startTime).format("YYYY-MM-DD")
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status <= 200) {
                        if (self.state.watchlistName !== 'All saved searches' && self.state.selectedWatchlist.id !== undefined) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    self.setState({
                                        displayAddEditAlert: false
                                    })
                                    self.refreshWatchlists(self.state.selectedWatchlist.id)
                                    //self.refreshAllQueries()
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showRefreshCollectionQueriesFailGrowl()
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        } else {
                            self.setState({
                                displayAddEditAlert: false
                            })
                            self.refreshWatchlists()
                            self.refreshAllQueries()
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (this.state.selectedAlertType === 'query' && (activeOption === 'active' || activeOption === 'paused') && (collection.notifications && collection.notifications.length !== 0)) {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${collection.notifications[0].id}`, {
                active: activeOption === 'active' ? true : false,
                queryId: collection.id,
                interval: interval,
                nextSendingDate: moment(startTime).format("YYYY-MM-DD")
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status <= 200) {
                        if (self.state.watchlistName !== 'All saved searches' && self.state.selectedWatchlist.id !== undefined) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    self.setState({
                                        displayAddEditAlert: false
                                    })
                                    self.refreshWatchlists(self.state.selectedWatchlist.id)
                                    // self.refreshAllQueries()
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showRefreshCollectionQueriesFailGrowl()
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        } else {
                            self.setState({
                                displayAddEditAlert: false
                            })
                            self.refreshWatchlists()
                            self.refreshAllQueries()
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (this.state.selectedAlertType === 'query' && activeOption === 'remove' && (collection.notifications && collection.notifications.length !== 0)) {
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${collection.notifications[0].id}`,
                { headers: getHeaderToken(), data: collection.notifications[0].id })
                .then(function (response) {
                    if (response.status <= 200) {
                        if (self.state.watchlistName !== 'All saved searches' && self.state.selectedWatchlist.id !== undefined) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    self.setState({
                                        displayAddEditAlert: false,
                                        displayConfirmationDialog: false
                                    })
                                    self.refreshWatchlists(self.state.selectedWatchlist.id)
                                    //self.refreshAllQueries()
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showRefreshCollectionQueriesFailGrowl()
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        } else {
                            self.setState({
                                displayAddEditAlert: false,
                                displayConfirmationDialog: false
                            })
                            self.refreshWatchlists()
                            self.refreshAllQueries()
                        }
                    }
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    watchlistAlertChange = async (list, status, e) => {
        let today = new Date()
        let week = new Date(today)
        let tomorrow = new Date(today)
        let month = new Date(today)
        week.setDate(tomorrow.getDate() + 7)
        tomorrow.setDate(tomorrow.getDate() + 1)
        month.setMonth(month.getMonth() + 1)
        //console.log( moment(tomorrow).format("YYYY-MM-DD"))
        //let newDate = new Date(tomorrow).toLocaleDateString('fr-CA')
        let newDate = moment(week).format("YYYY-MM-DD")
        let newDateTomorrow = moment(tomorrow).format("YYYY-MM-DD")
        let newDateMonth = moment(month).format("YYYY-MM-DD")

        let self = this;
        if (status === 'add') {
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications`, {
                active: true,
                queryCollectionId: list.id,
                interval: 'WEEKLY',
                nextSendingDate: newDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshQueryCollections(list.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (status === 'pause') {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${list.notifications[0].id}`, {
                active: false,
                queryCollectionId: list.id,
                interval: list.notifications[0].interval,
                nextSendingDate: list.notifications[0].nextSendingDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshQueryCollections(list.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${list.notifications[0].id}`, {
                active: true,
                queryCollectionId: list.id,
                interval: list.notifications[0].interval,
                nextSendingDate: list.notifications[0].interval === 'WEEKLY' ? newDate : list.notifications[0].interval === 'DAILY' ? newDateTomorrow : newDateMonth//list.notifications[0].nextSendingDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshQueryCollections(list.id)
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleDashboardChange = async (rowData, status) => {
        if (hasUserRole("ROLE_DASHBOARD")) {
            let self = this
            if (status === false) {
                await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${rowData.id}/tag?queryCollectionTag=dashboard`, { headers: getHeaderToken() })
                    .then(function (response) {
                        //console.log(response)
                        self.refreshWatchlists(self.state.selectedWatchlist.id)
                        self.refreshAllQueries()
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            } else {
                await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${rowData.id}/tag?queryCollectionTag=dashboard`, {
                }, { headers: getHeaderToken() })
                    .then(function (response) {
                        //console.log(response)
                        self.refreshWatchlists(self.state.selectedWatchlist.id)
                        self.refreshAllQueries()
                    })
                    .catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            console.log("not found")
                        }
                    })
            }
        }
    }

    watchlistAlertChangeQuery = async (rowData, status, e) => {
        let today = new Date()
        let week = new Date(today)
        let tomorrow = new Date(today)
        let month = new Date(today)
        week.setDate(tomorrow.getDate() + 7)
        tomorrow.setDate(tomorrow.getDate() + 1)
        month.setMonth(month.getMonth() + 1)
        //console.log( moment(tomorrow).format("YYYY-MM-DD"))
        //let newDate = new Date(tomorrow).toLocaleDateString('fr-CA')
        let newDate = moment(week).format("YYYY-MM-DD")
        let newDateTomorrow = moment(tomorrow).format("YYYY-MM-DD")
        let newDateMonth = moment(month).format("YYYY-MM-DD")

        let self = this;
        if (status === 'add') {
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications`, {
                active: true,
                queryId: rowData.id,
                interval: 'WEEKLY',
                nextSendingDate: newDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status <= 200) {
                        if (self.state.watchlistName !== 'All saved searches' && self.state.selectedWatchlist.id !== undefined) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    //self.setState({ collectionQueries: response.data.content })
                                    self.refreshWatchlists(self.state.selectedWatchlist.id)
                                    self.refreshAllQueries()
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showRefreshCollectionQueriesFailGrowl()
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        } else {
                            self.refreshWatchlists()
                            self.refreshAllQueries()
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else if (status === 'pause') {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.notifications[0].id}`, {
                active: false,
                queryId: rowData.id,
                interval: rowData.notifications[0].interval,
                nextSendingDate: rowData.notifications[0].nextSendingDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status <= 200) {
                        if (self.state.watchlistName !== 'All saved searches' && self.state.selectedWatchlist.id !== undefined) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    self.refreshWatchlists(self.state.selectedWatchlist.id)
                                    self.refreshAllQueries()
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showRefreshCollectionQueriesFailGrowl()
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        } else {
                            self.refreshWatchlists()
                            self.refreshAllQueries()
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        } else {
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${rowData.notifications[0].id}`, {
                active: true,
                queryId: rowData.id,
                interval: rowData.notifications[0].interval,
                nextSendingDate: rowData.notifications[0].interval === 'WEEKLY' ? newDate : rowData.notifications[0].interval === 'DAILY' ? newDateTomorrow : newDateMonth //rowData.notifications[0].nextSendingDate
            }, { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status <= 200) {
                        if (self.state.watchlistName !== 'All saved searches' && self.state.selectedWatchlist.id !== undefined) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    self.refreshWatchlists(self.state.selectedWatchlist.id)
                                    self.refreshAllQueries()
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showRefreshCollectionQueriesFailGrowl()
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        } else {
                            self.refreshWatchlists()
                            self.refreshAllQueries()
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    refreshQueryCollections = async (listID) => {
        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/page/0/count/100000/list`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showCollectionRefreshSuccessGrowl()
                let queryCollections = response.data.content.filter(function (collection) {
                    return collection.name !== 'History' && collection.name !== 'My Favorites' //&& !collection.hasOwnProperty('sharedComment')
                })
                self.setState({
                    allWatchlists: queryCollections
                }, () => {
                    if (listID !== undefined) {
                        self.state.allWatchlists.forEach(list => {
                            if (list.id === listID && self.state.selectedWatchlist && list.id === self.state.selectedWatchlist.id) {
                                let canEdit
                                if (list.shared && list.writable === true) {
                                    canEdit = true
                                } else if (list.shared && list.writable === false) {
                                    canEdit = false
                                } else {
                                    canEdit = true
                                }
                                axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                    .then(function (response) {
                                        self.setState({
                                            selectedQueries: response.data.content,
                                            watchlistID: list.id,
                                            selectedWatchlistID: list.id,
                                            watchlistName: list.name,
                                            watchlistNameSelected: list.name,
                                            watchlistDescription: list.description,
                                            selectedWatchlist: list,
                                            canEdit: canEdit,
                                            //selectedQueriesAction: [],
                                            selectedWatchlistsAction: null
                                        }, () => {
                                            if (Array.isArray(self.state.selectedQueriesAction)) {
                                                let selectedQueries = []
                                                self.state.selectedQueriesAction.forEach(selQu => {
                                                    self.state.selectedQueries.forEach(qu => {
                                                        if (selQu.id === qu.id) {
                                                            selectedQueries = [...selectedQueries, qu]
                                                        }
                                                    })
                                                })
                                                self.setState({ selectedQueriesAction: selectedQueries })
                                            }
                                        })
                                    })
                                    .catch(function (error) {
                                        if (error.response.status === 400) {
                                            self.showRefreshWatchlistQueriesFailGrowl()
                                        } else if (error.response.status === 401) {
                                            self.props.history.push('/')
                                        } else if (error.response.status === 404) {
                                            console.log("not found")
                                        }
                                    })
                            }
                        })
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showWatchlistsRefreshFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showWatchlistsRefreshFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collections not updated.', life: 6000 };
        this.growl.show(msg);
    }

    showRefreshWatchlistQueriesFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection searches could not be updated.', life: 6000 };
        this.growl.show(msg);
    }

    onRemoveWatchlist = () => {
        this.setState({
            displayDialogRemoveWatchlist: true
        })
    }

    onHideRemoveWatchlist = () => {
        this.setState({
            displayDialogRemoveWatchlist: false
        })
    }

    deleteWatchlist = async (e) => {
        let id = this.state.selectedWatchlist.id
        var self = this;
        await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection?queryCollectionIds=${id}`, { headers: getHeaderToken(), data: { id } })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showWatchlistDeleteSuccessGrowl()
                    self.refreshWatchlists()
                    self.toastBC.current.clear()
                    self.setState({
                        watchlistName: 'All saved searches',
                        watchlistDescription: 'All saved searches that can be part of your search collections',
                        selectedQueries: self.state.allQueries,
                        watchlistID: undefined,
                        selectedWatchlistID: undefined,
                        selectedWatchlist: undefined,
                        selectedQueriesAction: [],
                        selectedWatchlistsAction: null,
                        displayDialogRemoveWatchlist: false,
                        displayCustomToast: false
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showWatchlistDeleteFailGrowl()
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showWatchlistDeleteSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Collection deleted from database.' };
        this.growl.show(msg);
    }

    showWatchlistDeleteFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection could not be deleted from database.', life: 6000 };
        this.growl.show(msg);
    }

    handleIntervalChange = async (list, value) => {
        //console.log(list)
        let self = this
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/query-store/v1/query-collection-notifications?ids=${list.notifications[0].id}`, {
            active: list.notifications[0].active,
            queryCollectionId: list.id,
            interval: value,
            nextSendingDate: list.notifications[0].nextSendingDate
        }, { headers: getHeaderToken() })
            .then(function (response) {
                //console.log(response)
                self.refreshWatchlists(list.id)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    selectQueries = (queries) => {
        //console.log(queries)
        //console.log(this.state.selectedWatchlist === undefined)
        let foreignQuery = false
        let foreignQueryCount = 0
        if (queries.length > 0) {
            queries.forEach(q => {
                /*if (q.queryCollectionList && q.queryCollectionList.length > 0) {
                    q.queryCollectionList.every(list => {
                        if (list.shared && !list.writable) {
                            foreignQuery = true
                            foreignQueryCount++
                            return false
                        }
                        return true
                    })
                }*/
                if (!q.isCurrentUserOwner) {
                    foreignQuery = true
                    foreignQueryCount++
                }
            })
            //console.log(foreignQuery)
            //console.log(foreignQueryCount)
        }
        if (queries.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (queries.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
        this.setState({
            selectedQueriesAction: queries,
            foreignQuery: foreignQuery,
            foreignQueryCount: foreignQueryCount
        })
    }

    selectWatchlists = (watchlists) => {
        this.setState({
            selectedWatchlistsAction: watchlists
        })
    }

    handleUnselect = () => {
        this.toastBC.current.clear()
        this.setState({
            selectedQueriesAction: [],
            selectedWatchlistsAction: null,
            displayCustomToast: false
        })
    }

    handleRemoveQueriesDialog = () => {
        this.setState({
            displayDialogRemoveQueries: true
        })
    }

    handleDeleteQueriesDialog = () => {
        this.setState({
            displayDialogDeleteQueries: true
        })
    }

    handleCloseRemoveQueryDialog = () => {
        this.setState({
            displayDialogRemoveQueries: false
        })
    }

    handleCloseDeleteQueryDialog = () => {
        this.setState({
            displayDialogDeleteQueries: false
        })
    }

    removeQueries = async (ids, deleteQueries) => {
        if (deleteQueries) {
            //console.log("delete")
            //console.log(ids)
            var self = this;
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query?ids=${ids}`, { headers: getHeaderToken(), data: { ids } })
                .then(function (response) {
                    if (response.status <= 200) {
                        self.toastBC.current.clear()

                        if (self.state.selectedWatchlist !== undefined) {
                            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                                .then(function (response) {
                                    //self.showRefreshCollectionQueriesSuccessGrowl()
                                    self.setState({ selectedQueries: response.data.content })
                                })
                                .catch(function (error) {
                                    if (error.response.status === 400) {
                                        self.showRefreshCollectionQueriesFailGrowl()
                                    } else if (error.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (error.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        }

                        self.refreshAllQueries()
                        self.refreshWatchlists()
                        self.setState({
                            displayDialogRemoveQueries: false,
                            displayDialogDeleteQueries: false,
                            selectedQueriesAction: [],
                            displayCustomToast: false
                        })
                    }
                }).catch(function (response) {
                    if (response.response.status === 400) {
                        self.showQueryRemoveFailGrowl(response.response.data)
                    } else if (response.response.status === 401) {
                        self.props.history.push('/')
                    } else if (response.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
        else {
            var self = this;
            await axios.delete(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/query?ids=${ids}`, { headers: getHeaderToken(), data: { ids } })
                .then(function (response) {
                    if (response.status <= 200) {
                        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                            .then(function (response) {
                                //self.showRefreshCollectionQueriesSuccessGrowl()
                                self.setState({ selectedQueries: response.data.content })
                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showRefreshCollectionQueriesFailGrowl()
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                        //self.showQueryRemoveSuccessGrowl()
                        self.toastBC.current.clear()
                        self.refreshWatchlists(self.state.selectedWatchlist.id)
                        self.refreshAllQueries()
                        self.setState({
                            displayDialogRemoveQueries: false,
                            displayDialogDeleteQueries: false,
                            selectedQueriesAction: [],
                            displayCustomToast: false
                        })
                    }
                }).catch(function (response) {
                    if (response.response.status === 400) {
                        self.showQueryRemoveFailGrowl(response.response.data)
                    } else if (response.response.status === 401) {
                        self.props.history.push('/')
                    } else if (response.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    handleCopyQueryDialog = () => {
        this.setState({
            displayDialogCopyQuery: true
        })
    }

    handleMoveQueryDialog = () => {
        this.setState({
            displayDialogMoveQuery: true
        })
    }

    handleCloseCopyQueryDialog = () => {
        this.setState({
            displayDialogCopyQuery: false,
            selectedWatchlistsAction: null
        })
    }

    handleCloseMoveQueryDialog = () => {
        this.setState({
            displayDialogMoveQuery: false,
            selectedWatchlistsAction: null
        })
    }

    copyQueries = async (ListIds, ids) => {
        var self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${ListIds}/query?ids=${ids} `, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    //self.showQueryMoveSuccessGrowl()
                    self.setState({
                        displayDialogCopyQuery: false,
                        //selectedQueriesAction: [],
                        selectedWatchlistsAction: null
                    }, () => {
                        self.refreshWatchlists()
                        if (self.state.selectedWatchlist !== undefined) {
                            self.refreshWatchlists(self.state.selectedWatchlist.id)
                        } else {
                            self.refreshAllQueries()
                        }
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showCopyQueryFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    moveQueries = async (ListIds, ids) => {
        //console.log(ListIds)
        //console.log(ids)
        //console.log(this.state.selectedWatchlist.id)
        var self = this;
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${ListIds}/query?ids=${ids}&collectionIdToRemoveFrom=${this.state.selectedWatchlist.id} `, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status === 200) {
                    self.toastBC.current.clear()
                    //self.showQueryMoveSuccessGrowl()
                    self.setState({
                        displayDialogMoveQuery: false,
                        //selectedQueriesAction: [],
                        selectedWatchlistsAction: null,
                        selectedQueriesAction: [],
                        displayCustomToast: false
                    }, () => {
                        self.refreshWatchlists()
                        self.refreshWatchlists()
                        if (self.state.selectedWatchlist !== undefined) {
                            self.refreshWatchlists(self.state.selectedWatchlist.id)
                        } else {
                            self.refreshAllQueries()
                        }
                    })
                }
            }).catch(function (response) {
                if (response.response.status === 400) {
                    self.showQueryMoveFailGrowl(response.response.data)
                } else if (response.response.status === 401) {
                    self.props.history.push('/')
                } else if (response.response.status === 404) {
                    console.log("not found")
                }
            })
    }


    onModifyWatchlist = () => {
        //console.log(this.state)
        this.setState({
            displayDialogModifyWatchlist: true
        })
    }

    onHideModifyWatchlist = () => {
        this.setState({
            displayDialogModifyWatchlist: false
        })
    }

    modifyWatchlist = async (canModifyWatchlist, selectedWatchlistName, selectedWatchlistDescription, oldSelectedMembers, usersRead, usersWrite, selectedDepartments, oldWatchlistSharedWithDepartment, watchlistSharedWithDepartment, oldWatchlistEditable, watchlistEditable) => {
        var self = this;
        //let users = []
        let departments = []
        /*Object.keys(selectedMembers).forEach(user => {
            if (this.state.departmentMembers.some(mem => mem.id === Number(user))) {
                users = [...users, Number(user)]
            }
        })*/
        selectedDepartments.forEach(dep => {
            departments = [...departments, Number(dep)]
        })
        //if (canModifyWatchlist) {
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}`, {
            name: selectedWatchlistName,
            description: selectedWatchlistDescription,
            //sharedForDepartment: watchlistSharedWithDepartment,
            //userSharesRead: watchlistEditable ? [] : users,
            //userSharesWrite: watchlistEditable ? users : [],
            departmentSharesRead: departments,
            userSharesRead: usersRead,
            userSharesWrite: usersWrite
            //shareWritable: watchlistEditable
        }, { headers: getHeaderToken() })
            .then(function (response) {
                if (response.status <= 200) {
                    self.refreshWatchlists(self.state.selectedWatchlist.id)
                    //self.refreshAllQueries()
                    self.setState({
                        displayDialogModifyWatchlist: false
                    })
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showModifyCollectionFailGrowl()
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
        //}
    }

    showModifyCollectionFailGrowl = () => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Collection could not be edited.', life: 6000 };
        this.growl.show(msg);
    }

    showQueryRemoveFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }
    openEditQuery = () => {
        this.setState({
            displayDialogEditQuery: true
        })
    }

    onHideEditQuery = () => {
        this.setState({
            displayDialogEditQuery: false
        })
    }

    onModifyQuery = async (queryIDEdit, queryNameEdit, queryFullNameEdit, queryDescriptionEdit, queryStringEdit, queryTypeEdit, api, queryFormEdit, queryFormContentEdit, tool, collectionsOld, selectedCollections, isShared, value, filterQueries, filterQueriesJsonString) => {
        var self = this;
        if (this.state.selectedWatchlist !== undefined) {
            await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${queryIDEdit}`,
                {
                    name: queryNameEdit,
                    fullName: queryFullNameEdit,
                    description: queryDescriptionEdit,
                    value: value,
                    type: queryTypeEdit,
                    form: queryFormEdit,
                    formContent: queryFormContentEdit,
                    filterQueries: filterQueries,
                    filterQueriesJsonString: filterQueriesJsonString
                },
                { headers: getHeaderToken() })
                .then(function (response) {
                    if (response.status <= 200) {
                        //self.showModifyQueryDetailsSuccessGrowl()
                        self.setState({
                            displayDialogEditQuery: false
                        })
                        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                            .then(function (response) {
                                if (collectionsOld !== selectedCollections) {
                                    self.changeWatchlists(queryIDEdit, selectedCollections)
                                }
                                //self.showRefreshCollectionQueriesSuccessGrowl()
                                self.setState({
                                    selectedQueries: response.data.content
                                },
                                    () => {
                                        if (Array.isArray(self.state.selectedQueriesAction)) {
                                            let selectedQueries = []
                                            self.state.selectedQueriesAction.forEach(selQu => {
                                                self.state.selectedQueries.forEach(qu => {
                                                    if (selQu.id === qu.id) {
                                                        selectedQueries = [...selectedQueries, qu]
                                                    }
                                                })
                                            })
                                            //console.log("sel:", selectedQueries)
                                            self.setState({ selectedQueriesAction: selectedQueries })
                                        }
                                    })
                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showFailGrowl(error.response.data)
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    console.log("not found")
                                }
                            })
                        //self.refreshAllQueries()
                    }
                }).catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        self.setState({
                            displayDialogEditQuery: false
                        })
                        console.log("not found")
                    }
                })
        } else {
            if (isShared === false) {
                await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${queryIDEdit}`,
                    {
                        name: queryNameEdit,
                        fullName: queryFullNameEdit,
                        description: queryDescriptionEdit,
                        value: value,
                        type: queryTypeEdit,
                        form: queryFormEdit,
                        formContent: queryFormContentEdit,
                        filterQueries: filterQueries,
                        filterQueriesJsonString: filterQueriesJsonString
                    },
                    { headers: getHeaderToken() })
                    .then(function (response) {
                        if (response.status <= 200) {
                            //self.showModifyQueryDetailsSuccessGrowl()
                            self.setState({
                                displayDialogEditQuery: false
                            })
                            if (collectionsOld !== selectedCollections) {
                                self.changeWatchlists(queryIDEdit, selectedCollections)
                            }
                            self.refreshAllQueries()
                        }
                    }).catch(function (error) {
                        if (error.response.status === 400) {
                            self.showFailGrowl(error.response.data)
                        } else if (error.response.status === 401) {
                            self.props.history.push('/')
                        } else if (error.response.status === 404) {
                            self.setState({
                                displayDialogEditQuery: false
                            })
                            console.log("not found")
                        }
                    })
            } else {
                if (collectionsOld !== selectedCollections) {
                    self.changeWatchlists(queryIDEdit, selectedCollections)
                }
                self.refreshAllQueries()
            }
        }
    }

    changeWatchlists = async (queryID, collections) => {
        let self = this
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/${queryID}/collections?ids=${collections}`, {}, { headers: getHeaderToken() })
            .then(function (response) {
                if (self.state.selectedWatchlist !== undefined) {
                    //self.showQueryCollectionChangeSuccessGrowl()
                    axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${self.state.selectedWatchlist.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                        .then(function (response) {
                            //self.showRefreshCollectionQueriesSuccessGrowl()
                            self.setState({ selectedQueries: response.data.content })
                            self.refreshWatchlists()
                            self.refreshWatchlists(self.state.selectedWatchlist.id)
                            //self.refreshQueries()
                        })
                        .catch(function (error) {
                            if (error.response.status === 400) {
                                self.showFailGrowl(error.response.data)
                            } else if (error.response.status === 401) {
                                self.props.history.push('/')
                            } else if (error.response.status === 404) {
                                console.log("not found")
                            }
                        })
                } else {
                    //self.showQueryCollectionChangeSuccessGrowl()
                    //self.refreshQueryCollections()
                    self.refreshAllQueries()
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    callbackFromTimeChange = (date) => {
        //console.log(date)
        this.setState({
            startTime: date,
            selectedQueriesAction: [],
            selectedWatchlistsAction: [],
            filteredQuery: null
        })
    }

    callbackFromShowAll = () => {
        this.setState({
            startTime: null
        })
    }

    onExportClick = () => {
        this.toastBC.current.clear()
        this.setState({
            displayExportWatchlists: true,
            selectedQueriesAction: [],
            globalFilterWatchlists: '',
            displayCustomToast: false,
            selectedWatchlistsExport: []
        })
    }

    onImportClick = () => {
        this.toastBC.current.clear()
        this.setState({
            displayImportWatchlists: true,
            selectedQueriesAction: [],
            inputText: '',
            displayCustomToast: false
        })
    }

    handleCloseExportDialog = () => {
        this.setState({
            displayExportWatchlists: false,
            globalFilterWatchlists: '',
            selectedWatchlistsExport: []
        })
    }

    handleCloseImportDialog = () => {
        this.setState({
            displayImportWatchlists: false
        })
    }

    uploadHandler = ({ files }) => {
        // --- hack: increase counter to change key of file upload component --- //
        // --- otherwise file upload works only once --- //
        const self = this;

        // --- check file type --- //
        const [file] = files;

        var reader = new FileReader();
        reader.onload = function (event) {
            // --- NOTE: event.target points to FileReader --- //
            var contents = event.target.result;
            self.setState({
                processInputText: true,
                inputText: contents
            });
        };

        reader.readAsText(file);

    };

    handleImportWatchlists = async () => {
        function IsJsonString(str) {
            try {
                let json = JSON.parse(str)
                return (typeof json === 'object')
            } catch (e) {
                return false
            }
        }
        if (IsJsonString(this.state.inputText)) {
            let importedWatchlists = JSON.parse(this.state.inputText)
            let allNewWatchlistNames = []
            importedWatchlists.forEach(list => {
                allNewWatchlistNames = [...allNewWatchlistNames, list.name]
            })
            let exists = false

            allNewWatchlistNames.forEach(name => {
                if (name.length > 50) {
                    exists = true
                    this.showNameLengthWarnGrowl(name)
                }
            })

            if (!exists) {
                this.setState({
                    fetching: true
                })
                let operationsCompleted = 0;
                let searchCollections = []
                function operation(name, oldID, newID) {
                    ++operationsCompleted;
                    if (name !== undefined) {
                        searchCollections = [...searchCollections, { name: name, old: oldID, new: newID }]
                    }
                    if (operationsCompleted === importedWatchlists.length) {

                        let defaultID = ''
                        self.state.allWatchlists.forEach(list => {
                            if (list.defaultCollection) {
                                defaultID = list.id
                            }
                        })

                        allSearches.forEach(search => {
                            let newCollections = []
                            search.queryCollectionList.forEach(list => {
                                //console.log(list)
                                searchCollections.forEach(collection => {
                                    if (!list.defaultCollection && (list.id === collection.old)) {
                                        newCollections = [...newCollections, collection.new]
                                    } else if (list.defaultCollection && collection.old === undefined) {
                                        newCollections = [...newCollections, defaultID]
                                    }
                                })
                            })
                            search.newCollections = newCollections
                        })

                        let operationsCompletedSearches = 0;

                        function operationSearches() {
                            ++operationsCompletedSearches;
                            if (operationsCompletedSearches === allSearches.length) {
                                self.setState({
                                    displayImportWatchlists: false,
                                    inputText: '',
                                    fetching: false
                                }, () => {
                                    self.refreshAllQueries()
                                    self.refreshWatchlists()
                                    if (self.state.watchlistID !== undefined) {
                                        self.refreshWatchlists(self.state.watchlistID)
                                    }
                                })
                            }
                        }

                        allSearches.forEach(search => {
                            let watchlistIDs = ''
                            search.newCollections.forEach(newCol => {
                                watchlistIDs += newCol + ','
                            })
                            watchlistIDs = watchlistIDs.substring(0, watchlistIDs.length - 1)
                            axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection?collectionIds=${watchlistIDs}`, {
                                name: search.name,
                                fullName: search.fullName,
                                type: search.type,
                                value: search.value,
                                description: search.description,
                                form: search.form,
                                formContent: search.formContent,
                                category: search.category
                            }, { headers: getHeaderToken() })
                                .then(function (response) {
                                    operationSearches()
                                })
                                .catch(function (response) {
                                    if (response.response.status === 400) {
                                        self.showFailGrowl(response.response.data)
                                        operationSearches()
                                    } else if (response.response.status === 401) {
                                        self.props.history.push('/')
                                    } else if (response.response.status === 404) {
                                        console.log("not found")
                                    }
                                })
                        })
                    }
                }
                let self = this
                let allSearches = []
                importedWatchlists.forEach(async list => {
                    //console.log(list)
                    if (list.searches && list.searches.length > 0) {
                        list.searches.forEach(search => {
                            if (!(allSearches.some(el => el.id === search.id)) || allSearches.length === 0) {
                                allSearches = [...allSearches, search]
                            }
                        })
                    }

                    if (!list.defaultCollection) {
                        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection`, {
                            name: list.name,
                            description: list.description,
                            sharedForDepartment: false,
                            usersShared: []
                        }, { headers: getHeaderToken() })
                            .then(function (response) {
                                operation(list.name, list.id, response.data.id)
                            })
                            .catch(function (error) {
                                if (error.response.status === 400) {
                                    self.showCreateWatchlistFailGrowl(error.response.data)
                                    operation()
                                } else if (error.response.status === 401) {
                                    self.props.history.push('/')
                                } else if (error.response.status === 404) {
                                    self.setState({
                                        displayDialogAddWatchlist: false
                                    })
                                    console.log("not found")
                                }
                            })
                    } else {
                        operation(list.name, undefined, undefined)
                    }
                })
            }
        } else {
            this.showFailGrowl("No valid JSON object.")
        }
    }

    /*handleExportWatchlists = () => {
        this.setState({
            displayExportWatchlists: false
        })
    }*/

    nameTemplate = (rowData) => {
        if (rowData.hasOwnProperty('name')) {
            return <React.Fragment>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </React.Fragment>
        }
    }

    numberQueriesTemplate = (rowData) => {
        if (rowData.hasOwnProperty('numberQueries')) {
            return <React.Fragment>
                <span className="p-column-title">Queries</span>
                {rowData.numberQueries}
            </React.Fragment>
        }
    }

    sharedTemplate(rowData) {
        return <div style={{ marginBottom: 5 }}>
            <span className="p-column-title">Sharing</span>
            {!rowData.shared ?
                ((rowData.departmentSharesRead && rowData.departmentSharesRead.length > 0) || (rowData.userSharesRead && rowData.userSharesRead.length > 0) || (rowData.userSharesWrite && rowData.userSharesWrite.length > 0)) ?
                    <span title={`You share this search collection with other users`}>
                        {'Shared by you'}
                    </span>
                    :
                    <span title={`This search collection can only be seen by you`}>
                        {'Private'}
                    </span>
                :
                <span title={`This search collection is shared with you by another user`}>
                    {'Shared with you'}
                </span>
            }
        </div>
    }

    onExportWatchlistChange = (e) => {
        this.setState({
            selectedWatchlistsExport: e.value
        })
    }

    handleExportWatchlists = () => {
        let filteredWatchlistsExport = []
        let allWatchlistsExport = JSON.parse(JSON.stringify(this.state.selectedWatchlistsExport))
        let operationsCompleted = 0;
        function operation() {
            ++operationsCompleted;
            if (operationsCompleted === allWatchlistsExport.length) {
                allWatchlistsExport.forEach(list => {
                    //console.log(list)
                    delete list.writable
                    //delete list.id
                    delete list.numberQueries
                    delete list.owner
                    delete list.sharedForDepartment
                    delete list.shared
                    delete list.created
                    delete list.modified
                    delete list.notifications
                    delete list.departmentSharesRead
                    delete list.userSharesRead
                    delete list.userSharesWrite
                    filteredWatchlistsExport = [...filteredWatchlistsExport, list]
                })
                saveAs(new Blob([JSON.stringify(filteredWatchlistsExport, undefined, 4)]), `search_collections.txt`)
                self.setState({
                    displayExportWatchlists: false,
                    globalFilterWatchlists: '',
                    selectedWatchlistsExport: []
                })
            }
        }
        let self = this
        allWatchlistsExport.forEach(list => {
            axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/querystore/v1/query/collection/${list.id}/page/0/count/10000/list`, { headers: getHeaderToken() })
                .then(function (response) {
                    let searches = response.data.content
                    searches.forEach(search => {
                        //delete search.queryCollectionList
                        // delete search.id
                        delete search.created
                        delete search.modified
                        delete search.notifications
                        search.queryCollectionList.forEach(list => {
                            delete list.sharedForDepartment
                            delete list.numberQueries
                            delete list.created
                            delete list.modified
                            delete list.notifications
                            delete list.shared
                            delete list.departmentSharesRead
                            delete list.userSharesRead
                            delete list.userSharesWrite
                        })
                    })
                    list.searches = searches
                    operation()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showFailGrowl(error.response.data)
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        })
    }

    onCustomPage = (e, isPaginator) => {
        if (isPaginator) {
            this.setState({
                firstQueries: e.first,
                rowsQueries: e.rows
            })
        } else {
            this.setState({
                rowsQueries: e.value
            })
        }
    }

    render() {

        const fetchingData = this.state.fetching

        //console.log(this.state.fetching)

        /*const sharedWithDepartmentOptions = [
            { label: 'yes', value: true },
            { label: 'no', value: false }
        ]*/

        let members = []

        this.state.departmentMembers && this.state.departmentMembers.forEach(member => {
            //console.log(member)
            members = [...members, { label: `${member.lastName}, ${member.forename} (${member.username})`, value: member.id }]
        })


        const footerCreateWatchlist = (
            <div className='col-12' style={{ paddingRight: 15, paddingBottom: 0 }}>
                <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 8 }}>* required</label>
                <div>
                    <Button label="Cancel" onClick={(e) => this.hideCreateWatchlist(e)} className="p-button-secondary p-button-sm" />
                    <Button label="Create" onClick={this.onSaveWatchlist} style={{ float: 'right', marginRight: -5 }} className='p-button-sm primaryButton'
                        disabled={this.state.newWatchlistName && this.state.newWatchlistName.length > 0 ? false : true} />
                </div>
            </div>
        )

        let headerWatchlists = <div className='grid' style={{
            marginRight: 0, paddingLeft: 10,
            paddingTop: 18, paddingBottom: 10
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search collections">
                    {'search'}
                </i>
                <InputText
                    style={{ border: 'none', width: '83%', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block', whiteSpace: 'nowrap' }}
                    type="search"
                    autoComplete="off"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilterWatchlists: e.target.value })}
                    placeholder="Search collections" />
            </div>
        </div>

        let columnDataWatchlists = this.state.colsWatchlists.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        })

        const acceptedFileTypes = ['.txt']
        const acceptedFileTypesString = acceptedFileTypes.join(',')

        let filteredWatchlists = []

        if (this.state.allWatchlists && this.state.allWatchlists.length > 0) {
            filteredWatchlists = this.state.allWatchlists.filter(obj => {
                return obj.numberQueries > 0
            })
        }

        /*const groupedItemsTemplate = (option) => {
            console.log(option)
            return (<div>
                <TreeTable
                value={companies}>
                <Column field='label' header=''></Column>
                </TreeTable>
            </div>
            )
        }*/

        let sortedUsers = this.state.userNames?.sort((a, b) => a.label < b.label ? -1 : 1)

        let users = sortedUsers.map((user, i) => {
            return <>
                {i === 0 &&
                    <div key={`${i}_${user.label}`} className="grid" style={{ margin: 0, paddingRight: 0, paddingTop: 0 }}>
                        <div style={{ paddingBottom: 0, paddingRight: 0, paddingTop: 0 }} className="col-9 lg:col-9 xl:col-10">
                        </div>
                        <div style={{ paddingBottom: 0, paddingRight: 0, paddingTop: 0 }} className="col-3 lg:col-3 xl:col-2">
                            Can edit
                            <span><a className="infoIconLink valignMiddle"
                                style={{ marginTop: -1, cursor: 'default' }}
                                title="Users that can edit a search collection are allowed to i) add/remove searches and ii) move/copy searches but are not allowed to edit the searches being part of this search collection">
                                <img src={infoIcon} alt="Information about editing"
                                    style={{ marginLeft: 5 }} />
                            </a></span>
                        </div>
                    </div>
                }
                <div className="grid" style={{ margin: 0, paddingRight: 0 }}>
                    <div className="col-9 lg:col-9 xl:col-10" style={{ marginTop: 5, background: '#dee2e6', color: '#495057', borderRadius: 16, paddingRight: 0 }}>
                        <label>{user.label} <i style={{ fontSize: '0.7rem', cursor: 'pointer', paddingLeft: 5 }} onClick={(e) => this.onUserRemoveClick(user)} className="pi pi-times"></i></label>
                    </div>
                    <div className="col-3 lg:col-3 xl:col-2">
                        <InputSwitch style={{ marginTop: 3, paddingRight: 0 }} checked={this.state.usersWrite?.includes(user.value)} onChange={(e) => this.setUserWrite(e, user)} />
                    </div>
                </div>
            </>
        })

        return (
            <div className="grid">
                <Toast ref={(el) => { this.growl = el }} />

                <LoadingOverlay
                    active={fetchingData}
                    spinner={true}
                    text='Loading saved searches'>
                    <div className='col-12' style={{ float: 'left', padding: 0 }}>
                        {/*<div className='col-12' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 style={{ marginTop: 0, marginLeft: -5 }} className="pageHeader">My Saved Searches</h1>
                            <img src={pageLogo} alt={`library logo`} style={{ height: 120, marginRight: 15, marginTop: -10 }} />
        </div>*/}

                        <div className='col-12' style={{ paddingBottom: 0 }}>
                            <div className='grid'>
                                <div className="col textAlignLeft" style={{ paddingBottom: 0 }}>
                                    <h1 style={{ marginBottom: 0, marginTop: 3 }} className="pageHeader">My Saved Searches</h1>
                                </div>
                                <div className="col-fixed textAlignRight" style={{ verticalAlign: 'sub' }}>
                                    {!isArrayEmpty(this.state.pageTutorials) ?
                                        <span style={{ marginLeft: 30 }}>
                                            <TutorialVideos
                                                pageTutorials={this.state.pageTutorials}
                                                section='searches'
                                                sectionLabel='My Saved Searches'
                                            /></span> : null}
                                </div>
                            </div>
                        </div>

                        <div className="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10" style={{
                            paddingLeft: 7 //paddingLeft: 0, paddingBottom: 25, marginTop: -60 
                        }}>
                            <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal', color: "#757575", lineHeight: "20px" }}>Every search you perform can be saved, edited and executed again
                                later. Searches can, but must not be, sorted into search collections for easier management.
                                You can set automatic alerts on individual searches as well as on search collections. You can share search collections as well as see search collections someone
                                else shared with you. You can change the dashboard visibility status of each search collection except the "All saved searches" search collection and search collections that are shared with you.
                            </label>
                        </div>
                    </div>
                    <div className='grid col-12' style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}>
                        <div className='col-6 sm:col-6 md:col-5 lg:col-4 xl:col-3' style={{ paddingLeft: 5, borderRight: '1px solid #d6d6d6', paddingTop: 0, paddingRight: 10 }}>
                            <Watchlists
                                history={this.props.history}
                                userData={this.props.userData}
                                onAddWatchlist={this.onAddWatchlist}
                                sortWatchlistsBy={this.state.sortWatchlistsBy}
                                onSortWatchlists={this.onSortWatchlists}
                                allQueries={this.state.allQueries}
                                allWatchlists={this.state.allWatchlists}
                                handleWatchlistChange={this.handleWatchlistChange}
                                watchlistAlertChange={this.watchlistAlertChange}
                                openAddAlertDialog={this.openAddAlertDialog}
                                selectedWatchlist={this.state.selectedWatchlist}
                                onExportClick={this.onExportClick}
                                onImportClick={this.onImportClick}>
                            </Watchlists>
                        </div>
                        <div className='col-6 sm:col-6 md:col-7 lg:col-8 xl:col-9' style={{ paddingTop: 0, paddingLeft: 15, paddingRight: 0 }}>
                            <WatchlistQueries
                                departmentMembers={this.state.departmentMembers}
                                history={this.props.history}
                                userData={this.props.userData}
                                allWatchlists={this.state.allWatchlists}
                                selectedWatchlist={this.state.selectedWatchlist}
                                watchlistName={this.state.watchlistName}
                                watchlistDescription={this.state.watchlistDescription}
                                showOnDashboard={this.state.showOnDashboard}
                                handleDashboardChange={this.handleDashboardChange}
                                selectedQueries={this.state.selectedQueries}
                                deleteWatchlist={this.deleteWatchlist}
                                modifyWatchlist={this.modifyWatchlist}
                                onRemoveWatchlist={this.onRemoveWatchlist}
                                onModifyWatchlist={this.onModifyWatchlist}
                                displayDialogModifyWatchlist={this.state.displayDialogModifyWatchlist}
                                displayDialogRemoveWatchlist={this.state.displayDialogRemoveWatchlist}
                                onHideRemoveWatchlist={this.onHideRemoveWatchlist}
                                onHideModifyWatchlist={this.onHideModifyWatchlist}
                                watchlistAlertChange={this.watchlistAlertChange}
                                watchlistAlertChangeQuery={this.watchlistAlertChangeQuery}
                                handleIntervalChange={this.handleIntervalChange}
                                canEdit={this.state.canEdit}
                                copyQueries={this.copyQueries}
                                moveQueries={this.moveQueries}
                                selectedQueriesAction={this.state.selectedQueriesAction}
                                selectedWatchlistsAction={this.state.selectedWatchlistsAction}
                                handleUnselect={this.handleUnselect}
                                removeQueries={this.removeQueries}
                                selectQueries={this.selectQueries}
                                selectWatchlists={this.selectWatchlists}
                                handleCopyQueryDialog={this.handleCopyQueryDialog}
                                handleCloseCopyQueryDialog={this.handleCloseCopyQueryDialog}
                                handleRemoveQueriesDialog={this.handleRemoveQueriesDialog}
                                handleDeleteQueriesDialog={this.handleDeleteQueriesDialog}
                                displayDialogCopyQuery={this.state.displayDialogCopyQuery}
                                handleMoveQueryDialog={this.handleMoveQueryDialog}
                                handleCloseMoveQueryDialog={this.handleCloseMoveQueryDialog}
                                handleCloseRemoveQueryDialog={this.handleCloseRemoveQueryDialog}
                                handleCloseDeleteQueryDialog={this.handleCloseDeleteQueryDialog}
                                displayDialogMoveQuery={this.state.displayDialogMoveQuery}
                                displayDialogRemoveQueries={this.state.displayDialogRemoveQueries}
                                displayDialogDeleteQueries={this.state.displayDialogDeleteQueries}
                                onAddWatchlist={this.onAddWatchlist}
                                fetchSortFields={this.props.fetchSortFields}
                                sortFields={this.props.sortFields}
                                displayDialogEditQuery={this.state.displayDialogEditQuery}
                                openEditQuery={this.openEditQuery}
                                onHideEditQuery={this.onHideEditQuery}
                                onModifyQuery={this.onModifyQuery}
                                startTime={this.state.startTime}
                                callbackFromTimeChange={this.callbackFromTimeChange}
                                filteredQuery={this.state.filteredQuery}
                                callbackFromShowAll={this.callbackFromShowAll}
                                queryIntervals={this.state.queryIntervals}
                                firstQueries={this.state.firstQueries}
                                rowsQueries={this.state.rowsQueries}
                                onCustomPage={this.onCustomPage}
                                openAddAlertDialog={this.openAddAlertDialog}
                                userArray={this.state.userArray}
                                orgArray={this.state.orgArray}
                                allPossibleUsers={this.state.allPossibleUsers}
                                allPossibleSuborgs={this.state.allPossibleSuborgs}
                                allNotAllowedUsersRead={this.state.allNotAllowedUsersRead}
                                allNotAllowedUsersWrite={this.state.allNotAllowedUsersWrite}
                                allNotAllowedSuborgs={this.state.allNotAllowedSuborgs}
                                allUsers={this.state.allUsers}
                                newOrgArray={this.state.newOrgArray}
                                newOrgKeys={this.state.newOrgKeys}>
                            </WatchlistQueries>
                        </div>
                        {this.state.displayAddEditAlert ?
                            <AddEditAlert
                                displayAddEditAlert={this.state.displayAddEditAlert}
                                displayConfirmationDialog={this.state.displayConfirmationDialog}
                                onHide={this.closeAddEditAlert}
                                onHideConfirmationDialog={this.onHideConfirmationDialog}
                                type={this.state.selectedAlertType === 'watchlist' ? "search collection" : "search"}
                                selectedCollectionAddEdit={this.state.selectedCollectionAddEdit}
                                intervals={this.state.queryIntervals}
                                callbackFromSave={this.callbackFromSave}
                                onDeleteAlert={this.onDeleteAlert}
                                explanationText={this.state.selectedAlertType === 'watchlist' ? "Emails are sent if there are new documents for at least one search of this search collection." :
                                    "Emails are sent if there are new documents for this search."}>
                            </AddEditAlert>
                            : null
                        }

                        <Dialog focusOnShow={false}
                            visible={this.state.displayDialogAddWatchlist}
                            style={{ width: '70vw' }}
                            breakpoints={{ '1500px': '95vw', '1050px': '100vw' }}
                            header="Create new search collection"
                            modal={true}
                            blockScroll
                            footer={footerCreateWatchlist}
                            dismissableMask={false}
                            onHide={(e) => this.hideCreateWatchlist(e)} className='styledDialog'>
                            <div id='createCollectionForm'
                                className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Name *</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ padding: '.5em' }}>
                                    <InputText maxLength={50} id="name" onChange={(e) => { this.updateName(e.target.value) }} value={this.state.newWatchlistName} />
                                    <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Description</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ padding: '.5em' }}>
                                    <InputText maxLength={200} id="description" onChange={(e) => { this.updateDescription(e.target.value) }} value={this.state.newWatchlistDescription} />
                                    <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                                    <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                                </div>
                                <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ display: 'flex', paddingRight: 10, paddingTop: 5 }}>
                                    <div className="w-full" >
                                        <MultiSelect
                                            id='userMultiSelect'
                                            pt={{
                                                checkboxIcon: {
                                                    onClick: (e) => {
                                                        e.stopPropagation();
                                                        if (e.target.className.baseVal !== "") {
                                                            e.target.parentNode.click();
                                                        } else {
                                                            e.target.parentNode.parentNode.click();
                                                        }
                                                    },
                                                },
                                                headerCheckbox: {
                                                    onClick: (e) => {
                                                        e.stopPropagation();
                                                        e.target.parentNode.click();
                                                    },
                                                },
                                            }}
                                            appendTo={document.body}
                                            filter
                                            resetFilterOnHide
                                            display='chip'
                                            filterPlaceholder='Filter suborgs.'
                                            value={this.state.newOrgKeys}
                                            disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.state.departmentMembers && this.state.departmentMembers?.length !== 0 ? false : true}
                                            options={this.state.newOrgArray}
                                            onChange={(e) => this.onNewOrgArrayChange(e)}
                                            placeholder="Select suborganizations"
                                            style={{// width: 150, minWidth: 150,
                                                borderBottom: '1px solid gray', maxWidth: '27vw'
                                            }} />
                                    </div>
                                    <div className="w-full " >
                                        <AutoComplete
                                            className='autocomplete-input-field width100perc'
                                            style={{ marginLeft: 10, paddingRight: 5, marginTop: this.state.newOrgKeys?.length > 0 ? -1 : -4 }}
                                            placeholder='Type to find users'
                                            field='label'
                                            value={this.state.userValue}
                                            completeMethod={(e) => this.searchUser(e)}
                                            suggestions={this.state.userItems}
                                            onSelect={(e) => this.onSelectUser(e.value)}
                                            onChange={(e) => this.setUserValue(e)}
                                            disabled={this.state.departmentMembers && this.state.departmentMembers?.length !== 0 ? false : true} />
                                        {this.state.userNames?.length > 0 ?
                                            <div style={{ marginLeft: 10, marginTop: 10 }}>
                                                {/*<div>Visible for:</div>*/}
                                                <ScrollPanel className='userScrollPanel' style={{ width: '100%', height: '105px', maxHeight: `calc(${(users?.length * 42) + 20}px)` }}>
                                                    {users}
                                                </ScrollPanel></div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className='col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10' style={{ marginLeft: 'auto' }}>
                                    <div className="full-text-message" style={{ marginRight: 0 }}>
                                        Note: Users changing the suborganization will lose access to search collections shared on suborganization level, but will keep access to search collections shared on personal level.
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.state.displayExportWatchlists} style={{ 'width': "50vw" }}
                            header="Export search collections"
                            modal={true} dismissableMask={true} onHide={() => this.handleCloseExportDialog()} className='styledDialog'>
                            <React.Fragment>
                                {this.state.allWatchlists && this.state.allWatchlists.length > 0 &&
                                    <React.Fragment>
                                        <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 25, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>

                                            <div style={{ width: '100%' }} className="datatable-responsive-demo">
                                                {headerWatchlists}
                                                <DataTable ref={(el) => this.dt = el}
                                                    //scrollable
                                                    //scrollHeight='190px'
                                                    selection={this.state.selectedWatchlistsExport}
                                                    selectionMode="checkbox"
                                                    removableSort={true}
                                                    onSelectionChange={e => this.onExportWatchlistChange(e)}
                                                    //className="dataTableQueries checkboxCircle standardTableHeader"
                                                    className="p-datatable-responsive-demo"
                                                    value={filteredWatchlists}
                                                    responsive="true"
                                                    paginator={true}
                                                    rows={5}
                                                    globalFilter={this.state.globalFilterWatchlists}>
                                                    {columnDataWatchlists}
                                                </DataTable>
                                            </div>
                                        </div>
                                        <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                                            {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                            <Button label="Export"
                                                disabled={this.state.selectedWatchlistsExport && this.state.selectedWatchlistsExport.length > 0 ? false : true}
                                                onClick={this.handleExportWatchlists}
                                                className='p-button-sm primaryButton'
                                                style={{ marginRight: 0, float: 'right', marginTop: 0 }}
                                            //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                            />
                                            {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                            <Button label="Cancel" onClick={() => this.handleCloseExportDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 0, marginRight: '0.5rem' }} />
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        </Dialog>
                        <Dialog focusOnShow={false} visible={this.state.displayImportWatchlists} style={{ 'width': "50vw" }}
                            header="Import search collections"
                            modal={true} dismissableMask={true} onHide={() => this.handleCloseImportDialog()} className='styledDialog'>
                            <React.Fragment>
                                <div className="grid p-fluid" style={{ paddingLeft: 25, paddingRight: 15, paddingBottom: 15, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                                    <div className="col-12">
                                        <FileUpload
                                            name="upload"
                                            className="primaryButton"
                                            accept={acceptedFileTypesString}
                                            customUpload={true}
                                            uploadHandler={e => this.uploadHandler(e)}
                                            mode="basic"
                                            auto={true}
                                            chooseLabel="Upload file" />
                                    </div>
                                    <div className="col-12">
                                        <InputTextarea
                                            id="content"
                                            autoResize={true}
                                            readOnly={false}
                                            //rows={5}
                                            //cols={30}
                                            className="width100perc"
                                            value={this.state.inputText}
                                            onChange={(e) => {
                                                this.setState({
                                                    inputText: e.target.value
                                                });
                                            }}
                                            style={{ height: '50vh', maxHeight: '50vh' }}
                                        />
                                    </div>
                                </div>
                                <React.Fragment>
                                    <div className='col-12' style={{ paddingRight: 20, marginTop: 5, marginBottom: 25 }}>
                                        {/*<a style={{ marginBottom: 0, marginLeft: 0, marginTop: 0, float: 'left' }}
                                            title="Create new watchlist" onClick={this.addNewWatchlist}>
                        Create collection</a>*/}
                                        <Button label="Import"
                                            onClick={this.handleImportWatchlists}
                                            disabled={this.state.inputText && this.state.inputText !== "" ? false : true}
                                            className='p-button-sm primaryButton'
                                            style={{ marginRight: 0, float: 'right' }}
                                        //style={{ marginRight: 0, marginLeft: 15, marginTop: filteredWatchlists && filteredWatchlists.length > 1 ? 45 : 25 }}
                                        />
                                        {/*<Button label="Save" disabled={true} className='pink-btn' icon="pi-md-save" onClick={this.saveChanges} style={{ marginRight: 0, marginLeft: 15, marginTop: 45 }} />*/}
                                        <Button label="Cancel" onClick={() => this.handleCloseImportDialog()} className="p-button-secondary p-button-sm" style={{ float: 'right', marginRight: '0.5rem' }} />
                                    </div>
                                </React.Fragment>
                            </React.Fragment>
                        </Dialog>
                    </div>
                    <Toast ref={this.toastBC} position="bottom-center" style={{ width: this.state.selectedWatchlist !== undefined && !this.state.selectedWatchlist.shared ? '30rem' : '25rem', backgroundColor: 'white', opacity: '1', marginBottom: 35, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                    <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                        onHide={this.handleUnselect} style={{ width: this.state.selectedWatchlist !== undefined && !this.state.selectedWatchlist.shared || (this.state.selectedWatchlist !== undefined && this.state.selectedWatchlist.shared && this.state.selectedWatchlist.writable) ? '34rem' : this.state.foreignQuery ? '20rem' : '26rem', bottom: 35 }}
                        draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                        <ToastContent selectedElements={this.state.selectedQueriesAction}
                            elementLabel='search'
                            collectionID={this.state.selectedWatchlist}
                            onHide={this.handleUnselect}
                            onAddElements={this.handleCopyQueryDialog}
                            onCopyElements={this.handleCopyQueryDialog}
                            onDelete={this.handleDeleteQueriesDialog}
                            onMoveElements={this.handleMoveQueryDialog}
                            onRemoveElements={this.handleRemoveQueriesDialog}
                            foreignQuery={this.state.foreignQuery}
                            foreignQueryCount={this.state.foreignQueryCount}
                            activeOptions={this.state.selectedWatchlist !== undefined && this.state.selectedWatchlist.shared && !this.state.selectedWatchlist.writable ? ['addElements', 'copyElements'] : ['addElements', 'copyElements', 'moveElements', 'removeElements', 'deleteElements']} />
                    </Dialog>
                </LoadingOverlay>
            </div>
        )
    }
}

export default WatchlistsAndQueries
