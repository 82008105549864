/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from 'react'
import { getHeaderToken } from '../../../api/index'
import { APP_PROPERTIES } from '../../../properties/index';
import { checkResultAndGetPayload } from '../../../api';
import { exportDocumentsToReadcube } from '../../../api/content/DocumentApi';
import { //createDocViewUrl, 
    hasUserRole
} from '.././util'
import axios from 'axios'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ToastContent from '../../common/CustomToast/ToastContent';
import { InputText } from 'primereact/inputtext';
//import NumberFormat from 'react-number-format';
import { Rating } from 'primereact/rating';
import { ListBox } from 'primereact/listbox';
//import { checkResultAndGetPayload } from '../../../api';
import AddRemoveDocuments from '../docCollection/AddRemoveDocuments';
import AddReadcubeDialog from "../../common/ReadCube/AddReadcubeDialog";
//import { Panel } from 'primereact/panel';
//import { TabView, TabPanel } from 'primereact/tabview';
import LoadingOverlay from "@speedy4all/react-loading-overlay";
//import { createDocViewUrl } from '.././util'

import CustomPaginatorLeftSide from '../../common/CustomPaginator/CustomPaginatorLeftSide'
import CustomPaginatorRightSide from '../../common/CustomPaginator/CustomPaginatorRightSide'
import CustomPaginatorTemplate from '../../common/CustomPaginator/CustomPaginatorTemplate'
import { DocViewModal } from '../docview/common/DocviewModal/DocviewModal';
import ExportCenterSuccessMessage from '../export/ExportCenterSuccessMessage';
import readcubeIcon from "../../../assets/images/icons/readcube.png";


class ReadDocuments extends Component {
    toastBC = createRef();
    constructor(props) {
        super(props);
        let columnsAllDocuments = [
            { field: "check", selectionMode: 'multiple', style: { width: '4em', fontWeight: 'normal' } },
            //{ field: "read", header: `${APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons ? 'Read' : ''}`, style: { width: '40px', textAlign: 'left' }, body: this.readTemplate },
            { field: "title", header: "Title", body: this.titleTemplate, style: {}, sortable: true },
            { field: "rating", header: "My rating", style: { width: '10em', textAlign: 'left' }, body: this.relevanceTemplate, sortable: true },
            { field: "repository", header: "Repository", body: this.repositoryTemplate, style: { width: '13em', textAlign: 'left' }, sortable: true },
            { field: "collections", header: 'Document collections', body: this.collectionTemplate, style: { width: '12em', textAlign: 'left' } }

        ]
        this.state = {
            displayCustomToast: false,
            colsAllDocuments: columnsAllDocuments,
            first: 0,
            rows: 10,
            displayDialogRemoveDocuments: false,
            displayDialog: false,
            displayDialogAddCollection: false,
            allDocuments: [],
            selectedDocuments: [],
            isDocviewModalVisible: false,
            ocDocId: '',
            selectedDocument: null,
            repositoryId: '',
            displayAddReadcubeDialog: false
        }
        this.colOptionsDocuments = [];
        for (let col of this.state.colsAllDocuments) {
            this.colOptionsDocuments.push({ label: ` ${col.header}`, value: col });
        }
    }


    onShowDocviewModal = (repId, docId, doc) => {
        const { setIsLoaderActive } = this.props
        if (setIsLoaderActive)
            setIsLoaderActive(true)

        this.setState({
            ocDocId: docId,
            repositoryId: repId,
            selectedDocument: doc,
            isDocviewModalVisible: true
        })
    };

    onCloseDocviewModal = () => {
        const { setIsLoaderActive } = this.props
        if (setIsLoaderActive)
            setIsLoaderActive(false)

        this.setState({
            ocDocId: '',
            repositoryId: '',
            selectedDocument: null,
            isDocviewModalVisible: false
        })
    };


    componentDidMount() {
        var self = this;

        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document/read-status/true/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({ allDocuments: response.data.content })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    componentWillUnmount = () => {
        this.toastBC.current.clear()
        this.setState({
            selectedDocuments: [],
            displayCustomToast: false
        })
    }

    readTemplate = (rowData) => {
        if (!APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showIcons) {
            return <b><span>Read</span></b>
        }
        else {
            return <i className="material-icons"
                //id='read'
                title={'You read this document'}
            //onClick={(e) => this.onReadChange(rowData, e)}
            >
                {'drafts'}
            </i>
        }
    }

    relevanceTemplate = (rowData) => {
        let rating
        if (rowData.rating) {
            rating = rowData.rating
        } else {
            rating = 0
        }
        return <div>
            <span className="p-column-title">My rating</span>
            <span style={{ display: 'inline-block' }}>
                <Rating id="libraryRating" value={rating} cancel={true} readOnly={rowData.repositoryAvailable === false ? true : false} disabled={rowData.repositoryAvailable === false ? true : false} onChange={(e) => this.onEditorValueChange(rowData, e)} />
            </span>
        </div>
    }

    onEditorValueChange = async (rowData, value) => {
        //console.log(rowData)
        //console.log(value)
        if (rowData.rating !== value.value) {
            let val
            if (value.value !== null) {
                val = value.value
            } else {
                val = 0
            }
            let self = this;
            await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, [{ docId: rowData.docId, repository: rowData.repository, rating: val, title: rowData.title, repositoryId: rowData.repositoryId }], { headers: getHeaderToken() })
                .then(function (response) {
                    self.refreshDocuments()
                })
                .catch(function (error) {
                    if (error.response.status === 400) {
                        self.showChangeReadFailGrowl()
                    } else if (error.response.status === 401) {
                        self.props.history.push('/')
                    } else if (error.response.status === 404) {
                        console.log("not found")
                    }
                })
        }
    }

    titleTemplate = (rowData) => {
        if (rowData.title) {
            let title = rowData.title
            return <div>
                <span className="p-column-title">Title</span>
                {rowData.repositoryAvailable === false ?
                    <label>{title}</label>
                    :
                    <a onClick={(e) => this.showDocument(rowData, e)}>{title}</a>
                }
            </div>
        }
    }

    showDocument = (rowData) => {
        //console.log(rowData)
        //let link = createDocViewUrl(rowData.repositoryId, rowData.docId);
        // window.open(link, "_blank");
        this.onShowDocviewModal(rowData.repositoryId, rowData.docId, rowData)
    }

    repositoryTemplate = (rowData) => {
        let repLabel = ''
        if (rowData.repositoryAvailable === false) {
            repLabel = rowData.repository //+ ' (Repository not available)'
        } else {
            this.props.availableRepositories &&
                this.props.availableRepositories.filter(rep => rep.name === rowData.repository).forEach(repo => {
                    repLabel = repo.label
                })
        }
        return <React.Fragment>
            <span className="p-column-title">Repository</span>
            {rowData.repository.startsWith("rc_") ?
                <img src={readcubeIcon} alt="ReadCube papers"
                    style={{ height: 17, paddingRight: 5, verticalAlign: 'bottom' }} />
                : null
            }
            {repLabel}
        </React.Fragment>
    }

    collectionTemplate(rowData) {
        let collectionList = ''
        if (rowData.documentCollectionList) {
            rowData.documentCollectionList.forEach(list => {
                collectionList += list.name + ', '
            })
            collectionList = collectionList.substring(0, collectionList.length - 2)
            if (!rowData.documentCollectionList[0]) {
                collectionList = '-'
            } else if (rowData.documentCollectionList[0] && rowData.documentCollectionList[0].sharedComment) {
                collectionList = collectionList
            } else {
                collectionList = collectionList
            }
            return <React.Fragment>
                <span className="p-column-title">Document collections</span>
                {collectionList}
            </React.Fragment>
        }
    }

    handleSelectionChange = (e) => {
        if (e.value.length === 0) {
            this.toastBC.current.clear();
            this.setState({
                displayCustomToast: false
            })
        } else if (e.value.length !== 0) {
            this.setState({
                displayCustomToast: true
            })
        }
        /*if (this.toastBC.current.state.messages.length === 0) {
            this.toastBC.current.show({
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', fontWeight: 'bold' }}>{e.value.length} {e.value.length !== 1 ? 'documents' : 'document'} selected</label>
                        </div>
                        <div className='col-12 grid p-fluid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-4' style={{}}>
                                <a style={{}}
                                    title={e.value.length && e.value.length > 1 ? `Add ${e.value.length} selected documents to library` : `Add selected document to library`}
                                    onClick={this.handleSave}>Add to library</a>
                            </div>
                            <div className='col-4' style={{ textAlign: 'center' }}>
                                <a style={{}}
                                    title={e.value.length && e.value.length > 1 ? `Remove ${e.value.length} selected documents` : `Remove selected document`}
                                    onClick={this.handleRemoveDocumentsDialog}>Remove</a>
                            </div>
                            <div className='col-4' style={{ textAlign: 'end' }}>
                                <a style={{}}
                                    title='Unselect'
                                    onClick={this.handleUnselect}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            });
        } else if (this.toastBC.current.state.messages.length !== 0) {
            this.toastBC.current.state.messages = [{
                id: this.state.toastID,
                closable: false, severity: '', sticky: true, content: (
                    <div className='col-12' style={{ display: 'block', padding: 0 }}>
                        <div className='col-12' style={{ textAlign: 'center', padding: 0, paddingBottom: 5 }}>
                            <label style={{ color: 'black', fontWeight: 'bold' }}>{e.value.length} {e.value.length !== 1 ? 'documents' : 'document'} selected</label>
                        </div>
                        <div className='col-12 grid p-fluid' style={{ justifyContent: 'space-between', padding: 0 }}>
                            <div className='col-4' style={{}}>
                                <a style={{}}
                                    title={e.value.length && e.value.length > 1 ? `Add ${e.value.length} selected documents to library` : `Add selected document to library`}
                                    onClick={this.handleSave}>Add to library</a>
                            </div>
                            <div className='col-4' style={{ textAlign: 'center' }}>
                                <a style={{}}
                                    title={e.value.length && e.value.length > 1 ? `Remove ${e.value.length} selected documents` : `Remove selected document`}
                                    onClick={this.handleRemoveDocumentsDialog}>Remove</a>
                            </div>
                            <div className='col-4' style={{ textAlign: 'end' }}>
                                <a style={{}}
                                    title='Unselect'
                                    onClick={this.handleUnselect}>Unselect</a>
                            </div>
                        </div>
                    </div>
                )
            }]

        }*/
        this.setState({
            selectedDocuments: e.value
        }/*, () => {
            if (this.toastBC.current.state.messages[0]) {
                this.setState({
                    toastID: this.toastBC.current.state.messages[0].id
                })
            }
        }*/
        )
    }

    onCloseAddToCollectionsDialog = () => {
        this.setState({
            displayDialogAddToCollections: false
        })
    }

    onSubmitAddToCollections = async (collections) => {
        let ids = ''
        collections && collections.forEach(col => {
            ids += col.id + ","
        })
        ids = ids.slice(0, -1)

        let filteredDocumentObject = []
        const myArr = ['docId', 'repository', 'title', 'repositoryId']

        this.state.selectedDocuments && this.state.selectedDocuments.forEach((doc, i) => {
            const filtered = Object.keys(doc).filter(key => myArr.includes(key)).reduce((obj, key) => {
                //console.log(key)
                if (key === 'docId') {
                    obj['docId'] = doc[key]
                } else {
                    obj[key] = doc[key]
                }
                return obj
            },
                {})

            filteredDocumentObject = [...filteredDocumentObject, filtered]
        })

        //console.log(filteredDocumentObject)

        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/documents?collectionIds=${ids}`,
            filteredDocumentObject
            , { headers: getHeaderToken() })
            .then(function (response) {
                //self.showAddDocumentSuccessGrowl()
                //self.toastBC.current.clear();
                self.setState({
                    displayDialogAddToCollections: false,
                    //selectedDocuments: [],
                    selectedCollection: []
                }, () => {
                    self.refreshDocuments()
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showAddDocumentFailGrowl(error.response.data)
                    self.setState({
                        displayDialogAddToCollections: false,
                        selectedDocuments: [],
                        selectedCollection: []
                    })
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        displayDialogAddCollection: false
                    })
                    console.log("not found")
                }
            })
    }

    handleRemoveDocumentsDialog = (rowData) => {
        this.setState({
            displayDialogRemoveDocuments: true,
            rowDataDocumentRemove: rowData
        })
    }

    handleRemoveDocuments = async () => {
        let self = this;
        let unreadArray = []
        self.state.selectedDocuments.forEach(doc => {
            unreadArray = [...unreadArray, { docId: doc.docId, repository: doc.repository, read: false, title: doc.title, repositoryId: doc.repositoryId }]
        })
        await axios.put(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/documents`, unreadArray, { headers: getHeaderToken() })
            .then(function (response) {
                //self.showChangeReadSuccessGrowl()
                self.toastBC.current.clear();
                self.setState({
                    displayDialogRemoveDocuments: false,
                    selectedDocuments: [],
                    displayCustomToast: false
                }, () => {
                    self.refreshDocuments()
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showChangeReadFailGrowl()
                    self.setState({
                        displayDialogRemoveDocuments: false,
                        selectedDocuments: []
                    })
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    showChangeReadSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Documents successfully removed from list.' };
        this.growl.show(msg);
    }

    showChangeReadFailGrowl = () => {
        let msg = { severity: 'error', summary: 'Failed!', detail: 'Documents could not be removed from list.', life: 6000 };
        this.growl.show(msg);
    }

    showFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    handleSave = () => {
        var self = this;
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    documentCollections: response.data.content,
                    displayDialogAddToCollections: true,
                    selectedCollection: []
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    onCollectionChange = (e) => {
        this.setState({
            selectedCollection: e.value
        })
    }

    addNewCollection = () => {
        this.setState({
            displayDialogAddCollection: true,
            newCollectionName: '',
            newCollectionDescription: ''
        });
    }

    updateName = (name) => {
        this.setState({
            newCollectionName: name
        })
    }

    updateDescription = (desc) => {
        this.setState({
            newCollectionDescription: desc
        })
    }

    handleSaveDocuments = async () => {
        let ids = ''
        this.state.selectedCollection && this.state.selectedCollection.forEach(col => {
            ids += col.id + ","
        })
        ids = ids.slice(0, -1)

        let filteredDocumentObject = []
        const myArr = ['docId', 'repository', 'title', 'repositoryId']

        this.state.selectedDocuments && this.state.selectedDocuments.forEach((doc, i) => {
            const filtered = Object.keys(doc).filter(key => myArr.includes(key)).reduce((obj, key) => {
                //console.log(key)
                if (key === 'docId') {
                    obj['docId'] = doc[key]
                } else {
                    obj[key] = doc[key]
                }
                return obj
            },
                {})

            //filteredDocumentObject = [...filteredDocumentObject, Object.assign(filtered, {api: 'oc-webapi' })]
        })

        var self = this;
        await axios.post(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document-collections/documents?collectionIds=${ids}`,
            filteredDocumentObject
            , { headers: getHeaderToken() })
            .then(function (response) {
                //self.showAddDocumentSuccessGrowl()
                //self.toastBC.current.clear();
                self.setState({
                    displayDialog: false,
                    //selectedDocuments: [],
                    selectedCollection: []
                }, () => {
                    self.refreshDocuments()
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showAddDocumentFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    self.setState({
                        displayDialogAddCollection: false
                    })
                    console.log("not found")
                }
            })
    }

    showAddDocumentSuccessGrowl = (e) => {
        let msg = { severity: 'success', summary: 'Success!', detail: 'Document successfully added to library.' };
        this.growl.show(msg);
    }

    showAddDocumentFailGrowl = (e) => {
        let msg = { severity: 'error', summary: 'Failed!', detail: e, life: 6000 };
        this.growl.show(msg);
    }

    onHide = () => {
        this.setState({
            displayDialogRemoveDocuments: false
        })
    }

    refreshDocuments = async () => {
        let self = this
        axios.get(`${APP_PROPERTIES.MIDDLEWARE_BASE_URL}/api/document-store/v1/document/read-status/true/page/0/count/1000/list`, { headers: getHeaderToken() })
            .then(function (response) {
                self.setState({
                    allDocuments: response.data.content
                }, () => {
                    //console.log(self.state.allDocuments)
                    if (Array.isArray(self.state.selectedDocuments)) {
                        let selectedDocuments = []
                        self.state.selectedDocuments.forEach(selDoc => {
                            self.state.allDocuments.forEach(doc => {
                                if (selDoc.docId === doc.docId) {
                                    selectedDocuments = [...selectedDocuments, doc]
                                }
                            })
                        })
                        //console.log("new sel:", selectedDocuments)
                        self.setState({ selectedDocuments: selectedDocuments })
                    }
                })
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    self.showFailGrowl(error.response.data)
                } else if (error.response.status === 401) {
                    self.props.history.push('/')
                } else if (error.response.status === 404) {
                    console.log("not found")
                }
            })
    }

    handleUnselect = () => {
        this.toastBC.current.clear();
        this.setState({
            selectedDocuments: [],
            displayCustomToast: false
        })
    }

    onCustomPage = (e) => {
        this.setState({
            first: e.first,
            rows: e.rows
        })
    }


    getModifiedDocuments = docs => (docs?.map((item, index) => {
        if (item.document) {
            return {
                number: index + 1,
                ...item.document,
                user: item.user
            }
        } else {
            return {
                number: index + 1,
                ...item
            }
        }
    }))

    onAddToReadcube = () => {
        this.setState({
            displayAddReadcubeDialog: true
        })
    }

    onSubmitReadcubeLibrary = async (lib) => {

        let docIds = []
        if (this.state.selectedDocuments && Object.values(this.state.selectedDocuments).length > 0) {
            Object.values(this.state.selectedDocuments).forEach(doc => {
                docIds.push({
                    documentIdentifier: doc.docId,
                    repositoryIdentifier: doc.repositoryId
                });
            });
            let response;
            response = await exportDocumentsToReadcube(docIds, 'readcube', lib.id);
            if (response) {
                const result = checkResultAndGetPayload(response, this.growl, 'Success', <ExportCenterSuccessMessage toReadcubeExports={true} />, null, true);
                if (response.status === 'SUCCESS') {
                    this.setState({
                        displayAddReadcubeDialog: false
                    })
                }
            }
        }
        else {
            this.growl.current.show({
                sticky: false, closable: true, severity: 'error',
                summary: 'Missing data', detail: 'No document selected.'
            });
        }
    }
    render() {
        const fetchingData = this.state.fetchingRepoStatisticsResults || this.state.fetchingDocumentResults

        let headerAllDocuments = <div className='grid' style={{
            marginRight: 0,
            borderBottom: '1px solid #757575',
            paddingTop: 5, paddingBottom: 10,
            //paddingLeft: 5
        }}>
            <div style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: '#f4f4f4', width: '100%' }}>
                <i className="material-icons"
                    style={{ float: 'left', paddingTop: 4, paddingLeft: 5 }}
                    title="Search bookmarked documents">
                    {'search'}
                </i>
                <InputText
                    style={{ width: '92%', border: 'none', float: 'left' }}
                    type="search"
                    className="collectionFilter"
                    onInput={(e) => this.setState({ globalFilter: e.target.value })}
                    placeholder="Search bookmarked documents"
                />
            </div>
        </div>

        let dropdownFieldsAllDocuments = [
        ];

        this.state.colsAllDocuments && this.state.colsAllDocuments.forEach(entry => {
            dropdownFieldsAllDocuments = [...dropdownFieldsAllDocuments, {
                label: entry.header,
                value: entry.field
            }]
        })

        let columnDataAllDocuments = this.state.colsAllDocuments.map((col, i) => {
            return <Column className="columnheader" key={col.field}
                field={col.field} headerStyle={col.headerStyle} selectionMode={col.selectionMode} header={col.header} sortable={col.sortable} body={col.body} style={col.style} filter={col.filter} />
        });

        /*let footerDocuments = <div className='col-12' style={{ paddingTop: 5, paddingBottom: 5, justifyContent: 'center', display: 'flex' }}>
            {this.state.selectedDocuments && this.state.selectedDocuments.length > 0 &&
                <div className='col-4' style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #d6d6d6', padding: '2em' }}>
                    <label style={{ color: 'black', marginTop: 5, marginRight: 5 }}>{this.state.selectedDocuments.length} {this.state.selectedDocuments.length !== 1 ? 'entries' : 'entry'} selected</label>
                    <a style={{ marginTop: 5 }}
                        title={this.state.selectedDocuments.length && this.state.selectedDocuments.length > 1 ? `Remove ${this.state.selectedDocuments.length} selected documents` : `Remove selected document`}
                        onClick={this.handleRemoveDocumentsDialog}>Remove</a>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title={this.state.selectedDocuments.length && this.state.selectedDocuments.length > 1 ? `Add ${this.state.selectedDocuments.length} selected documents to library` : `Add selected document to library`}
                        onClick={this.handleSave}>Add to library</a>
                    <a style={{ marginTop: 5, marginRight: 5 }}
                        title='Unselect'
                        onClick={this.handleUnselect}>Unselect</a>
                </div>
            }
        </div>;*/

        const footerRemoveDocuments = (
            <div style={{ paddingRight: 0, paddingTop: 5 }}>
                <Button className='p-button-sm primaryButton' label="Delete" onClick={this.handleRemoveDocuments} />
                <Button label="Cancel" onClick={this.onHide} className="p-button-secondary p-button-sm" />
            </div>
        )

        let filteredDocumentCollections = []

        this.state.documentCollections && this.state.documentCollections.forEach(collection => {
            if (collection.sharedComment && collection.editableForSharedUsers === false) {
            } else {
                filteredDocumentCollections = [...filteredDocumentCollections, collection]
            }
        })

        //let pageReportTemplate = this.state.allDocuments.length === 1 ? `${this.state.allDocuments.length} document` : `${this.state.allDocuments.length} documents`

        return (
            <div className="col-12 grid-nogutter" style={{ paddingTop: 0, paddingRight: 0 }}>
                <Toast ref={(el) => { this.growl = el }} />
                <LoadingOverlay
                    active={fetchingData}
                    spinner={true} >
                    {/*<div className='col-12' style={{ marginBottom: 20 }}>
                        <div className='col-12' style={{ float: 'left', padding: 0 }}>
                            <h1 style={{ marginTop: 0 }} className="pageHeader">My Read Documents</h1>
                            <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 10 }}>
                                <label style={{ marginRight: 10, verticalAlign: 'bottom', fontWeight: 'normal' }}> You can rate any document and you can set the “Read” flag
                                to indicate that you have taken notice of this document.
                                The “My Read Documents” tab affords you a quick overview over all those read documents.
                    </label>
                            </div>
        </div>
                    </div>*/}
                    <div className='col-12' style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0, marginBottom: this.state.selectedDocuments && this.state.selectedDocuments.length > 0 ? 65 : 0, paddingRight: 0 }}>
                        {this.state.allDocuments && this.state.allDocuments.length > 0 ?
                            <div className='col-12' style={{ display: 'inline-block', paddingBottom: 0, paddingLeft: 0 }}>
                                <div className="datatable-responsive-demo">
                                    {headerAllDocuments}
                                    <DataTable ref={(el) => this.dt = el}
                                        selection={this.state.selectedDocuments}
                                        onSelectionChange={e => this.handleSelectionChange(e)}
                                        selectionMode="checkbox"
                                        className="p-datatable-responsive-demo"
                                        removableSort={true}
                                        value={this.state.allDocuments}
                                        responsive="true"
                                        paginator={true}
                                        paginatorPosition="bottom"
                                        rows={this.state.rows}
                                        //header={headerAllDocuments}
                                        globalFilter={this.state.globalFilter}
                                        first={this.state.first}
                                        //footer={footerDocuments}
                                        rowsPerPageOptions={[10, 20, 50]}
                                        onPage={(e) => this.onCustomPage(e)}
                                        //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        //currentPageReportTemplate={pageReportTemplate}
                                        paginatorTemplate={CustomPaginatorTemplate}
                                        paginatorLeft={<CustomPaginatorLeftSide
                                            first={this.state.first}
                                            contentLength={this.state.allDocuments.length}
                                            rows={this.state.rows}>
                                        </CustomPaginatorLeftSide>}
                                        paginatorRight={<CustomPaginatorRightSide
                                            rows={this.state.rows}
                                            onChange={(e) => {
                                                this.setState({
                                                    rows: e.value
                                                })
                                            }}
                                        >
                                        </CustomPaginatorRightSide>}>
                                        {columnDataAllDocuments}
                                    </DataTable>
                                </div>
                            </div>
                            :
                            <div className='col-12' style={{ marginTop: 60 }}>
                                <label style={{ fontWeight: 'bold' }}>No bookmarked documents available.</label>
                            </div>
                        }
                    </div>
                    <Dialog focusOnShow={false} visible={this.state.displayDialogRemoveDocuments} style={{ 'width': "40vw" }} header={this.state.selectedDocuments && this.state.selectedDocuments.length > 1 ? "Delete documents" : this.state.selectedDocuments && this.state.selectedDocuments.length === 1 ? "Delete document" : null}
                        modal={true} footer={footerRemoveDocuments} dismissableMask={true}
                        onHide={() => this.setState({ displayDialogRemoveDocuments: false })} className='styledDialog'>
                        <div className="grid p-fluid" style={{ paddingLeft: 30, paddingRight: 15, paddingBottom: 20, paddingTop: 20, marginRight: 0, borderBottom: '1px solid #d6d6d6' }}>
                            {this.state.selectedDocuments && this.state.selectedDocuments.length > 1 ?
                                `Warning! These documents will be deleted from this list! Do you really want to delete these documents?
                            These documents will also be marked as not bookmarked in your library.`
                                : this.state.selectedDocuments && this.state.selectedDocuments.length === 1 ?
                                    `Warning! This document will be deleted from this list! Do you really want to delete this document?
                            This document will also be marked as not bookmarked in your library.`
                                    : null
                            }
                        </div>
                    </Dialog>
                    <Dialog focusOnShow={false}
                        visible={this.state.displayDialog}
                        header={this.state.selectedDocuments && this.state.selectedDocuments.length > 1 ? `Add documents` : this.state.selectedDocuments && this.state.selectedDocuments.length === 1 ? `Add document` : null}
                        style={{ width: '40vw' }}
                        modal={true}
                        dismissableMask={true}
                        closable={true}
                        //footer={dialogFooter} 
                        onHide={() => this.setState({ displayDialog: false })}>
                        <div className='col-12'>
                            {this.state.selectedDocuments && this.state.selectedDocuments.length > 0 &&
                                <React.Fragment>
                                    <div className="col-12 grid p-fluid" style={{ marginTop: 5, borderBottom: '1px solid #d6d6d6', paddingBottom: 15 }}>
                                        <div className='col-12'>
                                            <h4 style={{ margin: 5 }}>Document collections</h4>
                                            <ListBox value={this.state.selectedCollection}
                                                options={filteredDocumentCollections}
                                                onChange={(e) => this.onCollectionChange(e)}
                                                multiple={true}
                                                style={{}}
                                                optionLabel="name" />
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: 15 }}>
                                        <a style={{ marginBottom: 0, marginLeft: 0, marginTop: 15, float: 'left' }}
                                            title="Create new collection" onClick={this.addNewCollection}>
                                            Create collection</a>

                                        <Button label={this.state.selectedDocuments && this.state.selectedDocuments.length > 1 ? "Add documents" : this.state.selectedDocuments && this.state.selectedDocuments.length === 1 ? "Add document" : null}
                                            disabled={this.state.selectedCollection && this.state.selectedCollection.length > 0 ? false : true}
                                            onClick={this.handleSaveDocuments}
                                            className='p-button-sm primaryButton'
                                            style={{ marginRight: 0, float: 'right', marginTop: 5 }}
                                        />
                                        <Button label="Cancel" onClick={() => this.setState({ displayDialog: false })} className="p-button-secondary p-button-sm" style={{ float: 'right', marginTop: 5, marginRight: '0.5rem' }} />
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </Dialog>
                    <AddRemoveDocuments
                        userData={this.props.userData}
                        displayDialogRemoveDocument={this.state.displayDialogRemoveDocument}
                        displayDialogAddToCollections={this.state.displayDialogAddToCollections}
                        onCloseAddToCollectionsDialog={this.onCloseAddToCollectionsDialog}
                        onSubmitAddToCollections={this.onSubmitAddToCollections}
                        onCancelAddToCollections={this.onCloseAddToCollectionsDialog}
                    />
                    <Toast ref={this.toastBC} position="bottom-center" style={{ backgroundColor: 'white', opacity: '1', marginBottom: 40, boxShadow: '0 2px 8px 0 rgba(0,.0,0,0.54)' }} />
                    <Dialog visible={this.state.displayCustomToast} closable={false} showHeader={false} position={'bottom'} modal={false}
                        onHide={this.handleUnselect} style={{ width: hasUserRole('ROLE_READCUBEVIEW') ? '36rem' : '28rem', bottom: 40 }}
                        draggable={false} resizable={false} contentClassName='dialog-content-toast'>
                        <ToastContent selectedElements={this.state.selectedDocuments}
                            elementLabel='document'
                            onHide={this.handleUnselect}
                            onAddElements={this.handleSave}
                            onAddToReadcube={this.onAddToReadcube}
                            onDelete={this.handleRemoveDocumentsDialog}
                            activeOptions={hasUserRole('ROLE_READCUBEVIEW') ? ['addElements', 'deleteElements', 'addToReadcube'] :
                                ['addElements', 'deleteElements']} />
                    </Dialog>

                </LoadingOverlay>

                <DocViewModal
                    isVisible={this.state.isDocviewModalVisible}
                    docId={this.state.ocDocId}
                    repId={this.state.repositoryId}
                    selectedDoc={this.state.selectedDocument}
                    documents={{ '1': this.getModifiedDocuments(this.state.allDocuments) }}
                    onCloseDocviewModal={this.onCloseDocviewModal}
                    onPageChange={() => { }}
                    first={0}
                    rows={this.state.allDocuments?.length}
                    documentCount={this.state.allDocuments?.length}
                />

                <AddReadcubeDialog
                    displayDialog={this.state.displayAddReadcubeDialog}
                    onHide={() => this.setState({ displayAddReadcubeDialog: false })}
                    onSubmitReadcubeLibrary={this.onSubmitReadcubeLibrary}
                    readcubeRequests={null}>
                </AddReadcubeDialog>
            </div>
        )
    }
}

export default ReadDocuments
