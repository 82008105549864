/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';
class CreateBlacklist extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.growl = createRef();
    }
    onSaveBlacklist = async () => {
        this.props.onSaveBlacklist()
    }

    render() {

        const footerAddBlacklist = (
            <div className='col-12' style={{ paddingRight: 15, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                <label style={{ float: 'left', paddingTop: 0, marginTop: -10, paddingLeft: 5 }}>* required</label>
                <Button label="Cancel" onClick={() => this.props.hideAddBlacklist()} className="p-button-secondary p-button-sm" />
                <Button label="Create" onClick={this.onSaveBlacklist}
                    className='p-button-sm primaryButton'
                    style={{ float: 'right', marginRight: -5 }}
                    disabled={this.props.newBlacklistName && this.props.newBlacklistName.length > 0 ? false : true} />
            </div>
        )

        return (
            <React.Fragment>

                <Toast ref={this.growl} />

                <Dialog
                    focusOnShow={false}
                    visible={this.props.displayDialogAddBlacklist}
                    style={{ width: '70vw' }}
                    breakpoints={{ '1500px': '95vw', '1050px': '100vw' }}
                    blockScroll
                    header="Create new blocklist"
                    modal={true}
                    footer={footerAddBlacklist}
                    dismissableMask={true}
                    onHide={() => this.props.hideAddBlacklist()}
                    className='styledDialog'>
                    <div className="grid p-fluid" style={{ borderBottom: '1px solid #d6d6d6', marginBottom: 5, paddingLeft: 25, paddingBottom: 15, paddingTop: 15, marginRight: 0, paddingRight: 15 }}>
                        <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Name *</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ padding: '.5em' }}>
                            <InputText id="blName"
                                maxLength={50}
                                onChange={(e) => this.props.updateBlacklistName(e.target.value)}
                                value={this.props.newBlacklistName} />
                            <label style={{ color: 'darkgray' }}>Max. 50 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Description</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ padding: '.5em' }}>
                            <InputText id="blDescription"
                                maxLength={200}
                                onChange={(e) => this.props.updateBlacklistDescription(e.target.value)}
                                value={this.props.newBlacklistDescription} />
                            <label style={{ color: 'darkgray' }}>Max. 200 characters</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-5 lg:col-3 xl:col-2" style={{ padding: '.75em' }}>
                            <label style={{ verticalAlign: 'sub' }}>Sharing</label>
                        </div>
                        <div className="col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10" style={{ display: 'flex', paddingRight: 10, paddingTop: 5 }}>
                            <div className="w-full" >
                                <MultiSelect
                                    id='userMultiSelect'
                                    pt={{
                                        checkboxIcon: {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                if (e.target.className.baseVal !== "") {
                                                    e.target.parentNode.click();
                                                } else {
                                                    e.target.parentNode.parentNode.click();
                                                }
                                            },
                                        },
                                        headerCheckbox: {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                e.target.parentNode.click();
                                            },
                                        },
                                    }}
                                    appendTo={document.body}
                                    filter
                                    resetFilterOnHide
                                    display='chip'
                                    filterPlaceholder='Filter suborgs.'
                                    value={this.props.newOrgKeys}
                                    disabled={this.props.userData?.userDetails?.highestAdminType !== 'NONE' && this.props.departmentMembers && this.props.departmentMembers?.length !== 0 ? false : true}
                                    options={this.props.newOrgArray}
                                    onChange={(e) => this.props.onNewOrgArrayChange(e)}
                                    placeholder="Select suborganizations"
                                    style={{// width: 150, minWidth: 150,
                                        borderBottom: '1px solid gray', maxWidth: '27vw'
                                    }} />
                            </div>
                            <div className="w-full " >
                                <AutoComplete
                                    className='autocomplete-input-field width100perc'
                                    style={{ marginLeft: 10, paddingRight: 5, marginTop: this.props.newOrgKeys?.length > 0 ? -1 : -4 }}
                                    placeholder='Type to find users'
                                    field='label'
                                    value={this.props.userValue}
                                    completeMethod={(e) => this.props.searchUser(e)}
                                    suggestions={this.props.userItems}
                                    onSelect={(e) => this.props.onSelectUser(e.value)}
                                    onChange={(e) => this.props.setUserValue(e)}
                                    disabled={this.props.departmentMembers && this.props.departmentMembers?.length !== 0 ? false : true} />
                                {this.props.userNames?.length > 0 ?
                                    <div style={{ marginLeft: 10, marginTop: 10 }}>
                                        {/*<div>Visible for:</div>*/}
                                        <ScrollPanel className='userScrollPanel' style={{ width: '100%', height: '85px', maxHeight: `calc(${(this.props.users?.length *42) + 20}px)` }}>
                                            {this.props.users}
                                        </ScrollPanel></div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className='col-6 sm:col-6 md:col-7 lg:col-9 xl:col-10' style={{ marginLeft: 'auto' }}>
                            <div className="full-text-message" style={{ marginRight: 0 }}>
                                Note: Users changing the suborganization will lose access to blocklists shared on suborganization level, but will keep access to blocklists shared on personal level.
                            </div>
                        </div>
                    </div>
                </Dialog>

            </React.Fragment>
        );
    }
}

export default CreateBlacklist;
